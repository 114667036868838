<template>
  <div class="news">
    <div class="header">
      <div class="banner">
        <div class="title">新闻动态</div>
        <div class="subtitle">每一条新的动态都是我们迈向前进的脚步</div>
      </div>
      <div class="tab">
        <div :class="{tabItem:true,tabItem_active:activeTab === 6}" @click="handleTabClick(6)" v-if="isShowTab1">
          <img :src="require('../../assets/img/newsTab_a.png')" v-if="activeTab === 6">
          <img :src="require('../../assets/img/newsTab.png')" v-else>
          <span>行业动态</span>
        </div>
        <div :class="{tabItem:true,tabItem_active:activeTab === 7}" @click="handleTabClick(7)" v-if="isShowTab2">
          <img :src="require('../../assets/img/newsTab2_a.png')" v-if="activeTab === 7">
          <img :src="require('../../assets/img/newsTab2.png')" v-else>
          <span>公司动态</span>
        </div>
      </div>
    </div>
    <ul class="newsList">
      <li class="newCard" v-for="(item,index) in newsList" :key="index" @click="toDetail(item)">
        <div class="left" :style="`background: url(${imgFn(item.titlePhoto)}) no-repeat left center/cover;`">

        </div>
        <div class="newsDetile">
          <div class="title">{{ item.titlePrimary }}</div>
          
          <div class="footer">
            <div class="origin" v-if="item.source">
              <span>文章来源：</span>
              <span>某某科技</span>
            </div>
            <span style="margin: 0 10px;" v-if="item.source">|</span>
            <div class="time">
              <span>{{ item.createTime.split(' ')[0].replace(/-/g,'/') }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="empty" v-if="!newsList.length">
      暂无数据
    </div>
    <div class="searchMore" @click="getList" v-show="newsList.length">
      <img src="../../assets/img/loading.png" v-show="loading">
      <span>{{finished?'没有更多了':'查看更多'}}</span>
    </div>
  </div>
</template>

<script>
  import { selectReceptionTitle } from "@/api/common";
  import params from "@/utils/params.js";
  export default {
    name:'News',
    data(){
      return{
        loading:false,
        activeTab:6,
        newsList:[],
        finished:false,
        isShowTab1:false,
        isShowTab2:false,
        fileurl: params.appServiceUrldown + "common/fileEcho?fileName=",
        searchParams:{
          pageNo:0,
          pageSize:10,
          titleType:6
        }
      }
    },
   activated(){
    this.$bus.$emit('setAliveComponents',[])
   },
    mounted(){
      this.getList()
      this.getTabs()
    },

    methods:{
      imgFn(n) {
        if (n) return this.fileurl + n;
      },
      async getTabs(){
        let params1 = {
          pageNo:1,
          pageSize:10,
          titleType:6
        }
        let params2 = {
          pageNo:1,
          pageSize:10,
          titleType:7
        }
        try {
          let res1 = await selectReceptionTitle(params1)
          let res2 = await selectReceptionTitle(params2)

          if(res1.code === 0 && res1.data.records.length){
            this.isShowTab1 = true
          }
          if(res2.code === 0 && res2.data.records.length){
            this.isShowTab2 = true
          }

          if(!this.isShowTab1 && this.isShowTab2){
            this.handleTabClick(7)
          }
        } catch (error) {
          console.log(error)
        }
      },
      async getList(){
        if(this.finished) return
        this.searchParams.pageNo++
        try {
          this.loading = true
          let res = await selectReceptionTitle(this.searchParams)
          this.loading = false
          if(res.code === 0){
            if(res.data.records.length === 0) return this.finished = true
            this.newsList = [...this.newsList,...res.data.records]
          }
        } catch (error) {
          this.loading = false
        }
      },
      handleTabClick(val){
        if(val === this.activeTab) return
        this.activeTab = val
        this.finished = false
        this.searchParams = {
          pageNo:0,
          pageSize:10,
          titleType:val
        }
        this.newsList = []
        this.getList()
        
      },
      toDetail(item){
        this.$bus.$emit('setAliveComponents',['News'])
        setTimeout(()=>{
          sessionStorage.setItem('newsDetail',JSON.stringify(item))
          this.$router.push({
            path:'/newsDetail'
          })
        },50)
      }
    }
  }
</script>

<style lang="less" scoped>
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .news{
    .header{
      .banner{
        overflow: hidden;
        width: 750px;
        height: 270px;
        background: url('../../assets/img/n_1.png') no-repeat 0px/cover;
        padding-left: 65px;
        .title{
          font-size: 36px;
          color: #FFFFFF;
          line-height: 47px;
          margin-top: 85px;
        }
        .subtitle{
          margin-top: 25px;
          font-size: 22px;
          color: #FFFFFF;
          line-height: 29px;
        }
      }
      .tab{
        height: 90px;
        box-shadow: 0px 6px 10px 0px rgba(55,55,55,0.13);
        display: flex;
        .tabItem{
          padding: 0 50px;
          line-height: 90px;
          height: 90px;
          font-size: 28px;
          font-weight: 500;
          color: #9D9B9B;
          position: relative;
          img{
            width: 25px;
            margin-right: 10px;
          }
        }
        .tabItem_active{
          color: rgba(76, 121, 229, 1);
          &::before{
            content:'';
            position: absolute;
            left: calc(50% + 17px);
            bottom: 0px;
            height: 6px;
            width: 60px;
            border-radius: 3px;
            background-color: rgba(76, 121, 229, 1);
            transform: translateX(-50%);
          }
        }
      }
     }
     .newsList{
      padding: 35px 20px;
      .newCard{
        width: 100%;
        height: 160px;
        // background-color: red;
        display: flex;
        margin-bottom: 30px;
        .left{
          border-radius: 10px;
          width: 230px;
          flex-shrink: 0;
          margin-right: 20px;
        }
        .newsDetile{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title{
            font-size: 28px;
            line-height: 37px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .footer{
            display: flex;
            font-size: 22px;
            color: #8F8F8F;
            .origin{
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100px;
            }
            .time{
              position: relative;
              padding-left: 30px;
              &::before{
                content: '';
                width: 22px;
                height: 22px;
                position: absolute;
                background: url('../../assets/img/timmer.png') no-repeat left/22px;
                left: 0;
                top: 7px;
              }
            }
          }

        }
        
      }
     }
     .empty{
      height: 200px;
      text-align: center;
      line-height: 200px;
      font-size: 25px;
      color: #9D9B9B;
     }
     .searchMore{
      width: 298px;
      height: 90px;
      border-radius: 5px;
      border: 2px solid #3E76E0;
      color: #3E76E0;
      margin: 100px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 35px;
        height: 35px;
        margin-right: 10px;
        animation: rotate 1s linear infinite;
      }
     }
  }
  
  
</style>