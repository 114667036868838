<template>
  <div class="energy-wrap">
      <div class='mask' v-show='isshow'></div>
      <div class='dialog' v-show='isshow'>
        <div class='img'></div>
        <div class='txt'>点击右上角按钮<br>在浏览器打开</div>
      </div>
  </div>
</template>

<script>
import $ from "../../assets/js/jquery.js";
export default {
  data() {
    return {
        isshow:false
    };
  },
  methods: {
   
  },
  mounted() {
      var that =this
      $(".header-wrap,.footer-wrap").hide()
        function isWeiXin() {

            var ua = window.navigator.userAgent.toLowerCase();

            console.log(ua);//mozilla/5.0 (iphone; cpu iphone os 9_1 like mac os x) applewebkit/601.1.46 (khtml, like gecko)version/9.0 mobile/13b143 safari/601.1

            if (ua.match(/MicroMessenger/i) == 'micromessenger') {

            return true;

            } else {

            return false;

            }

            }

            if(isWeiXin()){
                that.isshow=true
            console.log(" 是来自微信内置浏览器")

            }else{
                 that.isshow=false
                window.location.href='http://www.xiaoling-tech.com/GMAQ.apk'
            console.log("不是来自微信内置浏览器")

            }

  },
  beforeDestroy() {

  },
};
</script>

<style lang='less' scoped>
.dialog{
    position: absolute;
    top:30px;
    width:363px;
    height:400px;
    left:50%;
    margin-left:-181px;
    z-index: 10;
    .img{
        background:url("../../assets/images/fx.png") no-repeat center center;
        background-size:400px 196px;
        background-position-y: 40px;
        width:400px;
        height:196px;
    }
    .txt{
        color:#fff;
        font-size:36px;
        text-align: center;
    }
}

.mask{
    width:100%;
    height:100%;
    position: fixed;
    left:0;
    top:0;
    background:rgba(0,0,0,.5);
    z-index: 1;
}
</style>
