<template>
  <div class="newsDetail">
    <div class="header">
      <img :src="require('../../assets/img/back.png')" @click="back" >
      <span >详情</span>
    </div>
    <div class="title">
      {{ detail.titlePrimary }}
      
    </div>
    <div class="source-time" >
      <div class="source" v-if="detail.source">
        <span>文章来源：</span>
        <span>某某科技哈哈哈哈</span>
      </div>
      <div class="delimiter" v-if="detail.source">|</div>
      <div class="time">{{ detail.createTime }}</div>
    </div>
    <div class="content" v-html="detail.titleDetails || ''"></div>
  </div>
</template>

<script>
  export default {
    name:'newsDetail',
    data(){
      return{
        detail:{}
      }
    },
    mounted(){
      this.getDetail()
    },
    methods:{
      getDetail(){
        let detail = sessionStorage.getItem('newsDetail')
        if(!detail) return
        this.detail = JSON.parse(detail)
      },
      back(){
        window.history.back()
      }
    }
  }
</script>

<style lang="less" scoped>
  .newsDetail{
    padding: 145px 35px 50px 35px;
    .header{
      width: 100%;
      height: 99px;
      background: #3E76E0;
      box-shadow: 0px 6px 10px 0px rgba(55,55,55,0.13);
      line-height: 99px;
      text-align: center;
      color: #fff;
      left: 0;
      top: 0;
      position: fixed;
      z-index: 99;
      img{
        position: absolute;
        width: 60px;
        height: 60px;
        left: 15px;
        top: 20px;
      }
    }
    .title{
      width: 672px;
      font-size: 38px;
      font-weight: 600;
      color: #272729;
      line-height: 54px;
    }
    .source-time{
      margin-top: 40px;
      display: flex;
      font-size: 22px;
      line-height: 26px;
      color: #AEAEAE;
      .source{
        width:350px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .delimiter{
        
        margin: 0 10px;
      }
      .time{
        position: relative;
        padding-left: 30px;
        &::before{
          content: '';
          width: 22px;
          height: 22px;
          position: absolute;
          background: url('../../assets/img/timmer.png') no-repeat left/22px;
          left: 0;
          top: 2.5px;
        }
      }
    }
    .content{
      margin-top: 30px;
      font-size: 28px;
      color: #626770;
      line-height: 44px;
      /deep/img{
        width: 100% !important;
        margin: 20px 0;
      }
      /deep/video{
        width: 100% !important;
        margin: 20px 0;
      }
    }
  }
</style>