<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/img/H_1.png" alt="" />
      <!-- <div class='p-top'>
          <h3>基于HSE体系的作业人员安全管控产品</h3>
          <div>Safety control products for operators based on HSE system
        </div>
      </div> -->
    </div>
    <div class="survey-wrap">
      <img
        class="survey-img fadeOutUp"
        ref="animate0"
        :class="{ fadeInUp: animateData.animate0 }"
        src='../../assets/img/PRODUCTINTRODUCTION.png'
      />
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品简介
      </div>
      <div class="cp-cont">
        采用<span>“智能手机、IoT设备、云平台”</span>多端协同的设计思路，以全生命周期为主线，预防性维护为中心，兼顾设备档案、备品备件的管理，借助物联技术实现设备状态的实时监控与故障预警，利用数据和算法模型预测设备故障，帮助企业实现设备的规范化、科学化、智能化管理，降低设备故障率，保持设备稳定性，实现企业资产效益的全面提升。
      </div>
      <img class="cp-img1" src="../../assets/img/H_2.png" alt="" />
    </div>
    <div class="feature-wrap">
         <img
          class="survey-img survey-img1 fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
          src='../../assets/img/CORECOMPETENCE.png'
        />
        <div
          class="survey-title survey-title-fff fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          核心能力
        </div>
        <div class='hx-box'>
            <div class='hx-block l-between' v-for='(itm,index) in hexlist' :key='itm.title'>
                <img :class='"img"+(index+1)' :src='itm.url'>
                <div class='hx-block-rig' :class='"hx-block-rig"+(index+1)'>
                    <div class='hx-block-title f24'>{{itm.title}}</div>
                    <div class='hx-block-txt f20'>{{itm.txt}}</div>
                </div>
            </div>
        </div>




    </div>
    <div class="scene-wrap">
        <img
          class="survey-img survey-img1 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          src='../../assets/img/PRODUCTFEATURES.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
          产品特点
        </div>
        <div class='td-box'>
            <div class='td-block l-start' :class='"td-block"+(index+1)' v-for='(itm,index) in tdlist' :key='itm.title'>
                <img :class='"imgbj imgbj"+(index+1)' :src='itm.bj'>
                <img :class='"img img"+(index+1)' :src='itm.url'>
                 <div class='td-rig' >
                    <div class='td-title f24'>{{itm.title}}</div>
                    <div class='td-txt f20'>{{itm.txt}}</div>
                </div>
            </div>
        </div>

      
    </div>

    <div class="scene-wrap">
        <img
          class="survey-img survey-img1 survey-img2 fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
          src='../../assets/img/APPLICATIONSCENARIO.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
        >
          应用场景
        </div>
       <scene-swiper :list="yylist"></scene-swiper>
       <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />

      
    </div>
    

  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import sceneSwiper from "@/components/scene-swiper/index.vue";
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  components: { sceneSwiper },
  data() {
    return {
      animateData: {},
      num:0,
      hexlist:[
        {url:require("../../assets/img/H_3.png"),title:"全生命周期管理能力",txt:"支持设备资产购置、领用、转移、盘点、维修、报废全方位准确监管"},
        {url:require("../../assets/img/H_4.png"),title:"高效率维护保障能力",txt:"支持定期巡检、维修保养、工单处置、设备档案、备品配件精准管理"},
        {url:require("../../assets/img/H_5.png"),title:"智能化预测维护能力",txt:"支持IoT设备接入实时监测设备状态，借助算法和模型实现预测性维护"},
        {url:require("../../assets/img/H_17.png"),title:"精细化知识管理能力",txt:"支持设备资料、维保经验、规章制度、常用知识的多维度精细化管理"},
        {url:require("../../assets/img/H_18.png"),title:"多维度数据分析能力",txt:"规支持自动化分析，实时掌握巡检状态、维修效率、工单执行、库存等"},
      ],
      tdlist:[
        {url:require("../../assets/img/H_10.png"),bj:require("../../assets/img/H_19.png"),title:"多终端协同",txt:"PC、Android、IOS、微信公众平台等多种终端数据互通，实现全平台协同管理"},
        {url:require("../../assets/img/H_6.png"),bj:require("../../assets/img/H_20.png"),title:"多通知方式",txt:"支持系统消息、邮件、短信、微信等多种通知方式，实现多渠道、多场景通知"},
        {url:require("../../assets/img/H_12.png"),bj:require("../../assets/img/H_21.png"),title:"智能化辅助",txt:"基于IoT数据、报警数据、规则数据，借助智能算法实现预测性维护和任务规划"},
        {url:require("../../assets/img/H_8.png"),bj:require("../../assets/img/H_22.png"),title:"定制化交付",txt:"全自主架构设计，支持与其他系统集成，支持二次开发定制，支持私有化部署"},
      ],
      yylist:[
        {img:require("../../assets/img/H_13.png"),title:"生产设备维保"},
        {img:require("../../assets/img/H_14.png"),title:"工程机械管控"},
        {img:require("../../assets/img/H_15.png"),title:"电气设备保障"},
        {img:require("../../assets/img/H_16.png"),title:"医疗设备管理"},
        
      ]
    };
  },
  methods: {
    imgLoad(){
      this.num++
      if(this.num==4){
        this.swiperInit();
      }
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
    swiperInit() {
      new Swiper(".swiper-container", {
        slidesPerView: "auto",
        spaceBetween: -10,
        centeredSlides: true,
        loop: true,
        loopAdditionalSlides: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        centeredSlides: true,
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: true,
        },
      });
    },
  },
  mounted() {
    // setTimeout(()=>{
    //   this.swiperInit();
    // },5000)
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.swiper-container {
  margin-top: 45px;
  padding-bottom: 80px;
  margin-bottom: 20px;
}
.swiper-wrapper {
  .swiper-slide {
    background-image: url("../../assets/images/rebot-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 425px !important;
    height: 588px;
    transition: 300ms;
    transform: scale(0.8);
    padding-left: 40px;
    padding-top: 45px;
    box-sizing: border-box;
    border-radius: 20px;
    .rebot-title {
      font-size: 28px;
      color: #555;
      position: relative;
      line-height: 38px;
      &::after {
        content: "";
        position: absolute;
        width: 44px;
        height: 8px;
        bottom: -8px;
        left: 0;
        background: #b2b2b2;
      }
    }
    .rebot-name {
      font-size: 22px;
      color: #555;
      line-height: 30px;
      margin-top: 32px;
    }
    .rebot-img {
      position: absolute;
      right: 20px;
      bottom: 20px;
      img {
        width: 200px;
      }
    }
    &.swiper-slide_1 {
      .rebot-img {
        img {
          width: 242px;
        }
      }
    }
    &.swiper-slide_2 {
      .rebot-img {
        img {
          width: 200px;
        }
      }
    }
    &.swiper-slide_3 {
      .rebot-img {
        img {
          width: 230px;
        }
      }
    }
    &.swiper-slide_4 {
      .rebot-img {
        img {
          width: 250px;
        }
      }
    }
    &.swiper-slide-active {
      background-image: url("../../assets/images/rebot-bg-active.png");
      transform: scale(1);
      z-index: 1;
      .rebot-title {
        color: #fff;
        &::after {
          background-color: #01ff66;
        }
      }
      .rebot-name {
        color: #fff;
      }
    }
    &.swiper-slide-prev {
      transform: translate(210px, 0) scale(0.8);
    }
    &.swiper-slide-next {
      transform: translate(-220px, 0) scale(0.8);
    }
  }
}
::v-deep .swiper-pagination {
  .swiper-pagination-bullet {
    background-image: url("../../assets/images/pagination.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    opacity: 1;
    width: 20px;
    height: 20px;
    outline: 0;
  }
  .swiper-pagination-bullet-active {
    background-image: url("../../assets/images/pagination-active.png");
  }
}

.survey-title{
  margin-top:-20px;
  color: #11182e;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: #414B59;
}
.survey-title-fff{
  color:#fff;
}
.survey-img{
  width:660px;
  height:40px;
  display: block;
  margin: 0 auto;
}
.survey-img1{
   width:504px;
  height:40px;
}
.cp-cont{
  font-size: 24px;
  color: #909399;
  line-height: 48px;
  padding:23px 28px 0 26px;
  text-align: center;
  span{
     color:#414b59;
    font-size:28px
  }
}
.cp-img1{
  width:100%;
  margin-top: 10px;
  display: block;
  // margin-bottom:89px;
}

.feature-wrap{
  background: #3E76E0;
}
.hx-box{
  padding:0 15px;
  .hx-block{
    height:158px;
    width:100%;
    img{
      margin-top:30px;
      margin-right:30px;
    }
    .img1{
      width:90px;
      height:93px;
    }
    .img2{
      width:86px;
      height:88px;
    }
    .img3{
      width:85px;
      height:85px;
    }
    .img4{
      width:88px;
      height:85px;
    }
    .img5{
      width:98px;
      height:86px;
    }
    .img6{
      width:87px;
      height:73px;
    }
    .hx-block-rig{
      width:291px;
      height:100%;
      border-bottom:1px dashed  rgba(255, 255, 255, .39);
      .hx-block-title{
        font-size: 12px;
        color: #FFFFFF;
        line-height: 15px;
        margin-top:18px;
      }
      .hx-block-txt{
          font-size: 10px;
          color: rgba(255, 255, 255, .66);
          line-height: 15px;
          margin-top:4px;
      }
    }
    .hx-block-rig6{
      border-bottom: none;;
    }
  }
}
.td-box{
  padding:0 28px;
  .td-block{
    width:100%;
    height:197px;
    position: relative;
    margin-top:18px;
    .imgbj{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      z-index:-1;
    }
    .img{
      
    }
    .img1{
      width:90px;
      height:93px;
      margin:57px 40px 0 36px;
    }
    .img2{
      width:86px;
      height:88px;
      margin:58px 41px 0 38px;
    }
    .img3{
      width:85px;
      height:85px;
      margin:55px 39px 0 38px;
    }
    .img4{
      width:88px;
      height:85px;
      margin:55px 39px 0 38px;
    }
  
    .td-rig{
      .td-title{
          height: 15px;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 15px;
          margin:28px 0 9px 0;
      }
      .td-txt{
        color: rgba(255, 255, 255, .9);
        font-size: 10px;
        line-height: 16px;
        padding-right:10px;
      }
    }
  }
}


.survey-img2{
  width:620px;
}

.yy-box{
  padding:0 28px;
  .yy-block{
    width: 386px;
    height: 339px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);
    .img{
      width:386px;
      height:249px;
    }
    .yy-title{
      font-size: 24px;
      color: #909399;
      line-height: 31px;
      text-align: center;
      margin-top:28px;
    }
  }
}
.p-top{
  position: absolute;
  top:79px;
  left:35px;
  h3{
    width: 360px;
    height: 96px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 48px;
  }
  div{
    width: 342px;
    height: 36px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, .49);
    line-height: 27px;
    margin-top:38px;
  }
}
.swiper-container{
    margin-top:0;
}

</style>