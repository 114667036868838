<template>
  <div class="energy-wrap">
    <div class="energy-banner">
      <img src="../../assets/images/product-banner_8.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="fusion-wrap">
        <dl class="fusion-item" v-for="item in programList" :key="item.title">
          <dt>
            <img :src="item.icon" alt="" />
          </dt>
          <dd>
            <h3>{{ item.title }}</h3>
            <span>{{ item.mes }}</span>
          </dd>
        </dl>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
    <div class="architecture-wrap" id="architecture-wrap">
      <div
        class="architecture-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        系统架构图
      </div>
      <div class="architecture-content">
        <div class="architecture-item">
          <div class="architecture-name">“微电网”系统</div>
          <div class="architecture-ul">
            <div>充电站</div>
            <div>光伏站</div>
            <div>供电服务站</div>
            <div>储能站</div>
          </div>
          <img src="../../assets/images/fusion-arrow.png" alt="" />
          <div class="architecture-bottom">多维融合</div>
        </div>
        <div class="architecture-item">
          <div class="architecture-name">智慧用能管理系统</div>
          <div class="architecture-li">智慧用能站</div>
          <img src="../../assets/images/fusion-arrow.png" alt="" />
          <div class="architecture-bottom">多能互补</div>
        </div>
        <div class="architecture-item">
          <div class="architecture-name">智慧应用体系</div>
          <div class="architecture-ul">
            <div>5G基站</div>
            <div>北斗基站</div>
            <div>应急抢修站</div>
            <div>物资仓储站</div>
          </div>
          <img src="../../assets/images/fusion-arrow.png" alt="" />
          <div class="architecture-bottom">智慧应用</div>
        </div>
        <div class="architecture-item">
          <div class="architecture-name">数字新基建</div>
          <div class="architecture-li">数据中心站</div>
          <img src="../../assets/images/fusion-arrow.png" alt="" />
          <div class="architecture-bottom">共建、共享、共赢</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "@/utils/index.js";
export default {
  data() {
    return {
      programList: [
        {
          icon: require("../../assets/images/fusion-icon_1.png"),
          title: "打造新基建运营模式",
          mes: "以数据中心站为基础，打造“共建、共享、共赢”的数字新基建运营模式",
          animate: "animate6",
          delay: 0.1,
        },
        {
          icon: require("../../assets/images/fusion-icon_2.png"),
          title: "构筑多维融合的“微电网”系统",
          mes:
            "构筑多维融合的“微电网”系统，实现充电站、光伏站、供电服务站、储能站的融合",
          animate: "animate7",
          delay: 0.15,
        },
        {
          icon: require("../../assets/images/fusion-icon_3.png"),
          title: "建设智慧用能管理系统",
          mes: "建设智慧用能管理系统，通过智慧用能站来实现“多能互补”",
          animate: "animate8",
          delay: 0.2,
        },
        {
          icon: require("../../assets/images/fusion-icon_4.png"),
          title: "打造智慧应用场景",
          mes:
            "基于5G基站、北斗基站，打造智慧应用场景，并为应急抢修站、物资仓储站的高效运转提供技术支撑。通过方案的逐步落实，逐步实现“十站融合”，以此推进微电网、智慧能源、数字能源深度开发应用，构建投资界面清晰、运营有序高效、环境开放友好的运营模式",
          animate: "animate9",
          delay: 0.25,
        },
      ],
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.fusion-wrap {
  padding-left: 27px;
  margin-top: 24px;
  .fusion-item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ced0d7;
    padding: 26px 30px 22px 0;
    &:last-child {
      border-bottom: 0;
    }
    dt {
      margin-right: 18px;
    }
    img {
      width: 96px;
    }
    h3 {
      color: #11182e;
      font-size: 28px;
      font-weight: normal;
      line-height: 37px;
    }
    span {
      color: #878ea1;
      font-size: 24px;
      line-height: 42px;
    }
  }
}
.architecture-wrap {
  padding: 58px 28px 0;
  margin-bottom: 50px;
  .architecture-title {
    color: #11182e;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    margin-bottom: 12px;
  }
  .architecture-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .architecture-item {
    width: calc(~"50% - 14px");
    margin-top: 26px;
    padding-bottom: 39px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
    border-radius: 8px;
    text-align: center;
  }
  .architecture-name {
    line-height: 69px;
    height: 69px;
    background: #424e6e;
    border-radius: 8px 8px 0px 0px;
    text-align: center;
    color: #fff;
    font-size: 28px;
  }
  .architecture-ul {
    display: flex;
    padding: 15px 16px 0;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      width: calc(~"50% - 7px");
      margin-bottom: 10px;
      line-height: 40px;
      height: 40px;
      background: #eff1f8;
      border-radius: 5px;
      color: #11182e;
      font-size: 20px;
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }
  .architecture-li {
    line-height: 90px;
    height: 90px;
    background: #eff1f8;
    border-radius: 5px;
    color: #11182e;
    font-size: 20px;
    margin: 15px 16px 0;
  }
  img {
    width: 28px;
    margin: 0;
  }
  .architecture-bottom {
    line-height: 53px;
    height: 53px;
    background: #3ab9da;
    border-radius: 6px;
    font-size: 24px;
    color: #fff;
    margin: 5px 16px 0;
  }
}
</style>