<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/images/product-banner_1.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-center">
        <div class="survey-item survey-item_2">
          <img src="../../assets/images/survey-item_1.png" alt="" />
          <span>检测机器人</span>
        </div>
        <img
          class="survey-dotted survey-dotted_2"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-icon">
          <img src="../../assets/images/survey-icon_1.png" alt="" />
          <span>机器人智能巡检<br />解决方案</span>
        </div>

        <img
          class="survey-dotted survey-dotted_3"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-item survey-item_3">
          <img src="../../assets/images/survey-item_2.png" alt="" />
          <span>智能运检管理平台</span>
        </div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
    <div class="feature-wrap">
      <div
        class="feature-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="feature">
        <div
          class="feature-item feature-item_1 fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <div>
            <img src="../../assets/images/feature-icon_1.png" alt="" />
          </div>
          <span>多场景应用</span>
        </div>
        <div
          class="feature-item feature-item_2 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          style="transition-delay: 0.2s"
        >
          <div>
            <img src="../../assets/images/feature-icon_2.png" alt="" />
          </div>
          <span>全天候支撑</span>
        </div>
        <div
          class="feature-item feature-item_3 fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          style="transition-delay: 0.4s"
        >
          <div>
            <img src="../../assets/images/feature-icon_3.png" alt="" />
          </div>
          <span>高效率执行</span>
        </div>
      </div>
    </div>
    <div class="scene-wrap">
      <div
        class="scene-title fadeOutUp"
        ref="animate5"
        :class="{ fadeInUp: animateData.animate5 }"
      >
        应用场景
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-slide_2">
            <div class="rebot-title">绝缘油取样</div>
            <div class="rebot-name">取样机器人</div>
            <div class="rebot-img">
              <img @load="imgLoad()" src="../../assets/images/rebot_2.png" alt="" />
            </div>
          </div>
          <div class="swiper-slide swiper-slide_3">
            <div class="rebot-title">变压器巡检</div>
            <div class="rebot-name">油中机器人</div>
            <div class="rebot-img">
              <img @load="imgLoad()" src="../../assets/images/rebot_3.png" alt="" />
            </div>
          </div>
          <div class="swiper-slide swiper-slide_4">
            <div class="rebot-title">水电站坝体检测</div>
            <div class="rebot-name">水下机器人</div>
            <div class="rebot-img">
              <img @load="imgLoad()" src="../../assets/images/rebot_4.png" alt="" />
            </div>
          </div>
          <div class="swiper-slide swiper-slide_1">
            <div class="rebot-title">隧洞巡检</div>
            <div class="rebot-name">巡检机器人</div>
            <div class="rebot-img">
              <img @load="imgLoad()" src="../../assets/images/rebot_1.png" alt="" />
            </div>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { isElementNotInViewport } from "@/utils/index.js";
export default {
  data() {
    return {
      animateData: {},
      num:0
    };
  },
  methods: {
    imgLoad(){
      this.num++
      if(this.num==4){
        this.swiperInit();
      }
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
    swiperInit() {
      new Swiper(".swiper-container", {
        slidesPerView: "auto",
        spaceBetween: -10,
        centeredSlides: true,
        loop: true,
        loopAdditionalSlides: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        centeredSlides: true,
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: true,
        },
      });
    },
  },
  mounted() {
    // setTimeout(()=>{
    //   this.swiperInit();
    // },5000)
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.swiper-container {
  margin-top: 45px;
  padding-bottom: 80px;
  margin-bottom: 20px;
}
.swiper-wrapper {
  .swiper-slide {
    background-image: url("../../assets/images/rebot-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 425px !important;
    height: 588px;
    transition: 300ms;
    transform: scale(0.8);
    padding-left: 40px;
    padding-top: 45px;
    box-sizing: border-box;
    border-radius: 20px;
    .rebot-title {
      font-size: 28px;
      color: #555;
      position: relative;
      line-height: 38px;
      &::after {
        content: "";
        position: absolute;
        width: 44px;
        height: 8px;
        bottom: -8px;
        left: 0;
        background: #b2b2b2;
      }
    }
    .rebot-name {
      font-size: 22px;
      color: #555;
      line-height: 30px;
      margin-top: 32px;
    }
    .rebot-img {
      position: absolute;
      right: 20px;
      bottom: 20px;
      img {
        width: 200px;
      }
    }
    &.swiper-slide_1 {
      .rebot-img {
        img {
          width: 242px;
        }
      }
    }
    &.swiper-slide_2 {
      .rebot-img {
        img {
          width: 200px;
        }
      }
    }
    &.swiper-slide_3 {
      .rebot-img {
        img {
          width: 230px;
        }
      }
    }
    &.swiper-slide_4 {
      .rebot-img {
        img {
          width: 250px;
        }
      }
    }
    &.swiper-slide-active {
      background-image: url("../../assets/images/rebot-bg-active.png");
      transform: scale(1);
      z-index: 1;
      .rebot-title {
        color: #fff;
        &::after {
          background-color: #01ff66;
        }
      }
      .rebot-name {
        color: #fff;
      }
    }
    &.swiper-slide-prev {
      transform: translate(210px, 0) scale(0.8);
    }
    &.swiper-slide-next {
      transform: translate(-220px, 0) scale(0.8);
    }
  }
}
::v-deep .swiper-pagination {
  .swiper-pagination-bullet {
    background-image: url("../../assets/images/pagination.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    opacity: 1;
    width: 20px;
    height: 20px;
    outline: 0;
  }
  .swiper-pagination-bullet-active {
    background-image: url("../../assets/images/pagination-active.png");
  }
}
</style>