<template>
  <div class="energy-wrap">
    <div class="energy-banner">
      <img src="../assets/images/recruit-bg.png" alt="" />
      <div class="banner_text">
        <h3>期待你的加入</h3>
        <span>简历请投递：hr@xiaoling-tech.com</span>
      </div>
    </div>
    <div class="recruit_wrap">
      <div
        class="recruit_box"
        v-for="(item, index) in recruit"
        :key="index"
        :class="{ active: idx === index }"
      >
        <div
          class="recruit_head"
          @click="idx === index ? (idx = '') : (idx = index)"
        >
          <div class="name">{{ item.job }}</div>
          <div style='padding-right: 30px;'>
            <span class="time">{{ item.time }} 发布</span>
            <span class="address">{{ item.address }}</span>
            <img src="../assets/images/arrow.png" alt="" />
          </div>
        </div>
        <div class="recruit_content" v-if="idx === index">
          <ul>
            <h4>岗位职责</h4>
            <li v-for="it in item.description" :key="it">
              {{ it }}
            </li>
          </ul>
          <ul>
            <h4>任职资格</h4>
            <li v-for="it in item.demand" :key="it">
              {{ it }}
            </li>
          </ul>
             <ul v-if='item.other && item.other.length>0' class='ul'>
            
            <li v-for="it in item.other" :key="it">
              <h4>{{it.title}}</h4><span>{{it.txt}}</span>
            </li>
          </ul>
          <div class="contact_wrap">
            <a href="mailto:it@xiaoling-tech.com">投递邮箱：hr@xiaoling-tech.com</a>
            <img src="../assets/images/contact.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idx: '',
      recruit: [
        {
          job: "Java开发",
          time: "2023-06-12 14:02:17 ",
          address: "北京",
          description: [
            "1、参与公司新项目、新功能的需求讨论、技术方案设计与开发；",
            "2、维护现有系统，对现有系统进行升级改造；",
            "3、参与解决实际工作中遇到的功能、性能问题，不断地进行系统优化；",
            "4、能按计划有效且高质量的完成分配的任务；",
            "5、积极了解公司业务，并参与相应的会议提供建议。",
          ],
          demand: [
            "1、同岗位3年以上工作经验，计算机等相关专业本科及以上学历；",
            "2、精通Springboot、SpringCloud或Dubbo等开源框架",
            "3、精通MyBatis/JPA/等开源技术框架，掌握技术原理机制",
            "4、熟悉MySQL、MongoDB等数据库使用，具备复杂SQL性能优化经验",
            "5、熟悉Redis及RabbitMQ/RocketMQ/Kafka其中一种经验及核心原理",
            "6、熟悉Docker相关技术，能够熟练使用DockerSwarm或K8S",
            "7、有较强的逻辑思维、良好的沟通表达、逻辑以及网络规划能力；善于分析、归纳、解决问题。"

          ],
          other:[
            {title:"职位福利：",txt:"带薪年假、周末双休、六险一金、无官僚风气"},
            {title:"职位亮点：",txt:"工业互联网企业，行业前景好"}
          ]
        },
        {
          job: "前端开发",
          time: "2023-06-12 14:02:17 ",
          address: "北京",
          description: [
            "1、负责前端程序的实现，能实现高性能的交互效果",
            "2、 与UI设计师沟通交互、视觉设计的相关问题；",
            "3、 与后台工程师制定技术实现方案、服务接口等；",
            "4、对具体的产品进行性能优化，解决前端遇到的各种技术、性能、跨终端兼容问题。",
          ],
          demand: [
            "1、同岗位3年以上经验，大学本科及以上学历，计算机相关专业；",
            "2、能够独立完成前端架构搭建工作，要有解决各种突发问题的能力；",
            "3、熟练jquery、vue等，有uni-app开发经验者优先；",
            "4、熟练html5、css3，ES6等新技术标准；",
            "5、熟悉移动端h5开发，了解各平台兼容性差异；",
            "6、熟悉W3C标准，能够解决各种浏览器兼容性问题；",
            "7、熟悉微信平台接口及微信小程序功能研发；",
            "8、有良好的团队合作及抗压能力，有强烈的主人翁意识推进事务进展；"

          ],
          other:[
            {title:"职位福利：",txt:"带薪年假、周末双休、六险一金、无官僚风气"},
            {title:"职位亮点：",txt:"工业互联网企业，行业前景好"}
          ]
        },
         {
          job: "实施运维工程师",
          time: "2023-05-05 14:50:17 ",
          address: "北京",
          description: [
            "1、负责微服务软件的部署、运维，保证业务正常运行；",
            "2、负责与客户进行需求沟通，整理系统及功能优化方案；",
            "3、协助研发团队升级系统。",
          ],
          demand: [
            "1、3年及以上经验，本科及以上学历，计算机相关专业；",
            "2、具备一定容器化及Linux、MySQL、Mongo集群、ES集群安装项目运维经验及一定高可用、分布式架构系统运维项目经验；",
            "3、具备一定的微服务设计研发能力；",
            "4、有一定的需求分析能力。",

          ],
          other:[
            {title:"职位福利：",txt:"带薪年假、周末双休、六险一金、无官僚风气"},
            {title:"职位亮点：",txt:"工业互联网企业，行业前景好"}
          ]
        },
         {
          job: "解决方案工程师",
          time: "2023-05-05 14:50:17 ",
          address: "北京",
          description: [
            "1、负责标识解析应用建设售前政策、行业、客户信息化调研；",
            "2、负责对接客户需求、编写相应解决方案向客户汇报；",
            "3、协助产品及研发团队完成项目交付。",
          ],
          demand: [
            "1、本科及以上学历，软件行业3-5年售前工作经验；具备较强的Word、PPT文档能力；",
            "2、具备较强的学习能力、需求分析及沟通能力，性格开朗；",
            "3、对标识解析、数字经济、数据要素、数据确权及交易的相关概念有一定了解；",
            "4、对工业互联网周边技术，如：人工智能、大数据、区块链、5G数字孪生等有宏观的认识和了解优先。",

          ],
          other:[
            {title:"职位福利：",txt:"带薪年假、周末双休、六险一金、无官僚风气"},
            {title:"职位亮点：",txt:"工业互联网企业，行业前景好。"}
          ]
        },



        {
          job: "Java开发",
          time: "2023-06-12 14:02:17 ",
          address: "苏州",
          description: [
            "1、参与公司新项目、新功能的需求讨论、技术方案设计与开发；",
            "2、维护现有系统，对现有系统进行升级改造；",
            "3、参与解决实际工作中遇到的功能、性能问题，不断地进行系统优化；",
            "4、能按计划有效且高质量的完成分配的任务；",
            "5、积极了解公司业务，并参与相应的会议提供建议。",
          ],
          demand: [
            "1、同岗位3年以上工作经验，计算机等相关专业本科及以上学历；",
            "2、精通Springboot、SpringCloud或Dubbo等开源框架",
            "3、精通MyBatis/JPA/等开源技术框架，掌握技术原理机制",
            "4、熟悉MySQL、MongoDB等数据库使用，具备复杂SQL性能优化经验",
            "5、熟悉Redis及RabbitMQ/RocketMQ/Kafka其中一种经验及核心原理",
            "6、熟悉Docker相关技术，能够熟练使用DockerSwarm或K8S",
            "7、有较强的逻辑思维、良好的沟通表达、逻辑以及网络规划能力；善于分析、归纳、解决问题。"
          ],
          other:[
            {title:"职位福利：",txt:"带薪年假、周末双休、六险一金、无官僚风气"},
            {title:"职位亮点：",txt:"工业互联网企业，行业前景好"}
          ]
        },
        {
          job: "前端开发",
          time: "2023-06-12 14:02:17 ",
          address: "苏州",
          description: [
            "1、负责前端程序的实现，能实现高性能的交互效果",
            "2、 与UI设计师沟通交互、视觉设计的相关问题；",
            "3、 与后台工程师制定技术实现方案、服务接口等；",
            "4、对具体的产品进行性能优化，解决前端遇到的各种技术、性能、跨终端兼容问题。",
          ],
          demand: [
            "1、同岗位3年以上经验，大学本科及以上学历，计算机相关专业；",
            "2、能够独立完成前端架构搭建工作，要有解决各种突发问题的能力；",
            "3、熟练jquery、vue等，有uni-app开发经验者优先；",
            "4、熟练html5、css3，ES6等新技术标准；",
            "5、熟悉移动端h5开发，了解各平台兼容性差异；",
            "6、熟悉W3C标准，能够解决各种浏览器兼容性问题；",
            "7、熟悉微信平台接口及微信小程序功能研发；",
            "8、有良好的团队合作及抗压能力，有强烈的主人翁意识推进事务进展；"

          ],
          other:[
            {title:"职位福利：",txt:"带薪年假、周末双休、六险一金、无官僚风气"},
            {title:"职位亮点：",txt:"工业互联网企业，行业前景好"}
          ]
        },
         {
          job: "实施运维工程师",
          time: "2023-05-05 14:50:17 ",
          address: "苏州",
          description: [
            "1、负责微服务软件的部署、运维，保证业务正常运行；",
            "2、负责与客户进行需求沟通，整理系统及功能优化方案；",
            "3、协助研发团队升级系统。",
          ],
          demand: [
            "1、3年及以上经验，本科及以上学历，计算机相关专业；",
            "2、具备一定容器化及Linux、MySQL、Mongo集群、ES集群安装项目运维经验及一定高可用、分布式架构系统运维项目经验；",
            "3、具备一定的微服务设计研发能力；",
            "4、有一定的需求分析能力。",

          ],
          other:[
            {title:"职位福利：",txt:"带薪年假、周末双休、六险一金、无官僚风气"},
            {title:"职位亮点：",txt:"工业互联网企业，行业前景好"}
          ]
        },
        {
          job: "解决方案工程师",
          time: "2023-05-05 14:50:17 ",
          address: "苏州",
          description: [
            "1、负责标识解析应用建设售前政策、行业、客户信息化调研；",
            "2、负责对接客户需求、编写相应解决方案向客户汇报；",
            "3、协助产品及研发团队完成项目交付。",
          ],
          demand: [
            "1、本科及以上学历，软件行业3-5年售前工作经验；具备较强的Word、PPT文档能力；",
            "2、具备较强的学习能力、需求分析及沟通能力，性格开朗；",
            "3、对标识解析、数字经济、数据要素、数据确权及交易的相关概念有一定了解；",
            "4、对工业互联网周边技术，如：人工智能、大数据、区块链、5G数字孪生等有宏观的认识和了解优先。",

          ],
          other:[
            {title:"职位福利：",txt:"带薪年假、周末双休、六险一金、无官僚风气"},
            {title:"职位亮点：",txt:"工业互联网企业，行业前景好。"}
          ]
        }
      ],
    };
  },
};
</script>

<style lang='less' scoped>
.energy-banner {
  position: relative;
  .banner_text {
    position: absolute;
    top: 74px;
    left: 35px;
    color: #fff;
    font-size: 40px;
    h3 {
      margin-bottom: 10px;
    }
    span {
      font-size: 22px;
    }
  }
}
.recruit_wrap {
  padding: 24px 30px 36px;
}
.recruit_box {
  margin-bottom: 20px;
  &.active {
    .recruit_head {
      background: #f8fcff;
      img {
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }
  .recruit_head {
    position: relative;
    border: 1px solid #c2d8e4;
    padding: 18px 0 26px 23px;
    .name {
      color: #243159;
      font-size: 30px;
      line-height: 40px;
    }
    .time {
      font-size: 24px;
      line-height: 30px;
      color: #989eaf;
    }
    .address {
      font-size: 24px;
      line-height: 30px;
      color: #243159;
      margin-left: 20px;
      padding-right:10px;
    }
    img {
      position: absolute;
      width: 24px;
      right: 23px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}
.recruit_content {
  border: 1px solid #c2d8e4;
  border-top: none;
  padding: 25px 28px;
  h4 {
    min-width:85px;
    color: #243159;
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 10px;
    white:nowrap;
    overflow-x:auto;
    white-space:nowrap;
  }
  ul {
    li {
      font-size: 28px;
      color: #484e5e;
      line-height: 48px;
    }
    &:first-child {
      margin-bottom: 30px;
    }
  }
}
.contact_wrap {
  width: 453px;
  height: 56px;
  background: #0697d8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  color: #fff;
  padding: 0 20px;
  margin-top: 30px;
  img {
    width: 43px;
  }
}
.ul{
  li{
    h4{
      min-width:4.8rem;
    }
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
</style>