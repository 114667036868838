<template>
  <div class="energy-wrap">
    <div class="energy-banner">
      <img src="../../assets/images/product-banner_9.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div
        class="survey-subTitle fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
        style="transition-delay: 0.2s"
      >
        弹性电网综合解决方案，由八个部分组成，以此通过多元融合高弹性电网建设，在不改变电网物理形态的前提下，以改善电网辅助服务能力，为电网赋能，改变电网运行机制，提高电网安全抗扰能力，从而大幅提升社会综合能效水平
      </div>
      <div class="grid-wrap">
        <div class="grid-title">弹性电网综合解决方案</div>
        <ul class="grid-list">
          <li>
            <p>
              <img src="../../assets/images/grid-icon_1.png" alt="" />
            </p>
            <span>灵活规划网架坚强</span>
          </li>
          <li>
            <p>
              <img src="../../assets/images/grid-icon_2.png" alt="" />
            </p>
            <span>电网引导多能互联</span>
          </li>
          <li>
            <p>
              <img src="../../assets/images/grid-icon_3.png" alt="" />
            </p>
            <span>设备挖潜运行高效</span>
          </li>
          <li>
            <p>
              <img src="../../assets/images/grid-icon_4.png" alt="" />
            </p>
            <span>安全承载耐受抗扰</span>
          </li>
          <li>
            <p>
              <img src="../../assets/images/grid-icon_5.png" alt="" />
            </p>
            <span>源网荷储弹性平衡</span>
          </li>
          <li>
            <p>
              <img src="../../assets/images/grid-icon_6.png" alt="" />
            </p>
            <span>用户资源唤醒集聚</span>
          </li>
          <li>
            <p>
              <img src="../../assets/images/grid-icon_7.png" alt="" />
            </p>
            <span>市场改革机制配套</span>
          </li>
          <li>
            <p>
              <img src="../../assets/images/grid-icon_8.png" alt="" />
            </p>
            <span>科创引领数智赋能</span>
          </li>
        </ul>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "@/utils/index.js";
export default {
  data() {
    return {
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.grid-wrap {
  margin: 33px 28px 60px;
  background: #ffffff;
  box-shadow: 0px 0px 9px 6px rgba(23, 31, 48, 0.07);
  border-radius: 16px;
  padding: 26px 0 0;
  .grid-title {
    color: #11182e;
    font-size: 28px;
    text-align: center;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 62px;
      height: 4px;
      background: #01a0ef;
      left: 50%;
      bottom: -10px;
      transform: translate(-50%, 0);
    }
  }
  .grid-list {
    display: flex;
    flex-wrap: wrap;
    padding: 55px 20px 35px;
    li {
      background: #f7f8fc;
      border-radius: 12px;
      padding: 25px 0 28px;
      width: calc(~"33.33%  - 6px");
      margin-right: 8px;
      margin-bottom: 11px;
      text-align: center;
      &:nth-child(3n + 0) {
        margin-right: 0;
      }
    }
    p {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 19px;
    }
    span {
      color: #4f5978;
      font-size: 22px;
      line-height: 29px;
    }
    img {
      &:nth-child(1) {
        width: 93px;
      }
      &:nth-child(2) {
        width: 92px;
      }
      &:nth-child(3) {
        width: 97px;
      }
      &:nth-child(4) {
        width: 89px;
      }
      &:nth-child(5) {
        width: 98px;
      }
      &:nth-child(6) {
        width: 80px;
      }
      &:nth-child(7) {
        width: 86px;
      }
      &:nth-child(8) {
        width: 80px;
      }
    }
  }
}
</style>