<template>
  <div class="energy-wrap">
    <div class="energy-banner">
      <img src="../../assets/images/product-banner_12.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div class="survey-title">产品概况</div>
      <div class="survey-subTitle">
        变电站设备运维服务解决方案，主要是针对变电站的变压器状态检修和故障预测的业务需求而提供的专业服务方案，该方案基于泛在物联网的移动油色谱试验系统技术，可以实现离线试验数据和油色谱在线监测装置的数据自动耦合分析，并通过离线试验数据和在线装置的交互，制定相应的运维规范和标准接口，提高油色谱在线监测装置的数据准确性，降低油色谱在线监测装置的误报率，并为挖掘油色谱在线监测装置运行状态和监测数据以及变压器运行状态之间的内在规律提供更可靠的数据基础，以此提高油色谱在线监测装置的运行质量
      </div>
      <div class="substation-wrap">
        <div class="substation-top">
          <div class="substation-item substation-item_1">
            <div>
              <img src="../../assets/images/substation-item_1.png" alt="" />
              <span>在线数据</span>
            </div>

            <img
              class="substation-arrow_1"
              src="../../assets/images/substation-arrow_1.png"
              alt=""
            />
          </div>
          <div class="substation-item substation-item_2">
            <div>
              <img src="../../assets/images/substation-arrow_1.png" alt="" />
              <img src="../../assets/images/substation-item_3.png" alt="" />
            </div>

            <span>管理系统</span>
          </div>
        </div>
        <div class="substation-center">
          <div class="substation-item_bg">
            <img src="../../assets/images/substation-item_bg.png" alt="" />
            <span>移动油色谱试验<br />数据平台</span>
          </div>
          <div class="substation-item_3">
            <img src="../../assets/images/substation-item_2.png" alt="" />
          </div>
          <div class="substation-title">
            基于泛在物联网技术<br />的移动油色谱试验系<br />统总体架构图
          </div>
        </div>
      </div>
      <div class="substation-content">
        <div class="substation-content_item">
          <img
            class="substation-arrow_2"
            src="../../assets/images/substation-arrow_2.png"
            alt=""
          />
          <div class="substation-icon">
            <img src="../../assets/images/substation-icon_1.png" alt="" />
            <span>5G专网</span>
          </div>
          <img
            class="substation-arrow_3"
            src="../../assets/images/substation-arrow_3.png"
            alt=""
          />
          <div class="substation-icon">
            <img src="../../assets/images/substation-icon_2.png" alt="" />
            <span>一体化移动色谱分析仪</span>
          </div>
        </div>
        <img
          class="substation-arrow_4"
          src="../../assets/images/substation-arrow_4.png"
          alt=""
        />
        <div class="substation-content_item">
          <img
            class="substation-arrow_2"
            src="../../assets/images/substation-arrow_5.png"
            alt=""
          />
          <div class="substation-icon">
            <img src="../../assets/images/substation-icon_3.png" alt="" />
            <span>油色谱在线监测装置</span>
          </div>
          <img
            class="substation-arrow_3"
            src="../../assets/images/substation-arrow_3.png"
            alt=""
          />
          <div class="substation-icon">
            <img src="../../assets/images/substation-icon_4.png" alt="" />
            <span>变压器</span>
          </div>
        </div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang='less' scoped>
.substation-wrap {
  position: relative;
  padding: 28px 50px 0;

  .substation-top {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 45px;
  }
  .substation-item {
    display: flex;
    align-items: center;
    position: relative;
    div {
      position: relative;
      display: flex;
      align-items: center;
    }
    span {
      position: absolute;
      bottom: -70px;
      left: 50%;
      white-space: nowrap;
      transform: translate(-50%, 0);
      color: #4f5978;
      font-size: 24px;
      background: #ffffff;
      box-shadow: 0px 2px 9px 4px rgba(110, 138, 225, 0.12);
      padding: 8px 19px 8px 15px;
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 20px 20px;
        border-style: solid;
        border-color: transparent transparent #fff;
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 20px 20px;
        border-style: solid;
        border-color: transparent transparent #efefef;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, 0);
        filter: blur(2px);
      }
    }
    &.substation-item_1 {
      img {
        &:nth-child(1) {
          width: 99px;
        }
        &:nth-child(2) {
          width: 80px;
          margin-left: 25px;
        }
      }
    }
    &.substation-item_2 {
      img {
        &:nth-child(1) {
          width: 80px;
          transform: rotate(180deg);
          margin-right: 25px;
        }
        &:nth-child(2) {
          width: 99px;
        }
      }
    }
  }
  .substation-center {
    position: relative;
    top: -138px;
    .substation-item_bg {
      position: relative;
      text-align: center;
      img {
        width: 496px;
      }
      span {
        position: absolute;
        left: 50%;
        bottom: 125px;
        transform: translate(-50%, 0);
        color: #1d5690;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .substation-item_3 {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 60px;
      img {
        width: 136px;
      }
    }
    .substation-title {
      color: #243159;
      font-size: 24px;
      margin: 30px 0 0;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 18px 30px;
      box-shadow: 0px 0px 16px 5px rgba(4, 14, 52, 0.06);
      border-radius: 22px;
      width: 295px;
      text-align: center;
      position: absolute;
    }
  }
}
.substation-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 24px 65px;
  margin-top: -180px;
  .substation-content_item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-child {
      .substation-arrow_2 {
        left: unset;
        right: 60px;
        width: 54px;
      }
      .substation-icon:nth-child(2) {
        span {
          left: unset;
          right: 15px;
          &::before,
          &::after {
            left: unset;
            right: 40px;
          }
        }
      }
    }
  }
  .substation-arrow_2 {
    width: 65px;
    position: relative;
    left: 50px;
  }
  .substation-icon {
    display: flex;
    color: #4f5978;
    font-size: 24px;
    position: relative;
    margin-bottom: 75px;
    img {
      width: 157px;
      height: 157px;
    }
    span {
      background: #ffffff;
      box-shadow: 0px 2px 9px 4px rgba(110, 138, 225, 0.12);
      white-space: nowrap;
      min-width: 140px;
      text-align: center;
      padding: 5px 25px 6px;
      position: absolute;
      margin-top: 15px;
      bottom: -60px;
      left: 15px;
      border-radius: 8px;
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 20px 20px;
        border-style: solid;
        border-color: transparent transparent #fff;
        position: absolute;
        top: -15px;
        left: 45px;
        // transform: translate(-50%, 0);
        z-index: 1;
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 20px 20px;
        border-style: solid;
        border-color: transparent transparent #efefef;
        position: absolute;
        top: -20px;
        left: 45px;
        // transform: translate(-50%, 0);
        filter: blur(2px);
      }
    }
  }
  .substation-arrow_3 {
    width: 19px;
    margin-bottom: 7px;
  }
  .substation-arrow_4 {
    width: 378px;
    margin-bottom: 145px;
  }
}
</style>