<template>
  <div class="energy-wrap">
    <div class="energy-banner">
      <div class='h3'>公司资质</div>
      <img src="../assets/img/q_7.png" alt="" />
    </div>
    <div class="survey-wrap">
      <img
        class="survey-img fadeOutUp"
        ref="animate0"
        :class="{ fadeInUp: animateData.animate0 }"
        src='../assets/img/COMPANYQUALIFICATION.png'
      />
      <div
        class="survey-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        公司资质证书
      </div>
      <div class="zz-cont l-between l-wrap">
          <div class='block' :class="getBlockClass(index)" v-for='(itm,index) in zzlist' :key='itm.title'>
              <img :class='"img "+ getImgClass(index) ' :src='itm.url' />
              <div>{{itm.title}}</div>
          </div>
      </div>
    </div>
    <div class="qualification-wrap">
      <div
        class="qualification-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        软件著作权
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in list" :key="index">
            <img :src="item" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { isElementNotInViewport } from "@/utils/index.js";
export default {
  data() {
    return {
      zzlist:[
        
        {url:require("../assets/img/q_1.png"),title:"国家高新技术企业"},
        {url:require("../assets/img/q_2.png"),title:"中关村高新技术企业"},
        {url:require("../assets/img/qualification_9.png"),title:"专精特新"},
        {url:require("../assets/img/q_8.png"),title:"企业信用等级AAA认证"},
        {url:require("../assets/img/q_3.png"),title:"CMMI5资质认证证书"},
        {url:require("../assets/img/q_4.png"),title:"ISO9001企业质量认证体系"},
        {url:require("../assets/img/q_5.png"),title:"ISO27001信息安全认证体系"},
        {url:require("../assets/img/q_6.png"),title:"ISO14001环境管理认证体系"},
      ],
      list: [
        require("../assets/images/1.png"),
        require("../assets/images/2.png"),
        require("../assets/images/3.png"),
        require("../assets/images/4.png"),
        require("../assets/images/5.png"),
        require("../assets/images/6.png"),
        require("../assets/images/7.png"),
        require("../assets/images/8.png"),
        require("../assets/images/9.png"),
        require("../assets/images/10.png"),
        require("../assets/images/11.png"),
        require("../assets/images/12.png"),
        require("../assets/images/13.png"),
        require("../assets/images/14.png"),
        require("../assets/images/15.png"),
        require("../assets/images/16.png"),
        require("../assets/images/17.png"),
        require("../assets/images/18.png"),
      ],
      animateData: {},
    };
  },
  methods: {
    getBlockClass(index){
      if(index > 3){
        return 'block2'
      }
      return 'block'
    },
    getImgClass(index){
      if(index > 3){
        return 'block-img2'
      }
      return 'block-img1'
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
    swiperInit() {
      new Swiper(".swiper-container", {
        slidesPerView: "auto",
        // spaceBetween: -30,
        centeredSlides: true,
        loop: true,
        loopAdditionalSlides: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        centeredSlides: true,
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: true,
        },
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.swiperInit();
    });
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.qualification-title {
  color: #11182e;
  font-size: 36px;
  text-align: center;
  margin-top: 50px;
}
.swiper-container {
  margin-top: 28px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.swiper-wrapper {
  .swiper-slide {
    width: 370px !important;
    transition: 300ms;
    transform: scale(0.8);
    // padding-left: 40px;
    // padding-top: 45px;
    box-sizing: border-box;
    border-radius: 20px;
    img {
      width: 100%;
    }
    &.swiper-slide-active {
      transform: scale(1);
      z-index: 1;
    }
  }
}
.energy-banner{
  position: relative;
  img{
    height:383px;
    width:750px;
  }
  .h3{
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 48px;
    position: absolute;
    left:35px;
    top:40%;
  }
}
.survey-img{
  width:615px;
  margin:0 auto;
  display: block;
  position: relative;
  top:25px;
}
.survey-title{
  font-size: 36px;
  color: #414B59;
  line-height: 47px;
}
.zz-cont{
  padding:54px 30px 30px 30px;
  .block2{
    padding: 20px 0;
  }
  .block{
    width: 336px;
    
    // height: 302px;
    background: #FFFFFF;
    box-shadow: 0px 0px 11px 6px rgba(65, 65, 65, 0.04);
    border-radius: 8px;
    padding:20px 0;
    margin-bottom:21px;
    div{
      font-size: 20px;
      color: #909399;
      line-height: 32px;
      text-align: center;
    }
    .block-img1{
      height:205px;
      width: auto;
      margin-bottom:22px;
    }
    .block-img2{
      height:280px;
      width: auto;
      margin-bottom:22px;
    }
    .img{
      display: block;
      margin:0 auto 10px auto;
    }
    .img3{
      width:308px;
      height:206px;
      margin-bottom:21px;
    }
    .img2{
      width:303px;
      height:205px;
      margin-bottom:22px;
    }
    .img1{
      width:auto;
      height:205px;
      margin-bottom:14px;
    }
    .img4{
      width:303px;
      height:205px;
      margin-bottom:22px;
    }
    .img5{
      width:141px;
      height:199px;
      margin-bottom:22px;
    }
    .img6{
      width:142px;
      height:200px;
      margin-bottom:13px;
    }
    .img7{
      width:141px;
      height:199px;
      margin-bottom:22px;
    }
    .img8{
      width:141px;
      height:199px;
      margin-bottom:22px;
    }
  }
}
</style>