<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/images/product-banner_5.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-top">
        <div class="survey-item survey-item_1">
          <img src="../../assets/images/survey-item_12.png" alt="" />
          <span>故障案例库</span>
        </div>
        <img
          class="survey-dotted survey-dotted_1"
          src="../../assets/images/icon_3.png"
          alt=""
        />
      </div>
      <div class="survey-center">
        <div class="survey-item survey-item_2">
          <img src="../../assets/images/survey-item_13.png" alt="" />
          <span>体系化设备库</span>
        </div>
        <img
          class="survey-dotted survey-dotted_2"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-icon">
          <img src="../../assets/images/survey-icon_5.png" alt="" />
          <span
            >场景化大数据分析
            <br />
            解决方案</span
          >
        </div>

        <img
          class="survey-dotted survey-dotted_3"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-item survey-item_3">
          <img src="../../assets/images/survey-item_14.png" alt="" />
          <span>大数据诊断模型</span>
        </div>
      </div>
      <div class="survey-bottom">
        <img
          class="survey-dotted survey-dotted_4"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-item survey-item_4">
          <img src="../../assets/images/survey-item_15.png" alt="" />
          <span>高效分析引擎</span>
        </div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
    <div class="feature-wrap">
      <div
        class="feature-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="feature">
        <div
          class="feature-item feature-item_13 fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <div>
            <img src="../../assets/images/feature-icon_13.png" alt="" />
          </div>
          <span>数据标准 </span>
        </div>
        <div
          class="feature-item feature-item_14 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          style="transition-delay: 0.2s"
        >
          <div>
            <img src="../../assets/images/feature-icon_14.png" alt="" />
          </div>
          <span>样本丰富 </span>
        </div>
        <div
          class="feature-item feature-item_15 fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          style="transition-delay: 0.4s"
        >
          <div>
            <img src="../../assets/images/feature-icon_15.png" alt="" />
          </div>
          <span>诊断智能 </span>
        </div>
      </div>
    </div>
    <div class="scene-wrap">
      <div
        class="scene-title fadeOutUp"
        ref="animate5"
        :class="{ fadeInUp: animateData.animate5 }"
      >
        应用场景
      </div>
      <scene-swiper :list="sceneList"></scene-swiper>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
    </div>
  </div>
</template>

<script>
import sceneSwiper from "@/components/scene-swiper/index.vue";
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  components: { sceneSwiper },
  data() {
    return {
      sceneList: [
        {
          img: require("@/assets/images/scene-img_8.png"),
          title: "电缆故障案例库与智能诊断",
        },
        {
          img: require("@/assets/images/scene-img_9.png"),
          title: "变压器设备画像",
        },
        {
          img: require("@/assets/images/scene-img_10.png"),
          title: "变压器知识图谱",
        },
        {
          img: require("@/assets/images/scene-img_11.png"),
          title: "变压器负荷预测",
        },
        {
          img: require("@/assets/images/scene-img_12.png"),
          title: "GIS大数据诊断分析",
        },
        {
          img: require("@/assets/images/scene-img_13.png"),
          title: "数据质量分析",
        },
      ],
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.survey-wrap {
  .icon_1 {
    left: 96px;
    top: 237px;
  }
  .icon_2 {
    right: 56px;
    top: 229px;
  }
}
.survey-center {
  padding-top: 13px;
  padding-bottom: 18px;
}
</style>