<template>
  <div id="app" :style="$route.meta.hideHeader?'padding-top: 0':''">
    <Header v-show="!$route.meta.hideHeader"/>
    <keep-alive :include="aliveComponents">
      <router-view class="routerView" />
    </keep-alive>
    <Footer />
  </div>
</template>

<script>
import Header from "./views/header/header";
import Footer from "./views/footer/footer";
export default {
  components: { Header, Footer },
  data() {
    return {
      aliveComponents:[]
    };
  },
  mounted() {
    console.log(this)
    this.$bus.$on('setAliveComponents',this.setAliveComponents)
    let urls = window.location.href;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) 
    ) {
    } else {
      window.location = "http://xiaoling-tech.com"; //可以换成http地址
    }
    this.setscript()
  },
  methods:{
    setscript(){
      
    },
    setAliveComponents(arr){
      this.aliveComponents = arr
    }
  }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow-y: auto;
  padding-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.routerView{
  flex: 1;
}
</style>
