<template>
    <div class="product-wrap">
      <div class="product-banner">
        <img src="../../assets/img/fg15.png" alt="" />
        <!-- <div class='p-top'>
            <h3>基于振动监测的轨道交通智能运维产品</h3>
            <div>Rail transit intelligent operation and maintenance products based on Vibration Monitoring
          </div>
        </div> -->
      </div>
      <div class="survey-wrap survey-wrap0">
        <img
          class="survey-img fadeOutUp"
          ref="animate0"
          :class="{ fadeInUp: animateData.animate0 }"
          src='../../assets/img/f1.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
        产品简介
        </div>
        <div class="cp-cont">
          蜂筑平台聚焦工业互联网发展，通过整合标识解析系统、应用服务系统、运维监管系统、工业智脑和数据交易系统等专业系统，利用工业互联网标识解析体系助力企业数字化转型、智能化升级，实现企业高质量发展。利用工业数据赋能工业发展。
        </div>
        <img class="cp-img1 " src="../../assets/img/fg1.png" alt="" />
      </div>
      

      <div class="survey-wrap survey-wrap1">
        <img
          class="survey-img fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
          src='../../assets/img/f2.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
        平台构成
        </div>
        <div class="gcbox" >
          <van-tabs color="#1F50F3" :ellipsis="false" @click="onClick">
            <van-tab v-for="itm in navList" :title="itm.name" :key="itm.name"> </van-tab>
          </van-tabs>
          <div class="bj"></div>
          <div class="gcblock">
               <img class='limg' :src="navData[navIndex].img" alt="" />
                <h3 class="h3">{{ navData[navIndex].title }}</h3>
                <p class="p">{{ navData[navIndex].txt }}</p>
                <img class='limg2' :src="navData[navIndex].img2" alt="" />
                <h4 class="h4">核心能力<div class="xian"></div></h4>
                <div class="lbox">
                    <div class="l-start lboxrow" :class="{lboxrowborder:index2!=0}" v-for="(item,index2) in navData[navIndex  ].list">
                        <img class='listimg' :src="item.img" alt="" />
                        <div class="lrig" >
                            <h4 class="lh4">{{ item.title }}</h4>
                            <p class="lp">{{item.txt }}</p>
                        </div>
                        

                    </div>
                </div>
          </div>
  
        </div>
      </div>


      <div class="scene-wrap scene-wrap2 ">
          <img
            class="survey-img survey-img1 survey-img2 fadeOutUp"
            ref="animate2"
            :class="{ fadeInUp: animateData.animate2 }"
            src='../../assets/img/PRODUCTADVANTAGES.png'
          />
          <div
            class="survey-title fadeOutUp"
            ref="animate2"
            :class="{ fadeInUp: animateData.animate2 }"
          >
          服务行业
          </div>
         
  
         <div class="cpys_wrap">
            <div class="cpys_card" v-for="(item,index) in yslist" :key="index">
              <img :src="item.img" alt="">
              <div class="title">{{ item.title }}</div>
            </div>
            <div class="cpys_card" style="opacity: 0;"></div>
         </div>
      </div>

      <div class="scene-wrap scene-wrap3 ">
          <img
            class="survey-img survey-img1 survey-img2 fadeOutUp"
            ref="animate3"
            :class="{ fadeInUp: animateData.animate3 }"
            src='../../assets/img/f34.png'
            style="width:8rem !important"
          />
          <div
            class="survey-title fadeOutUp"
            ref="animate3"
            :class="{ fadeInUp: animateData.animate3 }"
          >
          解决方案
          </div>
         
  
         <div class="list">
            <div class="block l-between" v-for="(item,index) in jData" :key="index" @click="path(index)">
              <div class="l-start">
                <img class="img" :src="item.img" alt="">
                <div class="title">{{ item.title }}</div>
              </div>
              <img class="icon" src="../../assets/img/icon_1.png" alt="" />
            </div>
         </div>
      </div>
      

      <div class="survey-wrap survey-wrap4">
        <img
          class="survey-img fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          src='../../assets/img/f56.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
        业务分布地图
        </div>
        <p class="distribution-txt">
          蜂筑工业互联网平台的数据要素业务分布广泛，涉及不同行业，让企业数据在更大范围内发挥更大价值，助力中小企业发展，促进行业进步。目前服务的区域范围以北京为中心辐射至全国各地，涉及到北京、湖南、湖北、江苏、安徽、河南、广东、山东、山西、四川等省份。
        </p>
        <img class="map " src="../../assets/img/fg8.png" alt="" />
      </div>

      <div class="survey-wrap survey-wrap5">
        <img
          class="survey-img fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
          src='../../assets/img/f48.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
        >
        应用场景
        </div>
        <div class="list l-start">
            <div class="block"  v-for="itm in features">
              <img class="img " :src="itm.icon" alt="" />
              <img class="img2 " src="../../assets/img/fg16.png" alt="" />
                <div class="h4">{{ itm.name }}</div>
                <div class="p">{{ itm.txt }}</div>
            </div>
        </div>
        
      </div>

      <div class="survey-wrap survey-wrap7">
        <img
          class="survey-img fadeOutUp"
          ref="animate7"
          :class="{ fadeInUp: animateData.animate7 }"
          src='../../assets/img/f1.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate7"
          :class="{ fadeInUp: animateData.animate7 }"
        >
        业务合作伙伴
        </div>
        
        <img class="img " src="../../assets/img/fg10.png" alt="" />
      </div>
    
  
      
      
  
    </div>
  </template>
  
  <script>
  import Swiper from "swiper";
  import "swiper/css/swiper.min.css";
  // import sceneSwiper from "@/components/scene-swiper/index.vue";
  import { isElementNotInViewport } from "@/utils/index.js";
  
  export default {
    components: {  },
    data() {
      return {
        animateData: {},
        num:0,
        hexlist:[
          {url:require("../../assets/img/v_4.png"),title:"接口自动化测试能力",txt:"针对各类接口，通过编写自动化测试脚本，实现接口功能的自动化测试"},
          {url:require("../../assets/img/v_3.png"),title:"界面自动化测试能力",txt:"针对B/S架构系统，通过录制界面组件+测试脚本，实现界面自动化测试"},
          {url:require("../../assets/img/v_5.png"),title:"移动端自动化测试能力",txt:"针对APP、H5类型软件，通过录制组件+测试脚本，实现移动端自动化测试"},
        ],
        tdlist:[
          {url:require("../../assets/img/v_7.png"),bj:require("../../assets/img/v_6.png"),title:"测试工具全集成",txt:"新建线路和既有线路均可快速搭建"},
          {url:require("../../assets/img/v_9.png"),bj:require("../../assets/img/v_8.png"),title:"测试标准规范化",txt:"提供统一的测试过程管理、测试报表、测试日志，建立统一的指标体系"},
          {url:require("../../assets/img/v_11.png"),bj:require("../../assets/img/v_10.png"),title:"一站式测试平台",txt:"用户可以在一个服务上实现各种类型的测试，实现测试用例的统一性"},
        ],
        yslist:[
          {img:require("../../assets/img/f35.png"),title:"重型设备制造行业"},
          {img:require("../../assets/img/f36.png"),title:"能源行业",},
          {img:require("../../assets/img/f37.png"),title:"型材建筑行业"},
          {img:require("../../assets/img/f38.png"),title:"型材建筑行业"},
          {img:require("../../assets/img/f39.png"),title:"制冷设备制造行业"},
        ],
        navList: [
        {
            name: "标识解析系统",
            icon: require("../../assets/img/f7.png"),
          },
          {
            name: "应用服务系统",
            icon: require("../../assets/img/f8.png"),
          },
          {
            name: "运维监管系统",
            icon: require("../../assets/img/f9.png"),
          },
          {
            name: "工业智脑",
            icon: require("../../assets/img/f10.png"),
          },
          {
            name: "数据交易系统",
            icon: require("../../assets/img/f11.png"),
          },
          
        ],
        navIndex:0,
        navData:[
            {title:'标识解析系统简介',img:require("../../assets/img/f12.png"),img2:require("../../assets/img/fg3.png"),txt:'标识解析系统作为蜂筑平台的基础系统，利用标识解析系统赋予机器、设备、产品，乃至数字对象 “身份证”，打通标识背后的数据链，贯通物理世界和数字空间，实现对企业的数字化赋能。',list:[
            {title:"标识注册、解析和管理能力",img:require("../../assets/img/f17.png"),txt:"注册解析+标识管理，实现全面标识管理"},
            {title:"多层级平台对接能力",img:require("../../assets/img/f18.png"),txt:"国家级平台+第三方服务平台，实现数据互联互通"}
           ]},
           {title:'应用服务系统简介',img:require("../../assets/img/f13.png"),img2:require("../../assets/img/fg4.png"),txt:'专业的标识解析应用集合，针对标识解析典型场景在通用商城中即可找到满足企业发展需求的标识解析应用，快速解决企业数字化转型遇到的问题。针对企业的个性化需求，专业的解决方案团队通过实地调研给出合适的解决方案，助力企业标识解析应用建设。同时提供相关开发接口供有开发能力的企业进行标识应用开发。',list:[
            {title:"快速获取标识解析应用能力",img:require("../../assets/img/f19.png"),txt:"典型场景+SAAS化部署，帮助企业快速构建标识应用"},
            {title:"专业个性化解决方案能力",img:require("../../assets/img/f20.png"),txt:"专业团队+现场调研，全面满足企业的个性化需求"},
            {title:"应用开发能力",img:require("../../assets/img/f21.png"),txt:"技术支持+标准化接口，直接对接开发团队定制开发标识应用"}
           ]},
           {title:'运维监管系统简介',img:require("../../assets/img/f14.png"),img2:require("../../assets/img/fg5.png"),txt:'运维监管系统通过与行业二级节点和企业三级节点对接打通，进行数据汇聚、管理，形成面向全网的MA标识解析系统运行监测能力，为企业和政府监管部门提供统一的运维监管服务，更加直接了解标识解析业务发展情况。',list:[
            {title:"运维管理能力",img:require("../../assets/img/f22.png"),txt:"后期系统自动升级、数据库管理、运维工单管理、系统管理、日志管理"},
            {title:"状态监管能力",img:require("../../assets/img/f23.png"),txt:"了解标识解析二三级节点运行状态、标识解析应用服务运行状态"},
            {title:"服务托管能力",img:require("../../assets/img/f24.png"),txt:"提供系统服务托管、帮助中小企业提供服务器资源"}
           ]},
           {title:'工业智脑简介',img:require("../../assets/img/f15.png"),img2:require("../../assets/img/fg6.png"),txt:'随着接入蜂筑平台企业的数量增加，标识解析生态的逐步建立，针对不同行业，在数据汇聚之后，通过数据分析，利用人工智能技术分析行业发展现状，以及行业未来发展趋势，形成专业的行业分析报告以及相关行业数字产品。',list:[
            {title:"行业数据分析能力",img:require("../../assets/img/f25.png"),txt:"行业发展现状分析"},
            {title:"行业智能预测能力",img:require("../../assets/img/f26.png"),txt:"精准预测行业未来发展趋势"},
            {title:"行业风险预警能力",img:require("../../assets/img/f27.png"),txt:"分析行业发展现状以及未来发展趋势，准确指出行业发展未来面临的潜在风险"}
           ]},
           {title:'数据交易系统简介',img:require("../../assets/img/f16.png"),img2:require("../../assets/img/fg7.png"),txt:'通过数据挖掘，数据清洗等技术手段，结合标识解析统一编码的优势，按照数据交易市场的要求，对数据处理之后进行数据交易。行业内不同企业根据自身实际需求，购买或销售数据，利用数据促进自身技术、市场、服务质量的提升。',type:true,list:[
            {title:"数据挖掘能力",img:require("../../assets/img/f28.png"),txt:"在海量数据中找到对于行业内其他企业具有价值的数据", },
            { title:"数据确权能力",img:require("../../assets/img/f29.png"),txt:"明确企业数据归属权"},
            {title:"区块链能力",img:require("../../assets/img/f30.png"),txt:"利用区块链技术保证企业数据安全，形成专有的数据指纹", },
            {title:"数据交易能力",img:require("../../assets/img/f31.png"),txt:"对接不同数据交易所，为企业数据创造实际价值"}
           ]},
        ],
        jData:[
            {title:"产品售后解决方案",img:require("../../assets/img/f40.png"),txt:"通过对出厂产品赋予唯一标识，当消费者需要售后服务时，扫描产品标识码，联系原厂提供售后统一服务。同时原厂可以通过标识收集到相关用户售后需求信息，进而改进产品生产工艺。",list:[
            {title:"行业现状",txt:"产品售后市场往往采用的是线下记录的方法，包括售后维修以及定期养护，生产厂家对产品售后情况的了解有限，无法准确收集到用户的产品使用情况，不能提供专业的原厂售后服务，导致售后服务市场流失"},
            {title:"行业痛点",txt:"产品没有统一提供售后服务的渠道，消费者无法直接联系到原厂，导致去找第三方提供售后服务，造成售后服务质量下降，影响产品使用体验"},
            {title:"服务效果",txt:"为用户提供官方售后渠道。售后数据改进生产工艺。产品养护数据为厂家提供产品长期使用数据，进而优化生产工艺"},
            // {title:"具体案例",txt:"海螺门窗售后服务系统"}, 
          ]},
          {title:"产品质量追溯解决方案",img:require("../../assets/img/f41.png"),txt:"通过对生产的每一个产品赋予唯一的身份标识，对产品从生产到销售的每个业务流程的关键信息，在标识上进行信息追加，最终消费者可以通过扫码了解产品在各流程所产生的相关信息，实现质量追溯的效果。",list:[
            {title:"行业现状",txt:"消费者在买到产品之后，当产品出现质量问题时，往往无法了解产品生产、物流、销售流程，企业也无法发现问题产品在哪个环节出现了质量问题，导致无法定位问题出现节点以及产生原因。"},
            {title:"行业痛点",txt:"生产信息不明确。业务环节较多，各业务信息不透明。问题产品无法精准定位问题点。"},
            {title:"服务效果",txt:"产品信息透明。精准定位问题产品问题点。提升产品竞争力。"},
            // {title:"具体案例",txt:"冰山集团制冷热设备质量追溯系统"}, 
          ]},
          {title:"供应链生产协同解决方案",img:require("../../assets/img/f42.png"),txt:"对于生产备件来说，通过对主要生产企业的ERP、MES、营销系统内部数据编码进行产业链上共享，备件提供企业可以看到具体备件需求清单，对需求量大的备件加大备货，暂无需求的备件减少备件，降低库存压力。",list:[
            {title:"行业现状",txt:"复杂设备生产制造过程中，同一厂家往往不能生产设备全部配件，需要从不同厂家采购各种配件，之后进行组装生产，整个生产过程需要多个厂家沟通协商设备配件供货时间，整体生产效率偏低。"},
            {title:"行业痛点",txt:"多个厂家共同生产同一设备过程中，涉及到配件备件、技术共享、开发资料共享等问题，不同企业数据标准各不相同，信息共享存在困难。"},
            {title:"服务效果",txt:"行业企业共享数据可以缩减库存、提高配件交付效率、提升产品交付效率、提高供应商粘性、提高供应链稳定性，并降低生产风险。"},
            // {title:"具体案例",txt:"中联重科产业链同频备件应用"}, 
          ]},
          {title:"工程废料管理解决方案",img:require("../../assets/img/f43.png"),txt:"对于废料堆放区域利用标识解析分区进行赋码，标识信息包括废料信息，废料堆放区域信息等，利用统一的数据标准，通过废料管理平台向政府监管部门报送，同时对外发送废料信息，帮助废料需求企业进行对接。",list:[
            {title:"行业现状",txt:"建筑行业等相关工程行业在生产过程中会产生大量业务废料，废料处理往往没有统一管理，政府监管存在困难，废料共同处理存在难度。"},
            {title:"行业痛点",txt:"废料缺少统一管理渠道，政府废料监管存在困难，废料资源共享缺乏信息平台。"},
            {title:"服务效果",txt:"加速废料流通，促进废料交易市场发展。标准化的废料信息上报，加强政府废料监管。废料统一管理，减少环境污染。"},
            // {title:"具体案例",txt:"远大住工废料管理应用"}, 
          ]},
          {title:"工业大脑解决方案",img:require("../../assets/img/f44.png"),txt:"通过标识解析技术，将行业内大中小企业数据汇聚到工业大脑服务平台，进行分析利用。包括产业链图谱，产业链经济监测，产业能耗分析，产业亩均效益分析，产业知识汇聚，产业通讯录，企业大脑，产业预测分析，产业对标分析等功能模块。",list:[
            {title:"行业现状",txt:"政府侧对于行业发展缺少专业化的监管平台。行业政策制定缺乏依据。"},
            {title:"行业痛点",txt:"行业不同企业业务标准不同，导致政府无法利用企业数据进行大规模数据分析助力企业发展，行业发展。"},
            {title:"服务效果",txt:"促进行业数字化、智能化转型。提高行业核心竞争力。加快行业创新发展。促进行业协同发展。推动行业可持续发展。"},
            // {title:"具体案例",txt:"合肥政府工业大脑项目"}, 
          ]},
          {title:"安环应用解决方案",img:require("../../assets/img/f45.png"),txt:"针对不同的安环场景按需利用标识解析对人、物、环境等对象进行赋码，利用摄像头或者传感器对环境进行实时监测，根据监测数据，融合人员管理系统、通信等系统数据，利用人工智能等手段实现安全预警，规范作业流程，降低安全风险。",list:[
            {title:"行业现状",txt:"传统安环应用只是单独建设视频监控系统，依靠视频监控对图像进行分析来进行安全管理。"},
            {title:"行业痛点",txt:"安环应用功能越全面则需要多系统业务数据相互配合，而各个系统间数据标准各不相同，系统数据融合存在困难。"},
            {title:"服务效果",txt:"保护员工人身安全。降低生产成本和风险。提高生产效率。"},
            // {title:"具体案例",txt:"中联重科工厂内部安环应用"}, 
          ]},
        ],
        features: [
         {
            name: "协同生产",
            txt:"利用标识记录产品信息，通过标识解析共享产品生产信息，促进不同企业之间信息数据的互联互通，提高产品质量、生产效率和企业竞争力",
            icon: require("../../assets/img/f49.png"),
            animate: "animate20",
            delay: 0,
          },
          {
            name: "一物一码",
            txt:"利用标识作为产品的唯一标识码，通过扫码获取标识对应产品信息，可以实现产品防伪追溯、防窜货等功能，建立基于唯一标识的多应用模式",
            icon: require("../../assets/img/f50.png"),
            animate: "animate21",
            delay: 0.1,
          },
          {
            name: "产品全生命周期管理",
            txt:"通过标识打通不同系统间的数据壁垒，帮助企业实现业务环节信息的对接与互通，实现设计、生产、市场、售后信息数字化 ",
            icon: require("../../assets/img/f51.png"),
            animate: "animate22",
            delay: 0.2,
          },
          {
            name: "设备故障预测及健康管理",
            txt:"利用标识解析技术，通过规范行业设备相关编码规范，实现各行各业、各类企业通过唯一的标识码即可实现互联互通和设备远程运维及预测性维护",
            icon: require("../../assets/img/f52.png"),
            animate: "animate23",
            delay: 0.3,
          },
          {
              name: "数字资产共享与交付",
              txt:"将企业内人员信息、设备的身份信息、设计图纸、工艺流程图、说明书、安装维修手册等虚拟文档，通过标识解析技术赋予唯一标识，进行数字化交付。",
              icon: require("../../assets/img/f67.png"),
              animate: "animate23",
              delay: 0,
            },
            {
              name: "供应链优化管理",
              txt:"配件、物流、整机企业通过唯一标识在标识解析平台解析、查询，解决了企业间因信息不对称引起的生产效率低下问题，实现优化供应链管理。",
              icon: require("../../assets/img/f68.png"),
              animate: "animate23",
              delay: 0.1,
            },
            {
              name: "安全生产立体监控",
              txt:"基于统⼀编码和解析服务，在不同时间、空间下，实现对人、机、物的全面管控，制定更加高效、低成本的安全方案。",
              icon: require("../../assets/img/f69.png"),
              animate: "animate23",
              delay: 0.2,
            },
            {
              name: "防伪防窜货",
              txt:"基于标识的防伪功能与标签防伪技术相结合，采用一物一码技术，让用户通过扫码判断产品真伪，进一步了解产品信息，提升企业品牌信任度。",
              icon: require("../../assets/img/f70.png"),
              animate: "animate23",
              delay: 0.3,
            },
          {
            name: "产品追溯",
            txt:"利用标识解析技术，通过对物品赋予唯一防篡可信的标识码，可实现产品全生命周期追溯和产品生产、物流、销售、服务全过程精细化管理。",
            icon: require("../../assets/img/f71.png"),
            animate: "animate23",
            delay: 0,
          },
          {
            name: "认证授权",
            txt:"利用标识赋码实现统一设备身份认证以及可信数据传输，从源头上保证工业数据的合法性和安全性，服务数据流通，实现万物互联。",
            icon: require("../../assets/img/f72.png"),
            animate: "animate23",
            delay: 0.1,
          }
        ]
      };
    },
    methods: {
      path(itm){
        this.$router.push({
          path: '/productN/productFgDetail?id='+itm,
        });
      },
      onClick(name, title){
        this.navIndex = name
        console.log(name)
        console.log(title)
      },
      imgLoad(){
        this.num++
        if(this.num==4){
          this.swiperInit();
        }
      },
      // 滚动事件
      scrollToTop() {
        for (let i in this.$refs) {
          !isElementNotInViewport(this.$refs[i])
            ? this.$set(this.animateData, i, true)
            : this.$set(this.animateData, i, false);
        }
      },
      swiperInit() {
        new Swiper(".swiper-container", {
          slidesPerView: "auto",
          spaceBetween: -10,
          centeredSlides: true,
          loop: true,
          loopAdditionalSlides: 10,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          centeredSlides: true,
          coverflowEffect: {
            rotate: 30,
            stretch: 10,
            depth: 60,
            modifier: 2,
            slideShadows: true,
          },
        });
      },
    },
    mounted() {
      // setTimeout(()=>{
      //   this.swiperInit();
      // },5000)
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
  };
  </script>
  
  <style lang='less' scoped>
  .distribution-txt{
    padding: 23px;
    padding-top: 50px;
    font-family: MicrosoftYaHei;
    font-size: 24px;
    color: #909399;
    line-height: 48px;
    text-align: center;
    font-style: normal;
  }
  .swiper-container {
    margin-top: 45px;
    padding-bottom: 80px;
    margin-bottom: 20px;
  }
  .swiper-wrapper {
    .swiper-slide {
      background-image: url("../../assets/images/rebot-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 425px !important;
      height: 588px;
      transition: 300ms;
      transform: scale(0.8);
      padding-left: 40px;
      padding-top: 45px;
      box-sizing: border-box;
      border-radius: 20px;
      .rebot-title {
        font-size: 28px;
        color: #555;
        position: relative;
        line-height: 38px;
        &::after {
          content: "";
          position: absolute;
          width: 44px;
          height: 8px;
          bottom: -8px;
          left: 0;
          background: #b2b2b2;
        }
      }
      .rebot-name {
        font-size: 22px;
        color: #555;
        line-height: 30px;
        margin-top: 32px;
      }
      .rebot-img {
        position: absolute;
        right: 20px;
        bottom: 20px;
        img {
          width: 200px;
        }
      }
      &.swiper-slide_1 {
        .rebot-img {
          img {
            width: 242px;
          }
        }
      }
      &.swiper-slide_2 {
        .rebot-img {
          img {
            width: 200px;
          }
        }
      }
      &.swiper-slide_3 {
        .rebot-img {
          img {
            width: 230px;
          }
        }
      }
      &.swiper-slide_4 {
        .rebot-img {
          img {
            width: 250px;
          }
        }
      }
      &.swiper-slide-active {
        background-image: url("../../assets/images/rebot-bg-active.png");
        transform: scale(1);
        z-index: 1;
        .rebot-title {
          color: #fff;
          &::after {
            background-color: #01ff66;
          }
        }
        .rebot-name {
          color: #fff;
        }
      }
      &.swiper-slide-prev {
        transform: translate(210px, 0) scale(0.8);
      }
      &.swiper-slide-next {
        transform: translate(-220px, 0) scale(0.8);
      }
    }
  }
  ::v-deep .swiper-pagination {
    .swiper-pagination-bullet {
      background-image: url("../../assets/images/pagination.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: transparent;
      opacity: 1;
      width: 20px;
      height: 20px;
      outline: 0;
    }
    .swiper-pagination-bullet-active {
      background-image: url("../../assets/images/pagination-active.png");
    }
  }
  
  .survey-title{
    margin-top:-20px;
    color: #11182e;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    color: #414B59;
  }
  .survey-title-fff{
    color:#fff;
  }
  .survey-img{
    width:660px;
    height:40px;
    display: block;
    margin: 0 auto;
  }
  .survey-img1{
     width:504px;
    height:40px;
  }
  .cp-cont{
    font-size: 24px;
    color: #909399;
    line-height: 48px;
    padding:23px 28px 0 26px;
    text-align: center;
    span{
       color:#414b59;
      font-size:28px
    }
  }
  .cp-img1{
    width:480px;
    margin:40px auto;
    display: block;
    margin-bottom:50px;
  }
  
  .feature-wrap{
    background: #3E76E0;
  }
  .hx-box{
    padding:0 15px;
    .hx-block{
      height:158px;
      width:100%;
      img{
        margin-top:30px;
        margin-right:30px;
      }
      .img1{
        width:78px;
        height:91px;
      }
      .img2{
        width:90px;
        height:94px;
      }
      .img3{
        width:83px;
        height:86px;
      }
      .img4{
        width:84px;
        height:84px;
      }
      .img5{
        width:82px;
        height:87px;
      }
      .img6{
        width:87px;
        height:73px;
      }
      .hx-block-rig{
        width:291px;
        height:100%;
        border-bottom:1px dashed  rgba(255, 255, 255, .39);
        .hx-block-title{
          font-size: 12px;
          color: #FFFFFF;
          line-height: 15px;
          margin-top:18px;
        }
        .hx-block-txt{
            font-size: 10px;
            color: rgba(255, 255, 255, .66);
            line-height: 15px;
            margin-top:4px;
        }
      }
      .hx-block-rig6{
        border-bottom: none;;
      }
    }
  }
  .td-box{
    padding:0 28px;
    padding-bottom: 75px;
    .td-block{
      width:100%;
      height:197px;
      position: relative;
      margin-top:18px;
      .imgbj{
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index:-1;
      }
      .img{
        
      }
      .img1{
        width:96px;
        height:91px;
        margin:54px 24px 0 37px;
      }
      .img2{
        width:75px;
        height:77px;
        margin:64px 45px 0 37px;
      }
      .img3{
        width:79px;
        height:77px;
        margin:64px 41px 0 37px;
      }
      .img4{
        width:88px;
        height:84px;
        margin:55px 45px 0 30px;
      }
      .img5{
        width:85px;
        height:78px;
        margin:59px 42px 0 36px;
      }
    
      .td-rig{
        .td-title{
            height: 15px;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 15px;
            margin:33px 0 9px 0;
        }
        .td-txt{
          color: rgba(255, 255, 255, .9);
          font-size: 10px;
          line-height: 16px;
          padding-right:10px;
        }
      }
    }
  }
  
  
  .survey-img2{
    max-width:620px;
  }
  
  .yy-box{
    padding:0 28px;
    .yy-block{
      width: 386px;
      height: 339px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);
      .img{
        width:386px;
        height:249px;
      }
      .yy-title{
        font-size: 24px;
        color: #909399;
        line-height: 31px;
        text-align: center;
        margin-top:28px;
      }
    }
  }
  .p-top{
    position: absolute;
    top:79px;
    left:35px;
    h3{
      width: 360px;
      height: 96px;
      font-size: 30px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 48px;
    }
    div{
      width: 342px;
      height: 36px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, .49);
      line-height: 27px;
      margin-top:38px;
    }
  }
  .swiper-container{
      margin-top:0;
  }
  .scene-wrap-ys{
    background: rgba(246, 248, 253, 1);
  }
  .cpys_wrap{
    padding: 50px 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cpys_card{
      width: 331px;
      height: 329px;
      text-align: center;
      padding: 38px 26px;
      background-color: #fff;
      box-shadow: 0px 0px 10px 9px rgba(132,181,255,0.08);
      &:first-of-type{
        margin-bottom: 26px;
      }
      img{
        width: 88px;
        height: 103px;
      }
      .title{
        font-size: 24px;
        line-height: 35px;
        height: 35px;
        font-weight: 600;
        color: rgba(65, 75, 89, 1);
      }
      .text{
        width: 279px;
        height: 82px;
        font-size: 22px;
        color: #999999;
        line-height: 34px;
      }
    }
  }

  .survey-wrap0{
    margin-top:-10px;
      background: url("../../assets/img/fg2.png") no-repeat top center;
      background-size: 100% 100%;
      background-position-y: -10px;
      width:750px;
      height:887px;
      .cp-img1 {
        width: 100%;
      }

  }
  .survey-wrap1{
    .survey-title{
      margin-top: 0;
    }
    
    .gcbox{
      .bj{
        box-shadow: 0px 6px 7px 4px rgba(122,122,122,0.05);
        width: 100%;
      }
      .gcblock{
        padding:34px 25px;
        position: relative;
        .limg{
          width:66px;
          height: 78px;
        }
        .limg2{
          position: absolute;
          right:48px;
          top:52px;
          width:85px;
        }
        .h3{
          height: 40px;
          font-size: 30px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #414B59;
          line-height: 40px;
          margin:4px 0 10px 0;
        }
        .p{
          font-size: 24px;
          font-family: MicrosoftYaHei;
          color: #909399;
          line-height: 38px;
        }
        .h4{
                height: 37px;
                font-size: 28px;
                font-family: MicrosoftYaHei;
                color: #414B59;
                line-height: 37px;
                position: relative;
                margin:52px 0 10px 0;
                z-index: 1;
                .xian{
                    width: 112px;
                    height: 13px;
                    background: #428DE8;
                    position: absolute;
                    left:0px;
                    bottom:3px;
                    z-index: -1;
                    opacity: 0.5;
                }
            }

          .lbox{
            .lboxrow{
              height:150px;
              padding-top:30px;
            }
            .lboxrowborder{
              border-top: 1px dashed #E4E4E4;
            }
            .listimg{
              width: 88px !important;
              height:88px !important;
              margin-right: 10px;
            }
            .lh4{
              height: 37px;
              font-size: 28px;
              font-family: MicrosoftYaHei;
              color: #414B59;
              line-height: 37px;
              margin-top:5px;
            }
            .lp{
              height: 30px;
              font-size: 22px;
              font-family: MicrosoftYaHei;
              color: #95989D;
              line-height: 30px;
              margin-top:10px;
            }
          }
      }
    }
    

  }

  .scene-wrap2{
    
    background: #F6F8FD;
    .cpys_wrap{
      padding: 25px 25px 70px 25px;
      .cpys_card{
        width: 221px;
        height:221px;
        box-shadow:none;
        padding: 38px 5px;
      }
    }
  }

  .scene-wrap3{
    margin-bottom:50px;
    .list{
      .block{
        width: 702px;
        height: 100px;
        background: #F3F8FF;
        border-radius: 8px;
        margin:0 auto;
        margin-top:20px;
        .img{
          max-height:60px;
          margin:24px 25px 0 26px;
        }
        .title{
          font-size: 30px;
          font-family: MicrosoftYaHei;
          color: #414B59;
          line-height: 100px;
        }
        .icon{
          width:28px;
          height:28px;
          margin:36px 24px 0 0;
        }

      }
    }
  }

  .survey-wrap4{
    .map{
      display: block ;
      width:727px;
      margin:33px auto;
      margin-bottom:70px;
    }
  }
  
  .survey-wrap5{
    width: 750px;
    height: 597px;
    background: url("../../assets/img/fg9.png") no-repeat top center;
    background-size: 100% 100%;
    background-position-y: 0;
    .survey-title{
      color:#fff;
    }
    .list{
      margin-top: 60px;
      padding:0 32px;
      width:100%;
      overflow: scroll;
      -ms-overflow-style: none; /* IE11 */
      scrollbar-width: none; /* Firefox 64+ */
    }
    .list::-webkit-scrollbar {
      display: none; /* WebKit browsers */
    }
    .block{
      min-width: 444px;
      height: 362px;
      background: #FFFFFF;
      border-radius: 4px;
      padding:31px 22px;
      margin-right:20px;
      position: relative;
      .img{
        width:79px;
      }
      .img2{
        position: absolute;
        top:0;
        right:20px;
        width:106px;
      }
      .h4{
        height: 37px;
        font-size: 28px;
        font-family: MicrosoftYaHei;
        color: #414B59;
        line-height: 37px;
        margin:11px 0 12px 0;
      }
      .p{
          font-size: 24px;
          font-family: MicrosoftYaHei;
          color: #95989D;
          line-height: 36px;
      }
    }
  }


  .survey-wrap7{
    
      background: #F6F8FD;
    .img{
      display: block;
      width:698px;
      margin:0 auto;
      margin-top:37px;
      padding-bottom:72px;
    }
  }
  </style>