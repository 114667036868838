const nav = [{
        title: "蜂筑工业互联网平台",
        url: "/productN/productFg",
    },
    {
        title: "数据资产确权登记平台",
        url: "/productN/dataConfirmationRegistration",
    },
    {
        title: "数据产品可信交易平台",
        url: "/productN/trustedDataTrading",
    },
    {
        title: "TMS自动化测试系统",
        url: "/productN/productVibration",
    },
    {
        title: "CRM客户关系管理系统",
        url: "/productN/productRobot",
    },
    // {
    //     title: "EMS设备运维管理系统",
    //     url: "/productN/productHSE",
    // },
    
    
    // {
    //     title: "商拓邦微信小程序",
    //     url: "/productN/productWX",
    // },
    // {
    //     title: "DCMM贯标培训服务",
    //     url: "/productN/productDcmm",
    // },
]
export default nav