<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/img/p_1.png" />
      <!-- <div class='p-top'>
          <h3>基于卫星的可视化智能监拍产品</h3>
          <div>Visual intelligent monitoring products in non signal area based on satellite</div>
      </div> -->
    </div>
    <div class="survey-wrap">
      <img
        class="survey-img fadeOutUp"
        ref="animate0"
        :class="{ fadeInUp: animateData.animate0 }"
        src='../../assets/img/PRODUCTINTRODUCTION.png'
      />
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品简介
      </div>
      <div class="cp-cont">
        标识解析系统作为工业互联网的基础设施，支持通过赋予机器、设备、产品，乃至数字对象 “身份证”，打通标识背后的数据链，贯通物理世界和数字空间，并通过集成供应链管理、产品溯源、产品全生命周期管理等创新应用，实现对企业的数字化赋能。
      </div>
      <img class="cp-img1" src="../../assets/img/productSatellite_1.png" alt="" />
    </div>
    <div class="feature-wrap">
         <img
          class="survey-img survey-img1 fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
          src='../../assets/img/CORECOMPETENCE.png'
        />
        <div
          class="survey-title survey-title-fff fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          核心能力
        </div>
        <div class='hx-box'>
            <div class='hx-block l-between' v-for='(itm,index) in hexlist' :key='itm.title'>
                <img :class='"img"+(index+1)' :src='itm.url'>
                <div class='hx-block-rig' :class='"hx-block-rig"+(index+1)'>
                    <div class='hx-block-title f24'>{{itm.title}}</div>
                    <div class='hx-block-txt f20'>{{itm.txt}}</div>
                </div>
            </div>
        </div>




    </div>
    <div class="scene-wrap">
        <img
          class="survey-img survey-img1 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          src='../../assets/img/PRODUCTFEATURES.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
          产品特点
        </div>
        <div class='td-box'>
            <div class='td-block l-start' :class='"td-block"+(index+1)' v-for='(itm,index) in tdlist' :key='itm.title'>
                <img :class='"imgbj imgbj"+(index+1)' :src='itm.bj'>
                <img :class='"img img"+(index+1)' :src='itm.url'>
                 <div class='td-rig' >
                    <div class='td-title f24'>{{itm.title}}</div>
                    <div class='td-txt f20'>{{itm.txt}}</div>
                </div>
            </div>
        </div>

      
    </div>

    <div class="scene-wrap">
        <img
          class="survey-img survey-img1 survey-img2 fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
          src='../../assets/img/APPLICATIONSCENARIO.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
        >
          应用场景
        </div>
       <scene-swiper :list="yylist"></scene-swiper>
       <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />

      
    </div>
    

  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import sceneSwiper from "@/components/scene-swiper/index.vue";
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  components: { sceneSwiper },
  data() {
    return {
      animateData: {},
      num:0,
      hexlist:[
        {url:require("../../assets/img/productSatellite_2.png"),title:"标识注册、解析和管理能力",txt:"注册解析+标识管理，实现资产数字化管理"},
        {url:require("../../assets/img/productSatellite_3.png"),title:"“五个一”应用服务能力",txt:"一码多能，实现解析、管控、协同、共享、溯"},
        {url:require("../../assets/img/productSatellite_4.png"),title:"多层级平台对接能力",txt:"国家级平台+第三方服务平台，实现数据互联"},
        // {url:require("../../assets/img/productSatellite_5.png"),title:"多设备本地化组网能力",txt:"多终端设备本地化组网，实现数据集中和分发"},
        // {url:require("../../assets/img/productSatellite_6.png"),title:"边缘侧数据处理能力",txt:"边缘计算终端+轻量化AI应用，实现高效分析"},
        // {url:require("../../assets/img/productSatellite_7.png"),title:"高通量数据传输能力",txt:"高通量卫星通讯+本地无线通讯，实现数据高速传输"},
      ],
      tdlist:[
        {url:require("../../assets/img/productSatellite_9.png"),bj:require("../../assets/img/productSatellite_8.png"),title:"国际标准码",txt:"采用符合国际标准的MA标识编码体系"},
        {url:require("../../assets/img/productSatellite_11.png"),bj:require("../../assets/img/productSatellite_10.png"),title:"场景化应用",txt:"围绕标识解析构建生产、商贸等多领域应用"},
        {url:require("../../assets/img/productSatellite_13.png"),bj:require("../../assets/img/productSatellite_12.png"),title:"定制化服务",txt:"支持结合客户需求提供个性化应用服务"},
        {url:require("../../assets/img/productSatellite_18.png"),bj:require("../../assets/img/productSatellite_14.png"),title:"SAAS化设计",txt:"易拓展、易部署、易维护"},
      ],
      yylist:[
        {img:require("../../assets/img/p_15.png"),title:"产品全生命周期管理"},
        {img:require("../../assets/img/p_16.png"),title:"产品质量追溯"},
        {img:require("../../assets/img/p_17.png"),title:"产品防窜货"},
        {img:require("../../assets/img/p_18.png"),title:"供应链协同"},
      ]
    };
  },
  methods: {
    imgLoad(){
      this.num++
      if(this.num==4){
        this.swiperInit();
      }
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
    swiperInit() {
      new Swiper(".swiper-container", {
        slidesPerView: "auto",
        spaceBetween: -10,
        centeredSlides: true,
        loop: true,
        loopAdditionalSlides: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        centeredSlides: true,
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: true,
        },
      });
    },
  },
  mounted() {
    // setTimeout(()=>{
    //   this.swiperInit();
    // },5000)
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.swiper-container {
  margin-top: 45px;
  padding-bottom: 80px;
  margin-bottom: 20px;
}
.swiper-wrapper {
  .swiper-slide {
    background-image: url("../../assets/images/rebot-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 425px !important;
    height: 588px;
    transition: 300ms;
    transform: scale(0.8);
    padding-left: 40px;
    padding-top: 45px;
    box-sizing: border-box;
    border-radius: 20px;
    .rebot-title {
      font-size: 28px;
      color: #555;
      position: relative;
      line-height: 38px;
      &::after {
        content: "";
        position: absolute;
        width: 44px;
        height: 8px;
        bottom: -8px;
        left: 0;
        background: #b2b2b2;
      }
    }
    .rebot-name {
      font-size: 22px;
      color: #555;
      line-height: 30px;
      margin-top: 32px;
    }
    .rebot-img {
      position: absolute;
      right: 20px;
      bottom: 20px;
      img {
        width: 200px;
      }
    }
    &.swiper-slide_1 {
      .rebot-img {
        img {
          width: 242px;
        }
      }
    }
    &.swiper-slide_2 {
      .rebot-img {
        img {
          width: 200px;
        }
      }
    }
    &.swiper-slide_3 {
      .rebot-img {
        img {
          width: 230px;
        }
      }
    }
    &.swiper-slide_4 {
      .rebot-img {
        img {
          width: 250px;
        }
      }
    }
    &.swiper-slide-active {
      background-image: url("../../assets/images/rebot-bg-active.png");
      transform: scale(1);
      z-index: 1;
      .rebot-title {
        color: #fff;
        &::after {
          background-color: #01ff66;
        }
      }
      .rebot-name {
        color: #fff;
      }
    }
    &.swiper-slide-prev {
      transform: translate(210px, 0) scale(0.8);
    }
    &.swiper-slide-next {
      transform: translate(-220px, 0) scale(0.8);
    }
  }
}
::v-deep .swiper-pagination {
  .swiper-pagination-bullet {
    background-image: url("../../assets/images/pagination.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    opacity: 1;
    width: 20px;
    height: 20px;
    outline: 0;
  }
  .swiper-pagination-bullet-active {
    background-image: url("../../assets/images/pagination-active.png");
  }
}

.survey-title{
  margin-top:-20px;
  color: #11182e;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: #414B59;
}
.survey-title-fff{
  color:#fff;
}
.survey-img{
  width:660px;
  height:40px;
  display: block;
  margin: 0 auto;
}
.survey-img1{
   width:504px;
  height:40px;
}
.cp-cont{
  font-size: 24px;
  color: #909399;
  line-height: 48px;
  padding:23px 28px 0 26px;
  text-align: center;
  span{
    color:#414b59;
    font-size:28px
  }
}
.cp-img1{
  width:750px;
  margin-top:-45px;
}

.feature-wrap{
  background: #3E76E0;
}
.hx-box{
  padding:0 15px;
  .hx-block{
    height:158px;
    width:100%;
    img{
      margin-top:30px;
      margin-right:30px;
    }
    .img1{
      width:78px;
      height:91px;
    }
    .img2{
      width:90px;
      height:94px;
    }
    .img3{
      width:83px;
      height:86px;
    }
    .img4{
      width:84px;
      height:84px;
    }
    .img5{
      width:82px;
      height:87px;
    }
    .img6{
      width:87px;
      height:73px;
    }
    .hx-block-rig{
      width:291px;
      height:100%;
      border-bottom:1px dashed  rgba(255, 255, 255, .39);
      .hx-block-title{
        font-size: 12px;
        color: #FFFFFF;
        line-height: 15px;
        margin-top:18px;
      }
      .hx-block-txt{
          font-size: 10px;
          color: rgba(255, 255, 255, .66);
          line-height: 15px;
          margin-top:4px;
      }
    }
    .hx-block-rig6{
      border-bottom: none;;
    }
  }
}
.td-box{
  padding:0 28px;
  .td-block{
    width:100%;
    height:197px;
    position: relative;
    margin-top:18px;
    .imgbj{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      z-index:-1;
    }
    .img{
      
    }
    .img1{
      width:79px;
      height:98px;
      margin:45px 36px 0 40px;
    }
    .img2{
      width:88px;
      height:75px;
      margin:61px 31px 0 36px;
    }
    .img3{
      width:88px;
      height:89px;
      margin:50px 31px 0 36px;
    }
    .img4{
      width:87px;
      height:89px;
      margin:50px 29px 0 33px;
    }
    .td-rig{
      .td-title{
          height: 15px;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 15px;
          margin:33px 0 9px 0;
      }
      .td-txt{
          height: 24px;
          font-size: 10px;
          color: rgba(255, 255, 255, .9);
          line-height: 12px;
      }
    }
  }
}


.survey-img2{
  width:620px;
}

.yy-box{
  padding:0 28px;
  .yy-block{
    width: 386px;
    height: 339px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);
    .img{
      width:386px;
      height:249px;
    }
    .yy-title{
      font-size: 24px;
      color: #909399;
      line-height: 31px;
      text-align: center;
      margin-top:28px;
    }
  }
}
.p-top{
  position: absolute;
  top:79px;
  left:35px;
  h3{
    width: 360px;
    height: 96px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 48px;
  }
  div{
    width: 342px;
    height: 36px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, .49);
    line-height: 27px;
    margin-top:38px;
  }
}
.swiper-container{
    margin-top:0;
}

</style>