<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/img/v_1.png" alt="" />
      <!-- <div class='p-top'>
          <h3>基于振动监测的轨道交通智能运维产品</h3>
          <div>Rail transit intelligent operation and maintenance products based on Vibration Monitoring
        </div>
      </div> -->
    </div>
    <div class="survey-wrap">
      <img
        class="survey-img fadeOutUp"
        ref="animate0"
        :class="{ fadeInUp: animateData.animate0 }"
        src='../../assets/img/PRODUCTINTRODUCTION.png'
      />
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品简介
      </div>
      <div class="cp-cont">
        
        采用B/S架构，集项目管理、需求管理、缺陷管理、脚本设计、用例管理、自动化任务管理、报表管理、系统管理等功能于一体，覆盖“API自动化测试”、“UI自动化测试”、“APP自动化测试”、“性能效率测试”、“安全渗透测试”、“业务可用巡测”等测试类型，完整覆盖自动化测试项目的全过程，可快速协助用户建立自动化测试管理体系建立，提高测试效率与质量。

      </div>
      <img class="cp-img1" src="../../assets/img/v_2.png" alt="" />
    </div>
    <div class="feature-wrap">
         <img
          class="survey-img survey-img1 fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
          src='../../assets/img/CORECOMPETENCE.png'
        />
        <div
          class="survey-title survey-title-fff fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          核心能力
        </div>
        <div class='hx-box'>
            <div class='hx-block l-between' v-for='(itm,index) in hexlist' :key='itm.title'>
                <img :class='"img"+(index+1)' :src='itm.url'>
                <div class='hx-block-rig' :class='"hx-block-rig"+(index+1)'>
                    <div class='hx-block-title f24'>{{itm.title}}</div>
                    <div class='hx-block-txt f20'>{{itm.txt}}</div>
                </div>
            </div>
        </div>




    </div>
    <div class="scene-wrap">
        <img
          class="survey-img survey-img1 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          src='../../assets/img/PRODUCTFEATURES.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
          产品特点
        </div>
        <div class='td-box'>
            <div class='td-block l-start' :class='"td-block"+(index+1)' v-for='(itm,index) in tdlist' :key='itm.title'>
                <img :class='"imgbj imgbj"+(index+1)' :src='itm.bj'>
                <img :class='"img img"+(index+1)' :src='itm.url'>
                 <div class='td-rig' >
                    <div class='td-title f24'>{{itm.title}}</div>
                    <div class='td-txt f20'>{{itm.txt}}</div>
                </div>
            </div>
        </div>

      
    </div>

    <div class="scene-wrap scene-wrap-ys">
        <img
          class="survey-img survey-img1 survey-img2 fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
          src='../../assets/img/PRODUCTADVANTAGES.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
        >
          产品优势
        </div>
       
       <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />

       <div class="cpys_wrap">
          <div class="cpys_card" v-for="(item,index) in yslist" :key="index">
            <img :src="item.img" alt="">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
       </div>
    </div>
    

  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
// import sceneSwiper from "@/components/scene-swiper/index.vue";
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  components: {  },
  data() {
    return {
      animateData: {},
      num:0,
      hexlist:[
        {url:require("../../assets/img/productRobot_21.png"),title:"接口自动化测试能力",txt:"针对各类接口，通过编写自动化测试脚本，实现接口功能的自动化测试"},
        {url:require("../../assets/img/productRobot_22.png"),title:"界面自动化测试能力",txt:"针对B/S架构系统，通过录制界面组件+测试脚本，实现界面自动化测试"},
        {url:require("../../assets/img/productRobot_23.png"),title:"移动端自动化测试能力",txt:"针对APP、H5类型软件，通过录制组件+测试脚本，实现移动端自动化测试"},
        {url:require("../../assets/img/productRobot_24.png"),title:"性能效率测试能力",txt:"针对接口、界面等，通过压力、负载等指标配置，实现系统性能效率测试"},
        {url:require("../../assets/img/productRobot_25.png"),title:"安全渗透测试能力",txt:"针对系统、代码等，通过报文渗透攻击、扫描代码漏洞，实现系统安全测试"},
        {url:require("../../assets/img/productRobot_26.png"),title:"业务可用巡测能力",txt:"针对系统，通过检测系统功能、环境相关健康指标，实现系统业务可用性测试"},
      ],
      tdlist:[
        {url:require("../../assets/img/v_11.png"),bj:require("../../assets/img/v_10.png"),title:"一站式测试平台",txt:"以ATF为核心，集成多种自动化测试工具，全面满足研发、运维的测试需求"},
        {url:require("../../assets/img/v_7.png"),bj:require("../../assets/img/v_8.png"),title:"生态化体系支撑",txt:"以DevOps理念为支撑，通过管理统一、能力共享，实现提质、降本和增效"},
        {url:require("../../assets/img/v_9.png"),bj:require("../../assets/img/v_6.png"),title:"全闭环测试管理",txt:"贯穿需求、用例、缺陷管理全过程，实现需求与用例、用例与缺陷双向追踪"},
        {url:require("../../assets/img/v_23.png"),bj:require("../../assets/img/v_24.png"),title:"工具化应用能力",txt:"构建多种类工具集，全方位满足客户在环境、测试、管理、运维的核心诉求"},
      ],
      yslist:[
        {img:require("../../assets/img/v_16.png"),title:"更全面",text:'可实现接口、界面、移动端、性能、安全的全面测试'},
        {img:require("../../assets/img/v_17.png"),title:"更高效",text:'可自动生成高覆盖的测试用例，大幅提升工作效率'},
        {img:require("../../assets/img/v_18.png"),title:"更方便",text:'可实现脚本与数据分离，便于维护和共享'},
        {img:require("../../assets/img/v_19.png"),title:"更灵活",text:'可与其他系统集成，支持二次开发和私有化部署'},
      ]
    };
  },
  methods: {
    imgLoad(){
      this.num++
      if(this.num==4){
        this.swiperInit();
      }
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
    swiperInit() {
      new Swiper(".swiper-container", {
        slidesPerView: "auto",
        spaceBetween: -10,
        centeredSlides: true,
        loop: true,
        loopAdditionalSlides: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        centeredSlides: true,
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: true,
        },
      });
    },
  },
  mounted() {
    // setTimeout(()=>{
    //   this.swiperInit();
    // },5000)
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.swiper-container {
  margin-top: 45px;
  padding-bottom: 80px;
  margin-bottom: 20px;
}
.swiper-wrapper {
  .swiper-slide {
    background-image: url("../../assets/images/rebot-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 425px !important;
    height: 588px;
    transition: 300ms;
    transform: scale(0.8);
    padding-left: 40px;
    padding-top: 45px;
    box-sizing: border-box;
    border-radius: 20px;
    .rebot-title {
      font-size: 28px;
      color: #555;
      position: relative;
      line-height: 38px;
      &::after {
        content: "";
        position: absolute;
        width: 44px;
        height: 8px;
        bottom: -8px;
        left: 0;
        background: #b2b2b2;
      }
    }
    .rebot-name {
      font-size: 22px;
      color: #555;
      line-height: 30px;
      margin-top: 32px;
    }
    .rebot-img {
      position: absolute;
      right: 20px;
      bottom: 20px;
      img {
        width: 200px;
      }
    }
    &.swiper-slide_1 {
      .rebot-img {
        img {
          width: 242px;
        }
      }
    }
    &.swiper-slide_2 {
      .rebot-img {
        img {
          width: 200px;
        }
      }
    }
    &.swiper-slide_3 {
      .rebot-img {
        img {
          width: 230px;
        }
      }
    }
    &.swiper-slide_4 {
      .rebot-img {
        img {
          width: 250px;
        }
      }
    }
    &.swiper-slide-active {
      background-image: url("../../assets/images/rebot-bg-active.png");
      transform: scale(1);
      z-index: 1;
      .rebot-title {
        color: #fff;
        &::after {
          background-color: #01ff66;
        }
      }
      .rebot-name {
        color: #fff;
      }
    }
    &.swiper-slide-prev {
      transform: translate(210px, 0) scale(0.8);
    }
    &.swiper-slide-next {
      transform: translate(-220px, 0) scale(0.8);
    }
  }
}
::v-deep .swiper-pagination {
  .swiper-pagination-bullet {
    background-image: url("../../assets/images/pagination.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    opacity: 1;
    width: 20px;
    height: 20px;
    outline: 0;
  }
  .swiper-pagination-bullet-active {
    background-image: url("../../assets/images/pagination-active.png");
  }
}

.survey-title{
  margin-top:-20px;
  color: #11182e;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: #414B59;
}
.survey-title-fff{
  color:#fff;
}
.survey-img{
  width:660px;
  height:40px;
  display: block;
  margin: 0 auto;
}
.survey-img1{
   width:504px;
  height:40px;
}
.cp-cont{
  font-size: 24px;
  color: #909399;
  line-height: 48px;
  padding:23px 28px 0 26px;
  text-align: center;
  span{
     color:#414b59;
    font-size:28px
  }
}
.cp-img1{
  width:480px;
  margin:20px auto;
  display: block;
  margin-bottom:50px;
}

.feature-wrap{
  background: #3E76E0;
}
.hx-box{
  padding:0 15px;
  .hx-block{
    height:158px;
    width:100%;
    img{
      margin-top:30px;
      margin-right:30px;
    }
    .img1{
      width:78px;
      height:91px;
    }
    .img2{
      width:90px;
      height:94px;
    }
    .img3{
      width:83px;
      height:86px;
    }
    .img4{
      width:84px;
      height:84px;
    }
    .img5{
      width:82px;
      height:87px;
    }
    .img6{
      width:87px;
      height:73px;
    }
    .hx-block-rig{
      flex:1;
      height:100%;
      border-bottom:1px dashed  rgba(255, 255, 255, .39);
      .hx-block-title{
        font-size: 12px;
        color: #FFFFFF;
        line-height: 15px;
        margin-top:18px;
      }
      .hx-block-txt{
          font-size: 10px;
          color: rgba(255, 255, 255, .66);
          line-height: 15px;
          margin-top:4px;
      }
    }
    .hx-block-rig6{
      border-bottom: none;;
    }
  }
}
.td-box{
  padding:0 28px;
  padding-bottom: 75px;
  .td-block{
    width:100%;
    height:197px;
    position: relative;
    margin-top:18px;
    .imgbj{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      z-index:-1;
    }
    .img{
      
    }
    .img1{
      width:96px;
      height:91px;
      margin:54px 24px 0 37px;
    }
    .img2{
      width:106px;
      height:100px;
      margin:54px 30px 0 26px;
    }
    .img3{
      width:79px;
      height:77px;
      margin:64px 41px 0 37px;
    }
    .img4{
      width:88px;
      height:84px;
      margin:55px 45px 0 30px;
    }
    .img5{
      width:85px;
      height:78px;
      margin:59px 42px 0 36px;
    }
  
    .td-rig{
      .td-title{
          height: 15px;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 15px;
          margin:33px 0 9px 0;
      }
      .td-txt{
        color: rgba(255, 255, 255, .9);
        font-size: 10px;
        line-height: 16px;
        padding-right:10px;
      }
    }
  }
}


.survey-img2{
  width:620px;
}

.yy-box{
  padding:0 28px;
  .yy-block{
    width: 386px;
    height: 339px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);
    .img{
      width:386px;
      height:249px;
    }
    .yy-title{
      font-size: 24px;
      color: #909399;
      line-height: 31px;
      text-align: center;
      margin-top:28px;
    }
  }
}
.p-top{
  position: absolute;
  top:79px;
  left:35px;
  h3{
    width: 360px;
    height: 96px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 48px;
  }
  div{
    width: 342px;
    height: 36px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, .49);
    line-height: 27px;
    margin-top:38px;
  }
}
.swiper-container{
    margin-top:0;
}
.scene-wrap-ys{
  background: rgba(246, 248, 253, 1);
}
.cpys_wrap{
  padding: 50px 28px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .cpys_card{
    width: 331px;
    height: 329px;
    text-align: center;
    padding: 38px 26px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 9px rgba(132,181,255,0.08);
    &:first-of-type{
      margin-bottom: 26px;
    }
    img{
      width: 88px;
      height: 103px;
    }
    .title{
      font-size: 24px;
      line-height: 35px;
      height: 35px;
      font-weight: 600;
      color: rgba(65, 75, 89, 1);
    }
    .text{
      width: 279px;
      height: 82px;
      font-size: 20px;
      color: #999999;
      line-height: 34px;
    }
  }
}
</style>