import Vue from 'vue'
import {
  Col,
  Row,
  Dialog,
  Popup,
  Tab,
  Tabs,
  List,
  Cell,
  CellGroup,
  Grid,
  GridItem,
  Search,
  Icon,
  Form,
  Field,
  Stepper,
  Area,
  Cascader,
  Toast
} from 'vant'

Vue.use(Dialog)
  .use(Col)
  .use(Row)
  .use(Popup)
  .use(Tab)
  .use(Tabs)
  .use(List)
  .use(Cell)
  .use(CellGroup)
  .use(Grid)
  .use(GridItem)
  .use(Search)
  .use(Icon)
  .use(Form)
  .use(Field)
  .use(Stepper)
  .use(Area)
  .use(Cascader)
  .use(Toast)
