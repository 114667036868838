<template>
  <div class="energy-wrap">
    <div class="energy-banner">
      <img src="../../assets/images/product-banner_7.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div
        class="survey-subTitle fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
        style="transition-delay: 0.2s"
      >
        能源大数据应用中心按功能划分为三个子系统平台：大数据业务平台、公共云服务平台、超算平台
      </div>
      <div class="platform-wrap">
        <div class="platform-item platform-item_1">
          <span>超算平台</span>
          <img src="../../assets/images/platform-item_1.png" alt="" />
        </div>
        <div class="platform-item platform-item_2">
          <span>公共云服务平台</span>
          <img src="../../assets/images/platform-item_2.png" alt="" />
        </div>
        <div class="platform-item platform-item_3">
          <span>大数据业务平台</span>
          <img src="../../assets/images/platform-item_3.png" alt="" />
        </div>
        <div class="platform-bg">
          <span>子系统平台</span>
          <img src="../../assets/images/platform-bg.png" alt="" />
        </div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
      <div class="survey-list">
        <div
          class="survey-list_title"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
          style="transition-delay: 0.4s"
        >
          通过把以下数据接入电力系统运行全面数据集中处理后，通过建立业务模型和采取复杂的计算服务，为电网企业、政府、发电企业和制造业提供综合服务
        </div>
        <ul>
          <li class="platform-icon">
            <img src="../../assets/images/platform-icon_1.png" alt="" />
            <span>新能源实证测试数据</span>
          </li>
          <li class="platform-icon">
            <img src="../../assets/images/platform-icon_2.png" alt="" />
            <span>负荷数据</span>
          </li>
          <li class="platform-icon">
            <img src="../../assets/images/platform-icon_3.png" alt="" />
            <span>电网运行数据</span>
          </li>
          <li class="platform-icon">
            <img src="../../assets/images/platform-icon_4.png" alt="" />
            <span>新能源电站运行数据</span>
          </li>
          <li class="platform-icon">
            <img src="../../assets/images/platform-icon_5.png" alt="" />
            <span>数值天气预报数据</span>
          </li>
          <li class="platform-icon">
            <img src="../../assets/images/platform-icon_6.png" alt="" />
            <span>资源数据</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="framework-wrap">
      <div
        class="framework-title fadeOutUp"
        ref="animate3"
        :class="{ fadeInUp: animateData.animate3 }"
        style="transition-delay: 0.4s"
      >
        系统架构图
      </div>
      <div class="framework-list">
        <div class="framework-item">
          <div class="framework-item_title">业务应用</div>
          <ul>
            <li v-for="(item, index) in architectureTop" :key="item.name">
              <h3 @click="idx = index">
                <span>{{ item.name }}</span>
                <img
                  :style="idx == index ? 'transform:rotate(180deg)' : ''"
                  src="../../assets/images/platform-arrow.png"
                  alt=""
                />
              </h3>
              <ol v-if="idx == index">
                <li v-for="it in item.list" :key="it">{{ it }}</li>
              </ol>
            </li>
          </ul>
        </div>
        <div class="framework-item">
          <div class="framework-item_title">子系统平台</div>
          <ol>
            <li v-for="it in architectureCenter" :key="it">{{ it }}</li>
          </ol>
        </div>
        <div class="framework-item">
          <div class="framework-item_title">数据输入</div>
          <ol>
            <li v-for="it in architectureBottom" :key="it">{{ it }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  data() {
    return {
      architectureTop: [
        {
          name: "数据预报",
          list: [
            "发电能力预测",
            "气象灾害预警",
            "新能源电站选址辅助决策",
            "水文预报",
          ],
        },
        {
          name: "数据监测",
          list: [
            "监测数据质量管理",
            "自动告警",
            "多样化数据监测",
            "日常值班管理",
            "明细数据计算汇总",
          ],
        },
        {
          name: "全景展示",
          list: ["主题配置管理", "展示场景管理", "数据状态估计"],
        },
        {
          name: "运营分析",
          list: [
            "分析模型管理",
            "分析业务开展",
            "分析报告管理",
            "明细数据溯源",
          ],
        },
        {
          name: "综合管理",
          list: ["电站运维管理", "发电并网规划", "数据集成管理"],
        },
      ],
      architectureCenter: ["大数据业务平台", "公共云服务平台", "超算平台"],
      architectureBottom: [
        "资源数据",
        "数值天气预报数据",
        "新能源电站运行数据",
        "电网运行数据",
        "负荷数据",
        "新能源实证测试数据",
      ],
      idx: 0,
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.survey-wrap {
  .icon_1 {
    top: 43px;
  }
  .icon_2 {
    top: 34px;
  }
}

.platform-wrap {
  position: relative;
  padding-top: 175px;
  .platform-bg {
    position: relative;
    width: 100%;
    text-align: center;
    img {
      width: 640px;
    }
    span {
      position: absolute;
      left: 50%;
      bottom: 70px;
      transform: translate(-50%, 0);
      color: #222737;
      font-size: 28px;
    }
  }
  .platform-item {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    img {
      width: 113px;
    }
    span {
      margin-bottom: 20px;
      color: #1c9fdf;
      font-size: 24px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 2px 9px 4px rgba(110, 138, 225, 0.08);
      min-width: 167px;
      padding: 7px 18px 10px;
      text-align: center;
      line-height: 31px;
      position: relative;
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 20px 20px;
        border-style: solid;
        border-color: transparent transparent #fff;
        position: absolute;
        bottom: -18px;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, 0) rotate(180deg);
      }
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 20px 20px;
        border-style: solid;
        border-color: transparent transparent #f3f6f3;
        position: absolute;
        bottom: -20px;
        left: 50%;
        filter: blur(2px);
        transform: translate(-50%, 0) rotate(180deg);
      }
    }
    &.platform-item_1 {
      bottom: 29px;
      left: 96px;
    }
    &.platform-item_2 {
      bottom: 136px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &.platform-item_3 {
      bottom: 29px;
      right: 96px;
    }
  }
}
.survey-list {
  margin: 30px 28px 0;
  box-shadow: 0px 0px 9px 6px rgba(23, 31, 48, 0.07);
  border-radius: 16px;
  padding: 30px 26px;
  text-align: center;
  .survey-list_title {
    color: #484e5e;
    font-size: 24px;
    line-height: 40px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 38px 0 1px;
    li {
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }
    img {
      width: 114px;
    }
    span {
      line-height: 30px;
      color: #11182e;
      font-size: 22px;
      white-space: nowrap;
      margin-top: 16px;
    }
  }
}

.framework-wrap {
  margin-top: 60px;
  padding: 0 28px;
  margin-bottom: 56px;
  .framework-title {
    color: #11182e;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
  }
  .framework-item {
    background: #ffffff;
    box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
    border-radius: 8px;
    overflow: hidden;
    margin-top: 28px;
    .framework-item_title {
      line-height: 58px;
      text-align: center;
      height: 58px;
      background: #424e6e;
      border-radius: 8px 0px 0px 8px;
      font-size: 28px;
      color: #fff;
    }
    ul {
      padding: 0 15px 30px 23px;
      margin: 0;
      h3 {
        color: #4f5978;
        font-weight: normal;
        font-size: 24px;
        border-bottom: 1px solid #ced0d7;
        height: 63px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 7px;
        padding-right: 13px;
        justify-content: space-between;
      }
      img {
        width: 23px;
        margin-bottom: 8px;
      }
    }
    ol {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      list-style: none;
      justify-content: space-between;
      padding-top: 13px;
      margin: 0;
      li {
        width: calc(~"50% - 12px");
        height: 40px;
        background: #eff1f8;
        border-radius: 5px;
        font-size: 20px;
        color: #11182e;
        line-height: 40px;
        text-align: center;
        margin-bottom: 9px;
      }
    }
    > ol {
      padding: 19px 15px 30px 23px;
    }
  }
}
</style>