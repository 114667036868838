<template>
    <div class="productFgDetail">
        <div class="h3">解决方案 <img class="img " :src="require('../../../assets/img/back.png')" alt="" @click="backFn"/></div>
        <div class="box">
            <img class="img " :src="jData[idx].img" alt="" />
            <img class="img2 " :src="jData[idx].img2" alt="" />
            <div class="h4">{{ jData[idx].title }}</div>
            <div class="p">{{ jData[idx].txt }}</div>

            <div class="list" v-for="(itm,index) in jData[idx].list">
                   
                <div class="h5">{{ itm.title }}  <img class="img3 " :src='require("../../../assets/img/fg1"+(index+1) +".png")' alt="" /></div>
                <div class="txt">{{ itm.txt }}</div>
            </div>
        </div>

  
    </div>
  </template>
  
  <script>
  
  export default {
    components: {  },
    data() {
      return {
        idx:0,
        jData:[
            {title:"产品售后解决方案",img:require("../../../assets/img/f40.png"),img2:require("../../../assets/img/fg3.png"),txt:"通过对出厂产品赋予唯一标识，当消费者需要售后服务时，扫描产品标识码，联系原厂提供售后统一服务。同时原厂可以通过标识收集到相关用户售后需求信息，进而改进产品生产工艺。",list:[
            {title:"行业现状",txt:"产品售后市场往往采用的是线下记录的方法，包括售后维修以及定期养护，生产厂家对产品售后情况的了解有限，无法准确收集到用户的产品使用情况，不能提供专业的原厂售后服务，导致售后服务市场流失"},
            {title:"行业痛点",txt:"产品没有统一提供售后服务的渠道，消费者无法直接联系到原厂，导致去找第三方提供售后服务，造成售后服务质量下降，影响产品使用体验"},
            {title:"服务效果",txt:"为用户提供官方售后渠道。售后数据改进生产工艺。产品养护数据为厂家提供产品长期使用数据，进而优化生产工艺"},
            // {title:"具体案例",txt:"海螺门窗售后服务系统"}, 
          ]},
          {title:"产品质量追溯解决方案",img:require("../../../assets/img/f41.png"),img2:require("../../../assets/img/fg4.png"),txt:"通过对生产的每一个产品赋予唯一的身份标识，对产品从生产到销售的每个业务流程的关键信息，在标识上进行信息追加，最终消费者可以通过扫码了解产品在各流程所产生的相关信息，实现质量追溯的效果。",list:[
            {title:"行业现状",txt:"消费者在买到产品之后，当产品出现质量问题时，往往无法了解产品生产、物流、销售流程，企业也无法发现问题产品在哪个环节出现了质量问题，导致无法定位问题出现节点以及产生原因。"},
            {title:"行业痛点",txt:"生产信息不明确。业务环节较多，各业务信息不透明。问题产品无法精准定位问题点。"},
            {title:"服务效果",txt:"产品信息透明。精准定位问题产品问题点。提升产品竞争力。"},
            // {title:"具体案例",txt:"冰山集团制冷热设备质量追溯系统"}, 
          ]},
          {title:"供应链生产协同解决方案",img:require("../../../assets/img/f42.png"),img2:require("../../../assets/img/fg5.png"),txt:"对于生产备件来说，通过对主要生产企业的ERP、MES、营销系统内部数据编码进行产业链上共享，备件提供企业可以看到具体备件需求清单，对需求量大的备件加大备货，暂无需求的备件减少备件，降低库存压力。",list:[
            {title:"行业现状",txt:"复杂设备生产制造过程中，同一厂家往往不能生产设备全部配件，需要从不同厂家采购各种配件，之后进行组装生产，整个生产过程需要多个厂家沟通协商设备配件供货时间，整体生产效率偏低。"},
            {title:"行业痛点",txt:"多个厂家共同生产同一设备过程中，涉及到配件备件、技术共享、开发资料共享等问题，不同企业数据标准各不相同，信息共享存在困难。"},
            {title:"服务效果",txt:"行业企业共享数据可以缩减库存、提高配件交付效率、提升产品交付效率、提高供应商粘性、提高供应链稳定性，并降低生产风险。"},
            // {title:"具体案例",txt:"中联重科产业链同频备件应用"}, 
          ]},
          {title:"工程废料管理解决方案",img:require("../../../assets/img/f43.png"),img2:require("../../../assets/img/fg6.png"),txt:"对于废料堆放区域利用标识解析分区进行赋码，标识信息包括废料信息，废料堆放区域信息等，利用统一的数据标准，通过废料管理平台向政府监管部门报送，同时对外发送废料信息，帮助废料需求企业进行对接。",list:[
            {title:"行业现状",txt:"建筑行业等相关工程行业在生产过程中会产生大量业务废料，废料处理往往没有统一管理，政府监管存在困难，废料共同处理存在难度。"},
            {title:"行业痛点",txt:"废料缺少统一管理渠道，政府废料监管存在困难，废料资源共享缺乏信息平台。"},
            {title:"服务效果",txt:"加速废料流通，促进废料交易市场发展。标准化的废料信息上报，加强政府废料监管。废料统一管理，减少环境污染。"},
            // {title:"具体案例",txt:"远大住工废料管理应用"}, 
          ]},
          {title:"工业大脑解决方案",img:require("../../../assets/img/f44.png"),img2:require("../../../assets/img/fg7.png"),txt:"通过标识解析技术，将行业内大中小企业数据汇聚到工业大脑服务平台，进行分析利用。包括产业链图谱，产业链经济监测，产业能耗分析，产业亩均效益分析，产业知识汇聚，产业通讯录，企业大脑，产业预测分析，产业对标分析等功能模块。",list:[
            {title:"行业现状",txt:"政府侧对于行业发展缺少专业化的监管平台。行业政策制定缺乏依据。"},
            {title:"行业痛点",txt:"行业不同企业业务标准不同，导致政府无法利用企业数据进行大规模数据分析助力企业发展，行业发展。"},
            {title:"服务效果",txt:"促进行业数字化、智能化转型。提高行业核心竞争力。加快行业创新发展。促进行业协同发展。推动行业可持续发展。"},
            // {title:"具体案例",txt:"合肥政府工业大脑项目"}, 
          ]},
          {title:"安环应用解决方案",img:require("../../../assets/img/f45.png"),img2:require("../../../assets/img/fg4.png"),txt:"针对不同的安环场景按需利用标识解析对人、物、环境等对象进行赋码，利用摄像头或者传感器对环境进行实时监测，根据监测数据，融合人员管理系统、通信等系统数据，利用人工智能等手段实现安全预警，规范作业流程，降低安全风险。",list:[
            {title:"行业现状",txt:"传统安环应用只是单独建设视频监控系统，依靠视频监控对图像进行分析来进行安全管理。"},
            {title:"行业痛点",txt:"安环应用功能越全面则需要多系统业务数据相互配合，而各个系统间数据标准各不相同，系统数据融合存在困难。"},
            {title:"服务效果",txt:"保护员工人身安全。降低生产成本和风险。提高生产效率。"},
            // {title:"具体案例",txt:"中联重科工厂内部安环应用"}, 
          ]},
        ],
      };
    },
    mounted() {
        this.idx =  this.$route.query.id*1;
    },
    methods: {
        backFn(){
            this.$router.go(-1);
        }
     
    },
   
    beforeDestroy() {
    },
  };
  </script>
  
  <style lang='less' scoped>

    .productFgDetail{
        .h3{
            position: fixed;
            left:0;
            top:0;
            width:100%;
            height: 99px;
            background: #3E76E0;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 99px;
            box-shadow: 0px 6px 10px 0px rgba(55,55,55,0.13);
            z-index: 99;
            text-align: center;
            .img{
                width:60px;
                height:60px;
                position: absolute;
                top:20px;
                left:35px;
            }
        }
        .box{
            padding-top:33px;
            position: relative;
            width: 702px;
            margin:0 auto;
            margin-bottom:60px;
            .img{
                width:116px;
            }
            .img2{
                position:absolute;
                width:90px;
                top:100px;
                right:81px;
            }
            .h4{
                height: 42px;
                font-size: 32px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #414B59;
                line-height: 42px;
                margin:6px 0 10px 0;
            }
            .p{
                width: 702px;
                font-size: 28px;
                font-family: MicrosoftYaHei;
                color: #909399;
                line-height: 46px; 
                margin-bottom:28px;
            }
            .list{
                margin-bottom:20px;
                width: 702px;
                background: #F3F8FF;
                border-radius: 8px;
                padding:24px 25px 25px 66px;
                
                .h5{
                    position: relative;
                    height: 37px;
                    font-size: 28px;
                    font-family: MicrosoftYaHei;
                    color: #414B59;
                    line-height: 37px;

                    .img3{
                        width:30px;
                        position: absolute;
                        left:-42px;
                        top:3px;
                    }
                }
                .txt{
                    font-size: 24px;
                    font-family: MicrosoftYaHei;
                    color: #767F8D;
                    line-height: 38px;
                }
            }
        }

    }





  </style>