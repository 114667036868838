<template>
  <div class="energy-wrap">
    <div class="energy-banner">
      <img src="../../assets/images/product-banner_10.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div
        class="survey-subTitle fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
        style="transition-delay: 0.2s"
      >
        中央空调节能解决方案，是通过对系统各个节点加装传感器来收集整个系统的运行数据，并凭借此类数据不断优化算法模型，进而实现对系统进行动态控制和调整，以使整个系统达到一个最佳的运行状态，从而实现20%-40%的节能效果
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
      <div class="energySaving-list">
        <dl class="energySaving-item">
          <dt>
            <img src="../../assets/images/energySaving-item_1.png" alt="" />
          </dt>
          <dd>
            <h4>整体和部分</h4>
            <span
              >我们是对空调/锅炉系统整体进行节能控制，多数公司是针对部分设备进行控制
            </span>
          </dd>
        </dl>
        <img src="../../assets/images/energySaving-arrow.png" alt="" />
        <dl class="energySaving-item">
          <dt>
            <img src="../../assets/images/energySaving-item_2.png" alt="" />
          </dt>
          <dd>
            <h4>智能控制和顺序控制</h4>
            <span
              >楼控类公司主要是以PLC/DDC为基础进行简单控制，我们是以智能算法来动态全局控制</span
            >
          </dd>
        </dl>
        <img src="../../assets/images/energySaving-arrow.png" alt="" />
        <dl class="energySaving-item">
          <dt>
            <img src="../../assets/images/energySaving-item_3.png" alt="" />
          </dt>
          <dd>
            <h4>产品和项目</h4>
            <span
              >我们是产品化部署稳定性安全性高，多数公司是以项目方式做集成，现场调试等，稳定性不高
            </span>
          </dd>
        </dl>
        <img src="../../assets/images/energySaving-arrow.png" alt="" />
        <dl class="energySaving-item">
          <dt>
            <img src="../../assets/images/energySaving-item_4.png" alt="" />
          </dt>
          <dd>
            <h4>结果</h4>
            <span>我们的节能率最高 </span>
          </dd>
        </dl>
      </div>
    </div>
    <div class="feature-wrap">
      <div
        class="feature-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        方案特性
      </div>
      <div class="feature-content">
        <div class="feature-list">
          <div>采用嵌入式硬件、软件和统一产品开发平台</div>
          <div>结合物联网、移动互联和大数据平台技术</div>
          <div>采用独创的大数据集群算法+智能寻优控制技术</div>
          <div>
            智能动态控制中央空调流量、冷量、热量、风量，实现
            人工环境设备能效提升
          </div>
        </div>
        <div class="feature-card">
          <dl
            class="feature-item"
            v-for="item in architectureLeftList"
            :key="item.title"
          >
            <dt>
              <img :src="item.img" alt="" />
            </dt>
            <dd>
              <h4>{{ item.title }}</h4>
              <ul>
                <li v-for="it in item.list" :key="it">{{ it }}</li>
              </ul>
            </dd>
          </dl>
        </div>
        <div class="feature-card">
          <dl
            class="feature-item"
            v-for="item in architectureRightList"
            :key="item.title"
          >
            <dt>
              <img :src="item.img" alt="" />
            </dt>
            <dd>
              <h4>{{ item.title }}</h4>
              <ul>
                <li v-for="it in item.list" :key="it">{{ it }}</li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "@/utils/index.js";
export default {
  data() {
    return {
      architectureLeftList: [
        {
          title: "良好的系统架构",
          width: 458,
          img: require("../../assets/images/energySaving-icon_1.png"),
          list: [
            "强弱电、节能一体化设计",
            "易扩展的网络结构",
            "标准的对外接口",
            "全网的远程能源监测",
          ],
        },
        {
          title: "先进而专业的控制算法",
          width: 381,
          img: require("../../assets/images/energySaving-icon_2.png"),
          list: [
            "两器温差最佳效率群控",
            "冷却水裕度自适应优化算法",
            "冷冻水负荷动态平衡算法",
            "风机智能控制技术",
            "锅炉进出水温差平衡算法",
          ],
        },
        {
          title: "全面的设备能耗计量",
          width: 453,
          img: require("../../assets/images/energySaving-icon_3.png"),
          list: [
            "制冷机组能耗计量",
            "水泵能耗计量",
            "风机能耗计量",
            "制冷机组冷量计量",
            "锅炉供热量计量",
            "锅炉能耗计量",
          ],
        },
      ],
      architectureRightList: [
        {
          title: "满意的综合效果",
          width: 458,
          img: require("../../assets/images/energySaving-icon_4.png"),
          list: [
            "良好的节能收益",
            "运行稳定可靠",
            "功能完善、操作简便",
            "易扩展、易维护",
          ],
        },
        {
          title: "完善的系统安全保护",
          width: 381,
          img: require("../../assets/images/energySaving-icon_5.png"),
          list: ["多级操作权限设置", "多重安全保护措施", "完善的可靠性设计"],
        },
        {
          title: "丰富的数据统计分析",
          width: 450,
          img: require("../../assets/images/energySaving-icon_6.png"),
          list: [
            "空调系统运行参数统计",
            "制冷机组及系统效率统计",
            "系统操作及故障记录统计",
          ],
        },
      ],
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang="less" scoped>
.energySaving-list {
  padding: 0 28px 0 24px;
  margin-top: 20px;
  > img {
    width: 22px;
    margin: 0 0 0 60px;
  }
  .energySaving-item {
    display: flex;
    background: #f7f8fc;
    border-radius: 12px;
    padding: 18px 28px 20px 27px;
    img {
      width: 96px;
      margin-right: 16px;
    }
    h4 {
      color: #11182e;
      font-size: 28px;
    }
    span {
      color: #878ea1;
      font-size: 24px;
      line-height: 36px;
    }
  }
}
.feature-wrap {
  margin-top: 33px;
  margin-bottom: 65px;
  padding: 0 28px;
  background: transparent;
  .feature-title {
    color: #11182e;
    font-size: 36px;
    margin-bottom: 28px;
    line-height: 47px;
  }
  .feature-content {
    background: #ffffff;
    box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
    border-radius: 8px;
  }
  .feature-list {
    padding: 23px 24px 0;
    div {
      font-size: 24px;
      color: #fff;
      background: #545f7f;
      border-radius: 6px;
      padding: 17px 23px;
      margin-bottom: 10px;
    }
  }
  .feature-card {
    border-bottom: 1px solid #ced0d7;
    padding-bottom: 35px;
    &:last-child {
      border-bottom: 0;
    }
    .feature-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px 24px 0 12px;
      margin: 0;
    }
    img {
      width: 142px;
    }
    h4 {
      color: #243159;
      font-size: 24px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      color: #243159;
      font-size: 20px;
      border-radius: 8px;
      border: 1px solid #bdc5e2;
      line-height: 46px;
      width: calc(~"50% - 5px");
      margin-right: 10px;
      margin-bottom: 8px;
      text-align: center;
      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }
}
</style>