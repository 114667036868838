<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/images/hat-banner.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-top">
        <div class="survey-item survey-item_1">
          <img src="../../assets/images/hat-item_1.png" alt="" />
          <span>智能安全帽</span>
        </div>
        <img
          class="survey-dotted survey-dotted_1"
          src="../../assets/images/icon_3.png"
          alt=""
        />
      </div>
      <div class="survey-center">
        <div class="survey-item survey-item_2">
          <img src="../../assets/images/hat-item_2.png" alt="" />
          <span>健康保障服务</span>
        </div>
        <img
          class="survey-dotted survey-dotted_2"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-icon">
          <img src="../../assets/images/hat-icon.png" alt="" />
          <span>基于HSE管理体系的作<br />业人员 安全管控 <br />解决方案</span>
        </div>

        <img
          class="survey-dotted survey-dotted_3"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-item survey-item_3">
          <img src="../../assets/images/hat-item_3.png" alt="" />
          <span>安全管控平台</span>
        </div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
    <div class="feature-wrap">
      <div
        class="feature-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="feature">
        <div
          class="feature-item feature-item_10 fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <div>
            <img src="../../assets/images/hat-icon_1.png" alt="" />
          </div>
          <span>先进的管理体系</span>
          <span class="hat_text">“人身健康、作业环境、安全管控”的多维保障</span>
        </div>
        <div
          class="feature-item feature-item_11 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          style="transition-delay: 0.2s"
        >
          <div>
            <img src="../../assets/images/hat-icon_2.png" alt="" />
          </div>
          <span>全周期的服务覆盖</span>
          <span class="hat_text">“作业前、作业中、作业后”全生命周期的精准保障</span>
        </div>
        <div
          class="feature-item feature-item_12 fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          style="transition-delay: 0.4s"
        >
          <div>
            <img src="../../assets/images/hat-icon_3.png" alt="" />
          </div>
          <span>全面化的服务组合</span>
          <span class="hat_text">“智能安全帽+科学管控+多层健康服务“的全面保障</span>
        </div>
      </div>
    </div>
    <div class="scene-wrap">
      <div
        class="scene-title fadeOutUp"
        ref="animate5"
        :class="{ fadeInUp: animateData.animate5 }"
      >
        应用场景
      </div>
      <div
        class="scene-subTitle fadeOutUp"
        ref="animate6"
        :class="{ fadeInUp: animateData.animate6 }"
        style="transition-delay: 0.2s"
      >
        作业人员安全管控
      </div>
      <scene-swiper :list="sceneList"></scene-swiper>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
    </div>
  </div>
</template>

<script>
import sceneSwiper from "@/components/scene-swiper/index.vue";
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  components: { sceneSwiper },
  data() {
    return {
      sceneList: [
        {
          img: require("@/assets/images/hat-img_1.png"),
          title: "输电线路巡检",
        },
        {
          img: require("@/assets/images/hat-img_2.png"),
          title: "地铁线路运维",
        },
        {
          img: require("@/assets/images/hat-img_3.png"),
          title: "铁路交通检修",
        },
        {
          img: require("@/assets/images/hat-img_6.png"),
          title: "风电设备检修",
        },
        {
          img: require("@/assets/images/hat-img_4.png"),
          title: "建筑工程监理",
        },
        {
          img: require("@/assets/images/hat-img_5.png"),
          title: "通信设施建设",
        },
      ],
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.survey-wrap {
  .icon_1 {
    left: 96px;
    top: 237px;
  }
  .icon_2 {
    right: 56px;
    top: 229px;
  }
}

.survey-center {
  padding-top: 13px;
}
.feature-wrap .feature-item_10 img {
  width: 84px;
}

.survey-icon {
  span {
    bottom: 1rem;
    font-size: 0.6rem;
  }
}
.feature-wrap .feature-item{
  background-size: 100% 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  .hat_text{
    padding-bottom: .5rem;
    color: rgba(255, 255, 255, .8);
    padding: 0 .2rem;
    text-align: center;
  }
}
</style>