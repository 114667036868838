<template>
  <div class="energy-wrap">
    <div class="energy-banner">
      <img src="../assets/images/about-banner.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        关于我们
      </div>
      <div
     
        class="survey-subTitle fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
        style="transition-delay: 0.2s;text-align:left"
      >
      北京小凌泰科科技有限公司（以下简称：小凌泰科）成立于2017年，是一家专注于行业软件产品研发的高科技企业。目前公司的主要业务集中在能源、通信、工业互联网等领域，并致力于围绕数据标识，构建标识解析、确权登记、数据交易、资产入表等数据资产全生命周期产品服务体系，助力企业数据资产价值释放。

      </div>
      
    
      <div
        class="survey-subTitle fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
        style="transition-delay: 0.2s;text-align:left"
      >
      小凌泰科具有成熟的软件开发能力，并通过CMMI5认证。我们在工业互联网、电力、通信等领域基于现有基础产品提供个性化的集成服务和解决方案。在国内面向中国工业互联网研究院、中航工业集团、国家电网公司等大型企事业单位，为其提供领先的软件产品、解决方案及服务，并受到客户广泛好评。
      </div>
      <div
     
     class="survey-subTitle fadeOutUp"
     ref="animate1"
     :class="{ fadeInUp: animateData.animate1 }"
     style="transition-delay: 0.2s;text-align:left"
   >
   2022年，公司持续加大工业互联网方向产品研发和业务拓展的力度，特成立苏州小凌互联科技有限公司（简称：小凌互联）。经过近一年的快速发展，小凌互联已经实现了工业互联网标识解析完整的产品体系，并在横向场景应用打造、纵向产业数据融合的方向上不断深入发展。
   </div>
      <div
        class="about-img fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
        style="transition-delay: 0.3s"
      >
        <img src="../assets/images/about-img_1.png" alt="" />
      </div>
      <!-- <div
        class="survey-subTitle fadeOutUp"
        ref="animate3"
        :class="{ fadeInUp: animateData.animate3 }"
        style="transition-delay: 0.4s"
      >
        公司成立以来，始终以智能化助力电力服务优化，提升电力使用和设备运行效率为使命，并立志成为领先的能源智能化服务供应商
      </div> -->
      <div
        class="about-img fadeOutUp"
        ref="animate4"
        :class="{ fadeInUp: animateData.animate4 }"
        style="transition-delay: 0.5s"
      >
        <img src="../assets/images/about-img_2.png" alt="" />
      </div>
      <img class="icon_1" src="../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../assets/images/icon_2.png" alt="" />
    </div>
    <div class="about-list">
      <dl
        ref="animate5"
        class="fadeOutUp"
        :class="{ fadeInUp: animateData.animate5 }"
        style="transition-delay: 0.1s"
      >
        <dt><img src="../assets/images/about-icon_1.png" alt="" /></dt>
        <dd>
          <h4>使命</h4>
          <span>推进数据要素市场化</span>
        </dd>
      </dl>
      <dl
        ref="animate6"
        class="fadeOutUp"
        :class="{ fadeInUp: animateData.animate6 }"
        style="transition-delay: 0.2s"
      >
        <dt><img src="../assets/images/about-icon_2.png" alt="" /></dt>
        <dd>
          <h4>愿景</h4>
          <span>成为领先的数据资产服务提供商</span>
        </dd>
      </dl>
      <dl
        ref="animate6"
        class="fadeOutUp"
        :class="{ fadeInUp: animateData.animate6 }"
        style="transition-delay: 0.3s"
      >
        <dt><img src="../assets/images/about-icon_3.png" alt="" /></dt>
        <dd>
          <h4>战略</h4>
          <span>打造完整的数据资产产品服务体系 </span>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import { isElementNotInViewport } from "@/utils/index.js";
export default {
  data() {
    return {
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>
<style scoped lang='less'>
.about-img {
  width: 100%;
  padding: 0 30px;
  margin: 25px 0 16px;
  img {
    width: 100%;
  }
}
.about-list {
  padding: 0 26px 20px;
  dl {
    padding: 24px;
    background: #f7f8fc;
    border-radius: 12px;
    display: flex;
    margin-bottom: 20px;
  }
  dt {
    margin-right: 22px;
    img {
      width: 92px;
      display: block;
    }
  }
  dd {
    h4 {
      color: #243159;
      font-size: 28px;
    }
    span {
      color: #63697b;
      font-size: 24px;
    }
  }
}
</style>
