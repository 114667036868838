<template>
  <div class="header-wrap">
    <div class="logo-wrap l-start">
      <img
        src="../../assets/img/head_4.png"
        class="logo"
        alt=""
        @click="navHandle({ url: '/', title: '小凌泰科' })"
      />
       <!-- <div class="logo-line"></div>
        <img
        src="../../assets/img/head_3.png"
        class="log-txt"
      /> -->
      <img
        src="../../assets/img/head_2.png"
        class="menu"
        alt=""
        @click="navVisibile = !navVisibile"
      />
      
    </div>
    <div class="nav-wrap" v-show="navVisibile">
      <div class="nav" @click="navHandle({ url: '/', title: '小凌泰科' })">
        <div class="nav-name">首页</div>
      </div>
      <div
        class="nav"
        :class="{ active: subnavVisibile }"
        @click="subnavVisibile = !subnavVisibile"
      >
        <div class="nav-name">
          <span>产品服务</span>
          <img
            :style="subnavVisibile ? 'transform:rotate(180deg)' : ''"
            src="../../assets/images/nav-icon_1.png"
            alt=""
          />
        </div>
        <div class="subnav-wrap">
          <!-- <div
            class="subnav"
            @click.stop="
              subnavIndex === index ? (subnavIndex = '') : (subnavIndex = index)
            "
            :class="{ active: index === subnavIndex }"
            v-for="(item, index) in navList"
            :key="item.title"
          > -->
            <!-- <div class="subnav-title">
              <span>{{ item.title }}</span>
              <img
                :style="index === subnavIndex ? 'transform:rotate(180deg)' : ''"
                src="../../assets/images/nav-icon_2.png"
                alt=""
              />
            </div> -->
            <div
              class="subnav-item l-between"
              v-for="itm in navList"
              :key="itm.title"
              :class="{ active: routePath.includes(itm.url) }"
              @click.stop="navHandle(itm)"
            >
              <span>{{ itm.title }}</span>
              <img src="../../assets/images/nav-icon_3.png" alt="" />
            </div>
          <!-- </div> -->
        </div>
      </div>
      <div
        class="nav"
        @click="navHandle({ url: '/news', title: '新闻动态' })"
        v-if="isShowNews"
      >
        <div class="nav-name">新闻动态</div>
      </div>
      <div
        class="nav"
        @click="navHandle({ url: '/qualification', title: '公司资质' })"
      >
        <div class="nav-name">公司资质</div>
      </div>
      <div class="nav">
        <div
          class="nav-name"
          @click="navHandle({ url: '/About', title: '关于我们' })"
        >
          关于我们
        </div>
      </div>
      <div class="nav">
        <div
          class="nav-name"
          @click="navHandle({ url: '/recruit', title: '加入我们' })"
        >
          加入我们
        </div>
      </div>
    </div>
    <div class="head_foot" v-show="navVisibile">
      <div class="foot-content-wrap">
        <div class="foot-content">
          <div>公司全称：北京小凌泰科科技有限公司</div>
          <div>商务邮箱：it@xiaoling-tech.com</div>
          <div>联系地址：北京市昌平区龙域北街6号</div>
          <div>
            <img
              src="../../assets/images/foot-icon_2.png"
              alt=""
            />京ICP备2020038370号-1
          </div>
        </div>
        <div class="logo">
          <img src="../../assets/img/head_5.png" alt="" />
        </div>
      </div>
      <div class="foot-bottom">Copyright@2016～2021</div>
    </div>
    <!-- <img
      class="pushHome-a"
      src="@/assets/img/451665547402_.pic.jpg"
      @click="show=true"
      style="display:block;position:fixed;width:84px;height:84px;z-index:3;"
    /> -->

    <van-popup v-model="show">
      <img src="@/assets/img/baomingtongdao.png" alt="" />
      <van-icon name="cross" @click="show=false" />
    </van-popup>
  </div>
  
</template>

<script>
import { selectReceptionTitle } from "@/api/common";
import navList from "@/utils/nav.js";
export default {
  name: "Header",
  data() {
    return {
      show:false,
      navList,
      routePath: "",
      navVisibile: false,
      subnavVisibile: false,
      subnavIndex: "",
      isShowNews:false
    };
  },
  watch: {
    $route(v) {
      this.routePath = v.path;
    },
  },
  mounted() {
    this.routePath = this.$route.path;
    this.getNewsList()
  },
  methods: {
    async getNewsList(){
      let params1 = {
        pageNo:1,
        pageSize:10,
        titleType:6
      }
      let params2 = {
        pageNo:1,
        pageSize:10,
        titleType:7
      }
      try {
        let res1 = await selectReceptionTitle(params1)
        let res2 = await selectReceptionTitle(params2)
        if((res1.code === 0 && res2.code === 0)  && (res1.data.records.length || res2.data.records.length)){
          this.isShowNews = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    navHandle(item) {
      document.title = item.title;
      this.$router.push({
        path: item.url,
      });
      this.navVisibile = false;
    },
  },
};
</script>

<style lang="less" scoped>
.header-wrap {
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  // height: 100%;
  z-index: 10;
}
.logo-wrap {
  // display: flex;
  // justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 28px 0 35px;
  position: relative;
  z-index: 10;
  .logo {
    width: 470px;
  }
  .menu {
    width: 42px;
    position: absolute;
    right:29px;
    top:31px
  }
  .logo-line {
    width: 1px;
    height: 46px;
     background: rgba(31, 27, 83, .22);
    margin: 0 22px;
  }
  .log-txt{
    width:156px;
    height:19px;
  }
  
}
.nav-wrap {
  position: absolute;
  top: 98px;
  left: 0;
  background: #080f20;
  width: 100%;
  color: #e1e1e1;
  z-index: 11;
  .nav {
    border-top: 0.5px solid rgba(255, 255, 255, 0.2);
    padding: 0 30px;
    &.active {
      margin-bottom: 3px;
      .subnav-wrap {
        display: block;
      }
    }
  }
  .nav-name {
    line-height: 88px;
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 30px;
    }
  }
}
.subnav-wrap {
  display: none;
  padding-bottom:20px;
  .subnav {
    background: #101d3f;
    &.active {
      padding-bottom: 24px;
      .subnav-item {
        display: flex;
      }
      .subnav-title {
        margin-bottom: 24px;
      }
    }
  }
  .subnav-title {
    padding: 0 27px 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    color: #e1e1e1;
    line-height: 64px;
    border-bottom: 2px solid #080f20;

    img {
      width: 22px;
    }
  }
  .subnav-item {
    // display: none;
    // display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    padding: 0 22px 0 25px;
    width:691px;
    background: rgba(2, 142, 212, .45);
    border-radius: 8px;
    color: rgba(255, 255, 255, .9);
    font-size: 20px;
    // margin-bottom: 10px;
    margin: 0 27px 10px 0px;

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 26px;
    }
    &.active {
      color: #4478e7;
    }
  }
}
.head_foot {
  position: fixed;
  border-top: 1px solid #474952;
  bottom: 0;
  top:100px;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: #080f20;
}
.foot-content-wrap {
  height: 195px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 26px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.72);
  line-height: 24px;
  border-bottom: 1px solid #474952;
  position: absolute;
  bottom: 80px;
  width: 100%;
  .foot-content {
    padding-top: 15px;
    div {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
    }
    img {
      width: 20px;
      margin-right: 6px;
    }
  }
  .logo {
    width: 214px;
    img {
      width: 214px;
    }
  }
}
.foot-bottom {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.72);
  text-align: center;
  padding: 24px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.pushHome-a{
  bottom: 200px;
  right: 0;
  position: fixed;
}
.van-popup {
    z-index: 9999;
    border-radius: 10px;
    img {
      width: 512px;
    }
    .van-icon {
      color: transparent;
      position: absolute;
      top: 13px;
      right: 9px;
      font-size: 50px;
    }
  }
</style>
