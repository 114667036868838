import { render, staticRenderFns } from "./powerGrid.vue?vue&type=template&id=27a77c02&scoped=true&"
import script from "./powerGrid.vue?vue&type=script&lang=js&"
export * from "./powerGrid.vue?vue&type=script&lang=js&"
import style0 from "./powerGrid.vue?vue&type=style&index=0&id=27a77c02&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a77c02",
  null
  
)

export default component.exports