<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/images/product-banner_2.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-top">
        <div class="survey-item survey-item_1">
          <img src="../../assets/images/survey-item_3.png" alt="" />
          <span>边缘计算设备</span>
        </div>
        <img
          class="survey-dotted survey-dotted_1"
          src="../../assets/images/icon_3.png"
          alt=""
        />
      </div>
      <div class="survey-center">
        <div class="survey-item survey-item_2">
          <img src="../../assets/images/survey-item_4.png" alt="" />
          <span>可视化监拍设备</span>
        </div>
        <img
          class="survey-dotted survey-dotted_2"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-icon">
          <img src="../../assets/images/survey-icon_2.png" alt="" />
          <span
            >"云、边、端"<br />
            一体化解决方案</span
          >
        </div>

        <img
          class="survey-dotted survey-dotted_3"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-item survey-item_3">
          <img src="../../assets/images/survey-item_5.png" alt="" />
          <span>可视化监拍管理平台</span>
        </div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
    <div class="feature-wrap">
      <div
        class="feature-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="feature">
        <div
          class="feature-item feature-item_4 fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <div>
            <img src="../../assets/images/feature-icon_4.png" alt="" />
          </div>
          <span>覆盖广</span>
        </div>
        <div
          class="feature-item feature-item_5 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          style="transition-delay: 0.2s"
        >
          <div>
            <img src="../../assets/images/feature-icon_5.png" alt="" />
          </div>
          <span>效率高</span>
        </div>
        <div
          class="feature-item feature-item_6 fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          style="transition-delay: 0.4s"
        >
          <div>
            <img src="../../assets/images/feature-icon_6.png" alt="" />
          </div>
          <span>效果优</span>
        </div>
      </div>
    </div>
    <div class="scene-wrap">
      <div
        class="scene-title fadeOutUp"
        ref="animate5"
        :class="{ fadeInUp: animateData.animate5 }"
      >
        应用场景
      </div>
      <scene-swiper :list="sceneList"></scene-swiper>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
    </div>
  </div>
</template>

<script>
import sceneSwiper from "@/components/scene-swiper/index.vue";
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  components: { sceneSwiper },
  data() {
    return {
      sceneList: [
        {
          img: require("@/assets/images/scene-img_1.png"),
          title: "无信号区可视化监拍",
        },
        {
          img: require("@/assets/images/scene-img_2.png"),
          title: "有信号区可视化监拍",
        },
      ],
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.survey-wrap {
  .icon_1 {
    left: 96px;
    top: 237px;
  }
  .icon_2 {
    right: 56px;
    top: 229px;
  }
}

.survey-center {
  padding-top: 13px;
}
.feature-wrap .feature-item_4 img {
  width: 84px;
}
</style>