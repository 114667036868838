<template>
  <div class="SignUp">
    <div class="background">
      <div class="img-title">
        <img src="@/assets/img/SignUp/1871665975304.png" class="title-left" />
      </div>
      <div class="meeting-time">
        <div class="time">
          <div class="time-title">一、会议时间:</div>
          <div class="time-body">
            <ul>
              <li>2023年12月9日全天论坛报到;</li>
              <li>2023年12月9日20:00-22:00论坛闭门会议“社会办医十人谈”;</li>
              <li>
                2023年12月10日8:30-12:00开幕式及主论坛;
              </li>
              <li>
                2023年12月10日13:30-18:00社会办医专题平行论坛;
              </li>
              <li>
                2023年12月11日8:30-17:30科室效率管理培训班、医疗机构集团化运营管理专业能力培训班
              </li>
            </ul>
          </div>
          <div class="time-title top">二、会议地点:</div>
          <div class="time-body">
            北京友谊宾馆(北京市海淀区中关村南大街1号)
          </div>
        </div>
        <div class="time">
          <div
            class="question-loop"
            v-for="(item, index) in getQuestionListArr"
            :key="index"
          >
            <!-- 输入框选择 -->
            <div v-if="item.questionTpey === 'text'" class="question-text-loop">
              <van-field
                v-model="item.answers"
                :label="item.questionTitle"
                :placeholder="item.description"
                required
                input-align="right"
                class="question-name"
              />
            </div>
            <!-- 单选 -->
            <div
              class="question-radio-loop"
              v-if="item.questionTpey === 'select'"
            >
              <van-field
                name="radio"
                :label="item.questionTitle"
                class="question-name"
                required
              >
                <template #input>
                  <van-radio-group
                    v-model="item.answers"
                    direction="horizontal"
                  >
                    <van-radio
                      checked-color="#74b9ff"
                      :name="radioItem.sort"
                      v-for="radioItem in item.options"
                      :key="radioItem.optionId"
                    >
                      {{ radioItem.optionTitle }}
                    </van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </div>
            <!-- 地址 -->
            <div
              v-if="item.questionTpey === 'dropDownBox'"
              class="question-text-loop"
            >
              <van-field
                required
                class="question-name"
                v-model="fieldValue"
                is-link
                readonly
                :label="item.questionTitle"
                :placeholder="item.description"
                @click="isShow = true"
              />
              <van-popup v-model="isShow" round position="bottom">
                <van-cascader
                  active-color="#4f7dec"
                  v-model="cascaderValue"
                  title="请选择所在地区"
                  :options="options"
                  @close="isShow = false"
                  @finish="onFinish"
                />
              </van-popup>
            </div>
            <!-- 文本域选择 -->
            <div
              v-if="item.questionTpey === 'textarea'"
              class="question-text-loop"
            >
              <van-cell-group class="question-name" :border="false">
                <van-cell
                  :title="item.questionTitle"
                  :border="false"
                  required
                />
                <van-field
                  v-model="item.answers"
                  autosize
                  rows="1"
                  type="textarea"
                  :placeholder="item.description"
                />
              </van-cell-group>
            </div>
            <!-- 多选 -->
            <div
              v-if="item.questionTpey === 'checkbox'"
              class="question-text-loop"
            >
              <van-cell-group class="question-name" :border="false">
                <van-cell
                  :title="item.questionTitle"
                  :border="false"
                  required
                />
                <van-checkbox-group
                  v-model="item.answers"
                  v-for="checkoutItem in item.options"
                  :key="checkoutItem.optionId"
                >
                  <van-checkbox
                    :name="checkoutItem.optionId"
                    checked-color="#74b9ff"
                  >
                    {{ checkoutItem.optionTitle }}
                  </van-checkbox>
                </van-checkbox-group>
              </van-cell-group>
            </div>
            <!-- 付费健康中国 -->
            <div
              v-if="item.questionTpey === 'readOnly'"
              class="question-text-loop"
            >
              <van-cell-group class="question-name" :border="false">
                <van-cell
                  :title="item.questionTitle"
                  :border="false"
                  required
                />
                <div class="layout">
                  <div
                    class="left card"
                    v-for="cardLayout in item.options"
                    :key="cardLayout.optionId"
                  >
                    <div class="layout-top">
                      <div class="title">
                        {{ cardLayout.optionTitle }}
                      </div>
                      <div class="describe">
                        {{ cardLayout.description }}
                      </div>
                    </div>
                    <div class="layout-bottom">
                      <div class="money">
                        {{ cardLayout.remark }}
                      </div>
                      <div class="count">
                        <button
                          type="button"
                          class="btn btn-light btn-left"
                          @click="sub(cardLayout.optionId)"
                        >
                          -
                        </button>
                        <input
                          disabled
                          type="number"
                          class="form-control inp"
                          v-model="cardLayout.count"
                          @change="changeCountFn"
                        />
                        <button
                          type="button"
                          :disabled="disabled"
                          class="btn btn-light btn-right"
                          ref="buttonAdd"
                          @click="add(cardLayout.optionId)"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </van-cell-group>
            </div>
          </div>
        </div>
        <div class="vanButton">
          <van-button block @click="onSubmit">
            提交
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import QRCode from 'qrcode'
import { options } from './components/option'
import { reqSignUpData, reqSaveData, reqPayMoney } from '@/api/common.js'
import { Toast } from 'vant';
export default {
  name: 'signup',
  data() {
    return {
      // 省市区
      options,
      // 是否展示
      isShow: false,
      fieldValue: '',
      cascaderValue: '',

      //单选
      radio: '1',

      // 得到请求返回的数据，去渲染
      getQuestionList: [],
      // 处理好添加之后的数组
      getQuestionListArr: [],

      // 提交数据
      answersList: [],

      // 按钮禁用
      disabled: false,

      // 付费选项卡的ID
      pay: '',
      erWeiMaImg: ''
    }
  },
  created() {
    this.getSignUpDataList()
    this.mobilePhoneType()
  },
  methods: {
    // 接口数据
    async getSignUpDataList() {
      const res = await reqSignUpData()
      this.getQuestionList = res.data
      // console.log('🌈成功->信息', this.getQuestionList)

      console.log('-------------------------------------')

      // 添加字段
      // 把少数据的数组过滤掉
      const filterReadOnly = this.getQuestionList.filter(
        item => item.questionTpey !== 'readOnly'
      )
      // console.log('🌈成功->信息', filterReadOnly)

      // 把少数据的数组过滤出来
      const deleteReadOnly = this.getQuestionList.filter(
        item => item.questionTpey === 'readOnly'
      )
      // console.log('🌈成功->信息', deleteReadOnly)
      // console.log('🌈成功->信息', deleteReadOnly[0].options)

      // 给缺少字段的数据添加字段
      deleteReadOnly[0].options.map(item => {
        // 使用数据劫持添加字段
        this.$set(item, 'count', 0)
      })
      // 合并数组
      const handleAddData = [...filterReadOnly, ...deleteReadOnly]
      this.getQuestionListArr = handleAddData
      console.log('🌈成功->信息', handleAddData)
    },

    changeCountFn(e) {
      let num = parseInt(e.target.value || 1)
      // 如果小于等于0
      if (num <= 0) {
        num = 1
      }
      e.target.value = num
      // console.log('触发输入框的数量', num)
    },
    // 加
    add(id) {
      // 修改count即可
      this.getQuestionListArr.filter(item => {
        if (item.questionTpey === 'readOnly') {
          const arr = item.options.find(every => {
            if (every.optionId === id) {
              every.count = every.count + 1
              if (every.count > 0) {
                // 处理禁用
                this.disabled = true
                this.$toast('最多只能选一份')
              }
            }
          })
        }
      })
    },
    // 减
    sub(id) {
      // 修改count即可
      this.getQuestionListArr.filter(item => {
        if (item.questionTpey === 'readOnly') {
          const arr = item.options.find(every => {
            if (every.optionId === id) {
              if (every.count <= 0) return
              every.count = every.count - 1
              // 处理禁用
              if (every.count < 1) {
                this.disabled = false
              }
            }
          })
        }
      })
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.isShow = false
      this.fieldValue = selectedOptions.map(option => option.text).join('/')
    },
    // 提交表单
    async onSubmit() {
      const res = JSON.parse(JSON.stringify(this.getQuestionListArr))
      console.log('🌈成功->信息', res)
      const joinData = res.map((item, index) => {
        let arr = {}
        if (item.questionTpey === 'text') {
          arr.questionId = item.questionId
          arr.answers = [item.answers]
        }
        if (item.questionTpey === 'textarea') {
          arr.questionId = item.questionId
          arr.answers = [item.answers]
        }
        if (item.questionTpey === 'select') {
          arr.questionId = item.questionId
          arr.answers = [item.answers]
        }
        if (item.questionTpey === 'checkbox') {
          arr.questionId = item.questionId
          arr.answers = item.answers
        }
        if (item.questionTpey === 'dropDownBox') {
          arr.questionId = item.questionId
          arr.answers = [this.fieldValue]
        }
        if (item.questionTpey === 'readOnly') {
          // 传入的是数量
          /*    const res = item.options.map(every => {
            return every.count
          })
          arr.questionId = item.questionId
          arr.answers = res */

          // 传入ID
          const res = item.options.map(every => {
            // return every.optionId
            if (every.count > 0) {
              return every.optionId
            }
          })
          arr.questionId = item.questionId
          // 付费金额
          this.pay = item.questionId
          // 过滤掉null
          const filterNull = res.filter(item => item)
          arr.answers = filterNull

          const count = item.options.map(every => {
            return every.count
          })
          const countFilter = count.filter(itemCount => itemCount !== 0)
          // 数组里面的【单个】数字转 类型为数字类型   [1] => 1
          arr.amount = +countFilter
        }
        return arr
      })

      console.log('🌈成功->答案的集合', joinData)
      // 处理未填
      const ars = joinData.every((item, index) => {
        if (item.answers instanceof Array) {
          if (
            item.answers.length === 1 &&
            (item.answers[0] === undefined || item.answers[0] === '')
          ) {
            return false
          }
        } else {
          if (item.answers === undefined) return false
        }
        return true
      })
      console.log('🌈成功->判断是否有空值', ars)
      // let pushData = { WechatPayInfo: { questionIds: [this.pay] } }
      // joinData.push(pushData)

      // 得到手机类型
      const iponeType = this.mobilePhoneType()
      console.log('🌈成功->iponeType', iponeType)

      if (ars) {
        let save = {}
        save.answersList = joinData
        save.WechatPayInfo = { questionIds: [this.pay] }
        save.scenario = iponeType
        console.log('🌈成功->接口传入的数据', save)
        // 发请求,返回订单号
        const res = await reqSaveData(save)
        // console.log('🌈成功->Base64Url', res)
        // 订单号
        if(!res.data || !res.data.codeUrl){
          Toast(res.message)
          return 
        }
        const orderNum = res.data.orderNo
        // 重点-- 使用链接生成二维码，提交成功之后二维码才会显示
        // const Base64Url = await QRCode.toDataURL(res.data.codeUrl)
        // this.erWeiMaImg = Base64Url
        // 重点-- 跳转链接
        window.location.href = res.data.codeUrl
        // 重点-- 支付请求
        const resReqPayMoney = await reqPayMoney(orderNum)
        console.log('🌈成功->支付订单号返回的结果', resReqPayMoney)

        this.$toast('提交成功')
        // 判断是否在微信
        const isEnvWeixin = navigator.userAgent
          .toLowerCase()
          .includes('micromessenger')
        console.log('🌈成功->判断是否在微信', isEnvWeixin)
      } else {
        this.$toast('请完善信息')
      }
    },

    // 判断设备是否是IOS
    mobilePhoneType() {
      const model = navigator.userAgent
      const isiOS = !!model.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isiOS) {
        return 'iOS'
      } else {
        return 'Andriod'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.SignUp {
  box-sizing: border-box;
  ::v-deep .van-cell {
    &::after {
      border-bottom: 0;
    }
  }

  .payMoney {
    width: 200px;
    height: 200px;
  }

  .background {
    background: url('~@/assets/img/SignUp/1831665970338.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .img-title {
      .title-left {
        width: 200px;
        height: 100%;
        margin: 59px 100px 0 74px;
      }
    }

    .meeting-time {
      padding: 20px 18px 0 18px;

      .time {
        margin-top: 11px;
        padding: 10px 18px 30px 18px;
        background: rgba(0, 34, 161, 0.8);
        border: 1px solid #3f7ff4;
        border-radius: 5px;

        .time-title {
          font-size: 14px;
          color: #fff;
          font-weight: 900;
        }

        .time-body {
          margin-top: 5px;
          color: #fff;

          ul {
            li {
              padding: 5px 0;
              font-size: 12px;
            }
          }
        }

        .top {
          margin-top: 30px;
        }

        ::v-deep .van-field__label {
          color: #fff;
          font-size: 14px;
        }
        // text
        ::v-deep input {
          &::-webkit-input-placeholder {
            /* WebKit browsers，webkit内核浏览器 */
            color: #9eade5;
            font-size: 14px;
          }
        }
        ::v-deep input:disabled {
          &::-webkit-input-placeholder {
            /* WebKit browsers，webkit内核浏览器 */
            color: #9eade5;
            font-size: 14px;
          }
        }

        // textarea
        ::v-deep textarea {
          &::-webkit-input-placeholder {
            color: #9eade5;
            font-size: 13px;
          }
        }
        ::v-deep .van-field__control {
          color: #fff;
          height: 24px;
        }

        .question-loop {
          .question-name {
            padding: 8px 12px;
            background-color: #3f7ff4;
            margin-top: 10px;
            border-radius: 5px;

            ::v-deep .van-field__control {
              text-align: right;
            }

            ::v-deep .van-icon-arrow {
              &::before {
                color: #9eade5;
              }
            }

            .layout {
              margin-top: 12px;
              display: flex;
              justify-content: space-between;
              // 两个小卡片
              .card {
                width: 100%;
                // height: 154px;
                background-color: #0a3fc3;
                border-radius: 5px;

                .layout-top {
                  padding: 0 12px;
                  margin-top: 12px;

                  .title {
                    font-size: 14px;
                    color: #fff;
                  }

                  .describe {
                    margin-top: 10px;
                    font-size: 12px;
                    color: #bbc8fe;
                  }
                }

                .layout-bottom {
                  display: flex;
                  justify-content: space-between;

                  padding: 0 12px;
                  margin-top: 17px;
                  border-top: 1px solid #3556cb;
                  .money {
                    margin-top: 12px;
                    color: #ff683a;
                    font-size: 18px;
                  }

                  .count {
                    margin-top: 8px;
                    margin-bottom: 17px;
                    display: flex;
                    // 没有空隙
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-content: flex-start; /*子元素之间取消空白间隙，并把项目放在容器顶部。*/

                    .inp {
                      width: 43px;
                      text-align: center;
                      margin: 0px;
                      color: #fff;
                      background-color: transparent;
                    }

                    .btn {
                      width: 33px;
                      height: 28px;
                      border: 1px solid #668af1;
                      color: #fff;
                      background-color: transparent;
                    }
                    .btn-left {
                      border-radius: 5px 0 0 5px;
                    }

                    .btn-right {
                      border-radius: 0px 5px 5px 0px;
                    }

                    .form-control {
                      border-radius: 0;
                      border: 1px solid #668af1;
                      border-left: 0;
                      border-right: 0;
                    }
                  }
                }
              }
            }

            // 定位 必选
            .van-cell--required {
              &::before {
                content: '*';
                left: -5px;
              }
            }
            // 发票
            .van-cell {
              padding: 0;
              color: #fff;
              font-size: 14px;
              background-color: #3f7ff4;
            }

            // 文本域 标题
            .question-textarea {
              color: #fff;
              font-size: 14px;
            }
            // 文本域
            ::v-deep .van-field {
              margin-top: 11px;
              border-radius: 5px;
              background-color: #1c3ebc;
              padding: 8px 12px;
              ::v-deep .van-field__control {
                height: 40px;
                line-height: 20px;
              }
            }
            // 多选标题
            .question-checkbox-loop {
              color: #fff;
            }
            ::v-deep .van-checkbox-group {
              margin: 5px 0;
              margin-top: 11px;
              border-radius: 5px;
              background-color: #1c3ebc;
            }
            ::v-deep .van-checkbox__label {
              color: #fff;
              padding: auto 0;
            }
            ::v-deep .van-checkbox {
              padding: 8px 15px;
            }
            // 单选框
            ::v-deep .van-field__control--custom {
              justify-content: end;
            }

            // 单选框绑定的值
            .van-radio-group {
              ::v-deep .van-radio--horizontal {
                margin-right: 0;
                margin-left: 10px;
                .van-radio__icon {
                  font-size: 12px;
                }
                .van-radio__label {
                  color: #fff;
                }
                .van-icon-success {
                  &::before {
                    position: absolute;
                    left: -1px;
                    top: -2px;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .vanButton {
      margin-top: 50px;
      padding-bottom: 93px;
      .van-button {
        border-radius: 5px;
        background-color: #5bd0ec;
        font-size: 15px;
        color: #fff;
        border: 0;
      }
    }
  }
}
</style>
