<template>
  <!-- Swiper -->
  <div class="swiper-container scene-swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in list" :key="item.title">
        <img :src="item.img" alt="" />
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  props: ["list"],
  data() {
    return {};
  },
  methods: {
    swiperInit() {
      new Swiper(".swiper-container", {
        slidesPerView: "auto",
        spaceBetween: 10,
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.swiperInit();
    });
  },
};
</script>

<style lang='less' scoped>
//场景轮播图
.scene-swiper {
  padding: 42px 20px 64px 20px;

  ::v-deep .swiper-wrapper {
    .swiper-slide {
      width: 572px;
      position: relative;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      img {
        width: 100%;
        display: block;
      }
      .title{
        height: 91px;
        line-height: 91px;
        text-align: center;
        color: rgba(144, 147, 153, 1);
      }
      // span {
      //   position: absolute;
      //   width: 100%;
      //   left: 0;
      //   bottom: 2px;
      //   height: 80px;
      //   line-height: 80px;
      //   background-color: rgba(6, 6, 6, 0.7);
      //   font-size: 30px;
      //   padding-left: 20px;
      //   color: #fff;
      //   box-sizing: border-box;
      // }
    }
  }
}
</style>