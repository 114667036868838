<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/images/product-banner_6.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-center">
        <div class="survey-item survey-item_2">
          <img src="../../assets/images/survey-item_16.png" alt="" />
          <span>无人机</span>
        </div>
        <img
          class="survey-dotted survey-dotted_2"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-icon">
          <img src="../../assets/images/survey-icon_6.png" alt="" />
          <span
            >无人机缺陷自动
            <br />
            识别解决方案</span
          >
        </div>

        <img
          class="survey-dotted survey-dotted_3"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-item survey-item_3">
          <img src="../../assets/images/survey-item_17.png" alt="" />
          <span>AI图像分析平台</span>
        </div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
    <div class="feature-wrap">
      <div
        class="feature-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="feature">
        <div
          class="feature-item feature-item_16 fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <div>
            <img src="../../assets/images/feature-icon_16.png" alt="" />
          </div>
          <span>覆盖范围广 </span>
        </div>
        <div
          class="feature-item feature-item_17 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          style="transition-delay: 0.2s"
        >
          <div>
            <img src="../../assets/images/feature-icon_17.png" alt="" />
          </div>
          <span>作业效率高 </span>
        </div>
        <div
          class="feature-item feature-item_18 fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          style="transition-delay: 0.4s"
        >
          <div>
            <img src="../../assets/images/feature-icon_18.png" alt="" />
          </div>
          <span>缺陷识别快 </span>
        </div>
      </div>
    </div>
    <div class="scene-wrap">
      <div
        class="scene-title fadeOutUp"
        ref="animate5"
        :class="{ fadeInUp: animateData.animate5 }"
      >
        应用场景
      </div>
      <div
        class="scene-subTitle fadeOutUp"
        ref="animate6"
        :class="{ fadeInUp: animateData.animate6 }"
        style="transition-delay: 0.2s"
      >
        数字孪生智慧变电站
      </div>
      <scene-swiper :list="sceneList"></scene-swiper>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
    </div>
  </div>
</template>

<script>
import sceneSwiper from "@/components/scene-swiper/index.vue";
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  components: { sceneSwiper },
  data() {
    return {
      sceneList: [
        {
          img: require("@/assets/images/scene-img_14.png"),
          title: "输电线路无人机巡检",
        },
        {
          img: require("@/assets/images/scene-img_15.png"),
          title: "输变电设备红外图像识别",
        },
        {
          img: require("@/assets/images/scene-img_16.png"),
          title: "输变电设备缺陷自动识别",
        },
      ],
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.survey-item.survey-item_2 span {
  left: 10px;
  &::before,
  &::after {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.feature-wrap .feature-item_16 img {
  width: 84px;
}
</style>