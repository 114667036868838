<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/images/product-banner_4.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-top">
        <div class="survey-item survey-item_1">
          <img src="../../assets/images/survey-item_9.png" alt="" />
          <span>智慧全景监测</span>
        </div>
        <img
          class="survey-dotted survey-dotted_1"
          src="../../assets/images/icon_3.png"
          alt=""
        />
      </div>
      <div class="survey-center">
        <div class="survey-item survey-item_2">
          <img src="../../assets/images/survey-item_10.png" alt="" />
          <span>物联网感知</span>
        </div>
        <img
          class="survey-dotted survey-dotted_2"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-icon">
          <img src="../../assets/images/survey-icon_4.png" alt="" />
          <span
            >"监测、分析、诊断"
            <br />
            一站式解决方案</span
          >
        </div>

        <img
          class="survey-dotted survey-dotted_3"
          src="../../assets/images/icon_3.png"
          alt=""
        />
        <div class="survey-item survey-item_3">
          <img src="../../assets/images/survey-item_11.png" alt="" />
          <span>智能分析决策</span>
        </div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
    <div class="feature-wrap">
      <div
        class="feature-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="feature">
        <div
          class="feature-item feature-item_10 fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <div>
            <img src="../../assets/images/feature-icon_10.png" alt="" />
          </div>
          <span>智能化终端融合 </span>
        </div>
        <div
          class="feature-item feature-item_11 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          style="transition-delay: 0.2s"
        >
          <div>
            <img src="../../assets/images/feature-icon_11.png" alt="" />
          </div>
          <span>精细化全景展示 </span>
        </div>
        <div
          class="feature-item feature-item_12 fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          style="transition-delay: 0.4s"
        >
          <div>
            <img src="../../assets/images/feature-icon_12.png" alt="" />
          </div>
          <span>模块化特色微应用 </span>
        </div>
      </div>
    </div>
    <div class="scene-wrap">
      <div
        class="scene-title fadeOutUp"
        ref="animate5"
        :class="{ fadeInUp: animateData.animate5 }"
      >
        应用场景
      </div>
      <div
        class="scene-subTitle fadeOutUp"
        ref="animate6"
        :class="{ fadeInUp: animateData.animate6 }"
        style="transition-delay: 0.2s"
      >
        工业资源业务中台
      </div>
      <scene-swiper :list="sceneList"></scene-swiper>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
    </div>
  </div>
</template>

<script>
import sceneSwiper from "@/components/scene-swiper/index.vue";
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  components: { sceneSwiper },
  data() {
    return {
      sceneList: [
        {
          img: require("@/assets/images/scene-img_6.png"),
          title: "开关柜分析",
        },
        {
          img: require("@/assets/images/scene-img_7.png"),
          title: "自然灾害预警",
        },
      ],
      animateData: {},
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.survey-wrap {
  .icon_1 {
    left: 96px;
    top: 237px;
  }
  .icon_2 {
    right: 56px;
    top: 229px;
  }
}

.survey-center {
  padding-top: 13px;
}
.feature-wrap .feature-item_10 img {
  width: 84px;
}
</style>