// 蓝湖上设计稿自定义为375px 测量值直接写入即可
(function() {
    const baseSize = 32; // 32
    function setRem() {
      const scale = document.documentElement.clientWidth / 750; // 750
      document.documentElement.style.fontSize =
        baseSize * Math.min(scale, 2) + "px";
    }
    setRem();
    window.onresize = function() {
      setRem();
    };
  })();