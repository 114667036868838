<template>
  <div class="product-wrap">
    <div class="product-banner">
      <img src="../../assets/img/r_1.png" alt="" />
      <!-- <div class='p-top'>
          <h3>基于微型机器人的变压器智能检测产品</h3>
          <div>Transformer intelligent testing product based on micro robot
        </div>
      </div> -->
    </div>
    <div class="survey-wrap">
      <img
        class="survey-img fadeOutUp"
        ref="animate0"
        :class="{ fadeInUp: animateData.animate0 }"
        src='../../assets/img/PRODUCTINTRODUCTION.png'
      />
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品简介
      </div>
      <div class="cp-cont">
        采用<span>“获客+营销+交易+客服”</span>的客户全生命周期管理设计思路，以客户为中心分别打造连接客户、合作伙伴以及内部员工的“线索管理”、“客户管理”、“商机管理”、“产品管理”、“合同管理”、“营收管理”、“来电工作台”、“在线客服”等核心能力，以此为企业提供一站式全业务协作和运营的客户管理解决方案。
      </div>
      <img class="cp-img1" src="../../assets/img/r_2.png" alt="" />
    </div>
    <div class="feature-wrap">
         <img
          class="survey-img survey-img1 fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
          src='../../assets/img/CORECOMPETENCE.png'
        />
        <div
          class="survey-title survey-title-fff fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          核心能力
        </div>
        <div class='hx-box'>
            <div class='hx-block l-between' v-for='(itm,index) in hexlist' :key='itm.title'>
                <img :class='"img"+(index+1)' :src='itm.url'>
                <div class='hx-block-rig' :class='"hx-block-rig"+(index+1)'>
                    <div class='hx-block-title f24'>{{itm.title}}</div>
                    <div class='hx-block-txt f20'>{{itm.txt}}</div>
                </div>
            </div>
        </div>




    </div>
    <div class="scene-wrap">
        <img
          class="survey-img survey-img1 fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          src='../../assets/img/PRODUCTFEATURES.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
          产品特点
        </div>
        <div class='td-box'>
            <div class='td-block l-start' :class='"td-block"+(index+1)' v-for='(itm,index) in tdlist' :key='itm.title'>
                <img :class='"imgbj imgbj"+(index+1)' :src='itm.bj'>
                <img :class='"img img"+(index+1)' :src='itm.url'>
                 <div class='td-rig' >
                    <div class='td-title f24'>{{itm.title}}</div>
                    <div class='td-txt f20'>{{itm.txt}}</div>
                </div>
            </div>
        </div>

      
    </div>

    <div class="scene-wrap">
        <img
          class="survey-img survey-img1 survey-img2 fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
          src='../../assets/img/APPLICATIONSCENARIO.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
        >
          应用场景
        </div>

        <scene-swiper :list="yylist"></scene-swiper>


      
    </div>
    

  </div>
</template>

<script>

import sceneSwiper from "@/components/scene-swiper/index.vue";
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  components: { sceneSwiper },
  data() {
    return {
      animateData: {},
      num:0,
      hexlist:[
       {url:require("../../assets/img/r_15.png"),title:"一站式全业务协作能力",txt:"获客+营销+交易，实现客户、合作伙伴、内部员工的全流程管理"},
        {url:require("../../assets/img/r_16.png"),title:"面向企业的客户化能力",txt:"定制化开发+私有化部署，全面满足企业的个性化需求"},
         {url:require("../../assets/img/r_17.png"),title:"客户服务融合通信能力",txt:"话务通讯+即时消息，融合接入大幅提高服务效率和用户体验"},
         {url:require("../../assets/img/r_18.png"),title:"多系统对接和集成能力",txt:"支持OA+ERP+呼叫中心+客服等多种系统接口对接"},
      ],
      tdlist:[
        {url:require("../../assets/img/r_7.png"),bj:require("../../assets/img/r_6.png"),title:"全生命周期管理体系",txt:'“获客、营销、交易、客服”的全生命周期客户管理'},
        {url:require("../../assets/img/r_9.png"),bj:require("../../assets/img/r_8.png"),title:"连接全员赋能销售",txt:"以全生命周期客户管理为核心，连接多部门协作支撑"},
        {url:require("../../assets/img/r_11.png"),bj:require("../../assets/img/r_10.png"),title:"客户化系统整体交付",txt:"可根据客户需求，实现定制化开发和私有化部署"},
      ],
      yylist:[
        {img:require("../../assets/img/productHSE_15.png"),title:"生产制造型企业"},
        {img:require("../../assets/img/productHSE_16.png"),title:"软件研发型企业"},
        {img:require("../../assets/img/productHSE_17.png"),title:"技术服务型企业"},  
      ]
    };
  },
  methods: {
    imgLoad(){
      this.num++
      if(this.num==4){
        this.swiperInit();
      }
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
    swiperInit() {
      new Swiper(".swiper-container", {
        slidesPerView: "auto",
        spaceBetween: -10,
        centeredSlides: true,
        loop: true,
        loopAdditionalSlides: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        centeredSlides: true,
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: true,
        },
      });
    },
  },
  mounted() {
    // setTimeout(()=>{
    //   this.swiperInit();
    // },5000)
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='less' scoped>
.swiper-container {
  margin-top: 45px;
  padding-bottom: 80px;
  margin-bottom: 20px;
}
.swiper-wrapper {
  .swiper-slide {
    background-image: url("../../assets/images/rebot-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 425px !important;
    height: 588px;
    transition: 300ms;
    transform: scale(0.8);
    padding-left: 40px;
    padding-top: 45px;
    box-sizing: border-box;
    border-radius: 20px;
    .rebot-title {
      font-size: 28px;
      color: #555;
      position: relative;
      line-height: 38px;
      &::after {
        content: "";
        position: absolute;
        width: 44px;
        height: 8px;
        bottom: -8px;
        left: 0;
        background: #b2b2b2;
      }
    }
    .rebot-name {
      font-size: 22px;
      color: #555;
      line-height: 30px;
      margin-top: 32px;
    }
    .rebot-img {
      position: absolute;
      right: 20px;
      bottom: 20px;
      img {
        width: 200px;
      }
    }
    &.swiper-slide_1 {
      .rebot-img {
        img {
          width: 242px;
        }
      }
    }
    &.swiper-slide_2 {
      .rebot-img {
        img {
          width: 200px;
        }
      }
    }
    &.swiper-slide_3 {
      .rebot-img {
        img {
          width: 230px;
        }
      }
    }
    &.swiper-slide_4 {
      .rebot-img {
        img {
          width: 250px;
        }
      }
    }
    &.swiper-slide-active {
      background-image: url("../../assets/images/rebot-bg-active.png");
      transform: scale(1);
      z-index: 1;
      .rebot-title {
        color: #fff;
        &::after {
          background-color: #01ff66;
        }
      }
      .rebot-name {
        color: #fff;
      }
    }
    &.swiper-slide-prev {
      transform: translate(210px, 0) scale(0.8);
    }
    &.swiper-slide-next {
      transform: translate(-220px, 0) scale(0.8);
    }
  }
}
::v-deep .swiper-pagination {
  .swiper-pagination-bullet {
    background-image: url("../../assets/images/pagination.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    opacity: 1;
    width: 20px;
    height: 20px;
    outline: 0;
  }
  .swiper-pagination-bullet-active {
    background-image: url("../../assets/images/pagination-active.png");
  }
}

.survey-title{
  margin-top:-20px;
  color: #11182e;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: #414B59;
}
.survey-title-fff{
  color:#fff;
}
.survey-img{
  width:660px;
  height:40px;
  display: block;
  margin: 0 auto;
}
.survey-img1{
   width:504px;
  height:40px;
}
.cp-cont{
  font-size: 24px;
  color: #909399;
  line-height: 48px;
  padding:23px 28px 0 26px;
  text-align: center;
  span{
     color:#414b59;
    font-size:28px
  }
}
.cp-img1{
  width:750px;
  // height:528px;
  // margin:30px auto;
  // margin: 0 25px;
  display: block;
  // margin-bottom:89px;
}

.feature-wrap{
  background: #3E76E0;
}
.hx-box{
  padding:0 15px;
  .hx-block{
    height:158px;
    width:100%;
    img{
      margin-top:30px;
      margin-right:30px;
    }
    .img3{
      width:79px;
      height:94px;
    }
    .img2{
      width:79px;
      height:90px;
    }
    .img1{
      width:83px;
      height:86px;
    }
    .img4{
      width:84px;
      height:84px;
    }
    .img5{
      width:82px;
      height:87px;
    }
    .img6{
      width:87px;
      height:73px;
    }
    .hx-block-rig{
      width:291px;
      height:100%;
      border-bottom:1px dashed  rgba(255, 255, 255, .39);
      .hx-block-title{
        font-size: 12px;
        color: #FFFFFF;
        line-height: 15px;
        margin-top:18px;
      }
      .hx-block-txt{
          font-size: 10px;
          color: rgba(255, 255, 255, .66);
          line-height: 15px;
          margin-top:4px;
      }
    }
    .hx-block-rig6{
      border-bottom: none;;
    }
  }
}
.td-box{
  padding:0 28px;
  .td-block{
    width:100%;
    height:197px;
    position: relative;
    margin-top:18px;
    .imgbj{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      z-index:-1;
    }
    .img{
      
    }
    .img1{
      width:88px;
      height:88px;
      margin:54px 36px 0 39px;
    }
    .img2{
      width:84px;
      height:85px;
      margin:56px 38px 0 40px;
    }
    .img3{
      width:86px;
      height:85px;
      margin:56px 38px 0 41px;
    }
    .img4{
      width:88px;
      height:86px;
      margin:51px 36px 0 39px;
    }
  
    .td-rig{
      .td-title{
          height: 15px;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 15px;
          margin:33px 0 9px 0;
      }
      .td-txt{
        color: rgba(255, 255, 255, .9);
        font-size: 10px;
        line-height: 16px;
        padding-right:10px;
      }
    }
  }
}


.survey-img2{
  width:620px;
}

.yy-box{
  padding:0 28px;
  position: relative;
  margin-bottom:50px;
  margin-top:15px;
  img{
      width:100%;
  }
  div{
      position: absolute;
      top:93px;
      left:0;
      width:100%;
      text-align: center;
        font-size: 28px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 42px;
  }
}
.p-top{
  position: absolute;
  top:79px;
  left:35px;
  h3{
    width: 360px;
    height: 96px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 48px;
  }
  div{
    width: 342px;
    height: 36px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, .49);
    line-height: 27px;
    margin-top:38px;
  }
}
.swiper-container{
    margin-top:0;
}

</style>