<template>
    <div class="product-wrap">
      <div class="product-banner">
        <img src="../../assets/img/2024/d1.png" alt="" />
        <!-- <div class='p-top'>
            <h3>基于振动监测的轨道交通智能运维产品</h3>
            <div>Rail transit intelligent operation and maintenance products based on Vibration Monitoring
          </div>
        </div> -->
      </div>
      <div class="survey-wrap">
        <img
          class="survey-img fadeOutUp"
          ref="animate0"
          :class="{ fadeInUp: animateData.animate0 }"
          src='../../assets/img/PRODUCTINTRODUCTION.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
          产品简介
        </div>
        <div class="cp-cont">
          
            数据资产确权登记平台，围绕数据要素并以区块链、数据安全和人工智能等技术为支撑，面向企业、数据服务商、平台运营方、监管机构、评估机构等用户群体，构建支持<span>标识解析、确权登记、质量评价、价值评估、数据交易、资产入表</span>的数据资产生态服务体系。
  
        </div>
        <img class="cp-img1" src="../../assets/img/2024/d2.png" alt="" />
      </div>
      <div class="feature-wrap">
           <img
            class="survey-img survey-img1 fadeOutUp"
            ref="animate1"
            :class="{ fadeInUp: animateData.animate1 }"
            src='../../assets/img/CORECOMPETENCE.png'
          />
          <div
            class="survey-title survey-title-fff fadeOutUp"
            ref="animate2"
            :class="{ fadeInUp: animateData.animate2 }"
          >
            核心能力
          </div>
          <div class='hx-box'>
              <div class='hx-block l-between' v-for='(itm,index) in hexlist' :key='itm.title' >
                  <img :class='"img"+(index+1)' :src='itm.url'>
                  <div class='hx-block-rig' :class='"hx-block-rig"+(index+1)' :style="index==hexlist.length-1?'border-bottom:none':''">
                      <div class='hx-block-title f24'>{{itm.title}}</div>
                      <div class='hx-block-txt f20'>{{itm.txt}}</div>
                  </div>
              </div>
          </div>
  
  
  
  
      </div>
      <div class="scene-wrap">
          <img
            class="survey-img survey-img1 fadeOutUp"
            ref="animate3"
            :class="{ fadeInUp: animateData.animate3 }"
            src='../../assets/img/PRODUCTFEATURES.png'
          />
          <div
            class="survey-title fadeOutUp"
            ref="animate4"
            :class="{ fadeInUp: animateData.animate4 }"
          >
            产品特点
          </div>
          <div class='td-box'>
              <div class='td-block l-start' :class='"td-block"+(index+1)' v-for='(itm,index) in tdlist' :key='itm.title'>
                  <img :class='"imgbj imgbj"+(index+1)' :src='itm.bj'>
                  <img :class='"img img"+(index+1)' :src='itm.url'>
                   <div class='td-rig' >
                      <div class='td-title f24'>{{itm.title}}</div>
                      <div class='td-txt f20'>{{itm.txt}}</div>
                  </div>
              </div>
          </div>
  
        
      </div>
  
      <div class="scene-wrap scene-wrap-ys">
          <img
            class="survey-img survey-img1 survey-img2 fadeOutUp"
            ref="animate5"
            :class="{ fadeInUp: animateData.animate5 }"
            src='../../assets/img/PRODUCTADVANTAGES.png'
          />
          <div
            class="survey-title fadeOutUp"
            ref="animate6"
            :class="{ fadeInUp: animateData.animate6 }"
          >
            产品优势
          </div>
         
         <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
  
         <div class="cpys_wrap">
            <div class="cpys_card l-start" v-for="(item,index) in yslist" :key="index">
              <img :src="item.img" alt="">
              <div>
                <div class="title">{{ item.title }}</div>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
         </div>
      </div>
      
  
    </div>
  </template>
  
  <script>
  import Swiper from "swiper";
  import "swiper/css/swiper.min.css";
  // import sceneSwiper from "@/components/scene-swiper/index.vue";
  import { isElementNotInViewport } from "@/utils/index.js";
  
  export default {
    components: {  },
    data() {
      return {
        animateData: {},
        num:0,
        hexlist:[
          {url:require("../../assets/img/2024/d3.png"),title:"数据登记能力",txt:"采用端、云协同的方式，实现数据资产上报、登记、审核、发证的全流程管理"},
          {url:require("../../assets/img/2024/d4.png"),title:"数据评估能力",txt:"通过企业侧、资产侧及数据侧多维度的质量评价，实现数据资产的全面评估"},
          {url:require("../../assets/img/2024/d5.png"),title:"数据服务能力",txt:"通过数据服务商的能力引入，实现企业数据资产价值释放和收益转化效率提升"},
          {url:require("../../assets/img/2024/d6.png"),title:"数据交易能力",txt:"采用“可用不可见、可控可计量”的应用模式，实现数据的安全共享和可信交易"},
        ],
        tdlist:[
          {url:require("../../assets/img/2024/d7.png"),bj:require("../../assets/img/2024/d13.png"),title:"一体化节点群",txt:"1个国家节点+N个城市节点，全面满足统一管控、灵活自治的平台建设要求"},
          {url:require("../../assets/img/2024/d8.png"),bj:require("../../assets/img/2024/d12.png"),title:"生态化体系支撑",txt:"支持标识解析、确权交易、质量评价、价值评估、资产入表的生态服务体系"},
          {url:require("../../assets/img/2024/d9.png"),bj:require("../../assets/img/2024/d11.png"),title:"数据服务商支持",txt:"降低企业数据资产化运营门槛，大幅提升企业数据资产价值释放和收益转化效率"},
          {url:require("../../assets/img/2024/d10.png"),bj:require("../../assets/img/2024/d14.png"),title:"组件化基础服务",txt:"支持区块链、大模型、隐私计算能力的灵活扩展，全方位满足客户的个性化需求"},
        ],
        yslist:[
          {img:require("../../assets/img/2024/d15.png"),title:"应用等级高",text:'国家级、城市级纵横联动，更具规模化、体系化'},
          {img:require("../../assets/img/2024/d16.png"),title:"运营门槛低",text:'企业、服务商生态共建，降低数据资产运营门槛'},
          {img:require("../../assets/img/2024/d17.png"),title:"确权范围广",text:'确权交易覆盖数据及衍生产品，更利于价值释放'},
        ]
      };
    },
    methods: {
      imgLoad(){
        this.num++
        if(this.num==4){
          this.swiperInit();
        }
      },
      // 滚动事件
      scrollToTop() {
        for (let i in this.$refs) {
          !isElementNotInViewport(this.$refs[i])
            ? this.$set(this.animateData, i, true)
            : this.$set(this.animateData, i, false);
        }
      },
      swiperInit() {
        new Swiper(".swiper-container", {
          slidesPerView: "auto",
          spaceBetween: -10,
          centeredSlides: true,
          loop: true,
          loopAdditionalSlides: 10,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          centeredSlides: true,
          coverflowEffect: {
            rotate: 30,
            stretch: 10,
            depth: 60,
            modifier: 2,
            slideShadows: true,
          },
        });
      },
    },
    mounted() {
      // setTimeout(()=>{
      //   this.swiperInit();
      // },5000)
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
  };
  </script>
  
  <style lang='less' scoped>
  .swiper-container {
    margin-top: 45px;
    padding-bottom: 80px;
    margin-bottom: 20px;
  }
  .swiper-wrapper {
    .swiper-slide {
      background-image: url("../../assets/images/rebot-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 425px !important;
      height: 588px;
      transition: 300ms;
      transform: scale(0.8);
      padding-left: 40px;
      padding-top: 45px;
      box-sizing: border-box;
      border-radius: 20px;
      .rebot-title {
        font-size: 28px;
        color: #555;
        position: relative;
        line-height: 38px;
        &::after {
          content: "";
          position: absolute;
          width: 44px;
          height: 8px;
          bottom: -8px;
          left: 0;
          background: #b2b2b2;
        }
      }
      .rebot-name {
        font-size: 22px;
        color: #555;
        line-height: 30px;
        margin-top: 32px;
      }
      .rebot-img {
        position: absolute;
        right: 20px;
        bottom: 20px;
        img {
          width: 200px;
        }
      }
      &.swiper-slide_1 {
        .rebot-img {
          img {
            width: 242px;
          }
        }
      }
      &.swiper-slide_2 {
        .rebot-img {
          img {
            width: 200px;
          }
        }
      }
      &.swiper-slide_3 {
        .rebot-img {
          img {
            width: 230px;
          }
        }
      }
      &.swiper-slide_4 {
        .rebot-img {
          img {
            width: 250px;
          }
        }
      }
      &.swiper-slide-active {
        background-image: url("../../assets/images/rebot-bg-active.png");
        transform: scale(1);
        z-index: 1;
        .rebot-title {
          color: #fff;
          &::after {
            background-color: #01ff66;
          }
        }
        .rebot-name {
          color: #fff;
        }
      }
      &.swiper-slide-prev {
        transform: translate(210px, 0) scale(0.8);
      }
      &.swiper-slide-next {
        transform: translate(-220px, 0) scale(0.8);
      }
    }
  }
  ::v-deep .swiper-pagination {
    .swiper-pagination-bullet {
      background-image: url("../../assets/images/pagination.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: transparent;
      opacity: 1;
      width: 20px;
      height: 20px;
      outline: 0;
    }
    .swiper-pagination-bullet-active {
      background-image: url("../../assets/images/pagination-active.png");
    }
  }
  
  .survey-title{
    margin-top:-20px;
    color: #11182e;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    color: #414B59;
  }
  .survey-title-fff{
    color:#fff;
  }
  .survey-img{
    width:660px;
    height:40px;
    display: block;
    margin: 0 auto;
  }
  .survey-img1{
     width:504px;
    height:40px;
  }
  .cp-cont{
    font-size: 24px;
    color: #909399;
    line-height: 48px;
    padding:23px 28px 0 26px;
    text-align: center;
    span{
       color:#414b59;
      font-size:28px
    }
  }
  .cp-img1{
    width:100%;
    margin:20px auto;
    display: block;
    margin-bottom:50px;
  }
  
  .feature-wrap{
    background: #3E76E0;
  }
  .hx-box{
    padding:0 15px;
    .hx-block{
      min-height:158px;
      width:100%;
      img{
        margin-top:30px;
        margin-right:30px;
      }
      .img1{
        width:78px;
        height:91px;
      }
      .img2{
        width:90px;
        height:94px;
      }
      .img3{
        width:83px;
        height:86px;
      }
      .img4{
        width:84px;
        height:84px;
      }
      .img5{
        width:82px;
        height:87px;
      }
      .img6{
        width:87px;
        height:73px;
      }
      .hx-block-rig{
        flex:1;
        height:100%;
        border-bottom:1px dashed  rgba(255, 255, 255, .39);
        .hx-block-title{
          font-size: 12px;
          color: #FFFFFF;
          line-height: 15px;
          margin-top:18px;
        }
        .hx-block-txt{
            font-size: 10px;
            color: rgba(255, 255, 255, .66);
            line-height: 15px;
            margin-top:12px;
            margin-bottom:18px;
        }
      }
      .hx-block-rig6{
        border-bottom: none;;
      }
    }
  }
  .td-box{
    padding:0 28px;
    padding-bottom: 75px;
    .td-block{
      width:100%;
      height:197px;
      position: relative;
      margin-top:18px;
      .imgbj{
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index:-1;
      }
      .img{
        
      }
      .img1{
        width:101px;
        height:100px;
        margin:55px 27px 0 35px;
      }
      .img2{
        width:87px;
        height:74px;
        margin:58px 34px 0 42px;
      }
      .img3{
        width:93px;
        height:88px;
        margin:52px 37px 0 33px;
      }
      .img4{
        width:88px;
        height:88px;
        margin:56px 37px 0 38px;
      }
     
    
      .td-rig{
        .td-title{
            height: 15px;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 15px;
            margin:28px 0 9px 0;
        }
        .td-txt{
          color: rgba(255, 255, 255, .9);
          font-size: 10px;
          line-height: 16px;
          padding-right:10px;
        }
      }
    }
  }
  
  
  .survey-img2{
    width:620px;
  }
  
  .yy-box{
    padding:0 28px;
    .yy-block{
      width: 386px;
      height: 339px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);
      .img{
        width:386px;
        height:249px;
      }
      .yy-title{
        font-size: 24px;
        color: #909399;
        line-height: 31px;
        text-align: center;
        margin-top:28px;
      }
    }
  }
  .p-top{
    position: absolute;
    top:79px;
    left:35px;
    h3{
      width: 360px;
      height: 96px;
      font-size: 30px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 48px;
    }
    div{
      width: 342px;
      height: 36px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, .49);
      line-height: 27px;
      margin-top:38px;
    }
  }
  .swiper-container{
      margin-top:0;
  }
  .scene-wrap-ys{
    background: rgba(246, 248, 253, 1);
  }
  .cpys_wrap{
    padding: 50px 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cpys_card{
        width: 694px;
        height: 170px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 9px rgba(132,181,255,0.08);
        border-radius: 4px;
        margin-bottom: 21px;
      img{
        width: 88px;
        height: 103px;
        margin:33px 28px 0 36px;
      }
      .title{
        height: 37px;
        font-weight: bold;
        font-size: 28px;
        color: #414B59;
        line-height: 37px;
        margin-top:42px;
      }
      .text{
        font-size: 24px;
        color: #999999;
        line-height: 34px;
        text-align: left;
        margin-top:14px;
      }
    }
  }
  </style>