<template>
  <div class="footer-wrap">
    <div class="nav-wrap">
      <div @click="navHandle({ url: '/', title: '小凌泰科' })">首页</div>
      <div @click="subnavVisibile = !subnavVisibile">
        产品服务 <img src="../../assets/images/foot-icon_1.png" alt="" />
      </div>
      <div @click="navHandle({ url: '/news', title: '新闻动态' })" v-if="isShowNews">
        新闻动态
      </div>
      <div @click="navHandle({ url: '/qualification', title: '公司资质' })">
        公司资质
      </div>
      <div @click="navHandle({ url: '/About', title: '关于我们' })">
        关于我们
      </div>
      <div @click="navHandle({ url: '/recruit', title: '加入我们' })">
        加入我们
      </div>
    </div>
    <div class="foot-content-wrap">
      <div class="foot-content">
        <div>公司全称：北京小凌泰科科技有限公司</div>
        <div>商务邮箱：it@xiaoling-tech.com</div>
        <div>北京地址：北京市昌平区龙域北街3号院1号楼金域国际中心A座14层1411</div>
        <div>苏州地址：苏州市姑苏区八达街118号(月亮湾地铁站6号口步行220米)苏州新闻大厦1204</div>
       
       
      </div>
      <div class="logo">
        <img src="../../assets/img/head_5.png" alt="" />
      </div>
    </div>
    <div class="center">
      <div @click='gohFn2' class="div">
          <img
            src="../../assets/images/record.png"
            alt=""
          />京公网安备 11011402011394号
        </div>
        <div @click='gohFn' class="div">京ICP备2020038370号-1</div>
        
    </div>
    <div class="foot-bottom">Copyright@2017～2021</div>
    <div class="subnav-dialog" v-show="subnavVisibile">
      <div class="subnav-wrap">
        <div class="subnav" v-for="item in navList" :key="item.title"
          @click="navHandle(item)"
            :class="{ active: routePath.includes(item.url) }"
        >
          <div class="subnav-title">{{ item.title }}</div>
          <!-- <div
            class="subnav-item"
            v-for="itm in item.subNavList"
            :key="itm.title"
            @click="navHandle(itm)"
            :class="{ active: routePath.includes(itm.url) }"
          >
            {{ itm.title }}
          </div> -->
        </div>
      </div>
      <div class="subnav-close" @click="subnavVisibile = false">关闭</div>
    </div>
  </div>
</template>

<script>
import { selectReceptionTitle } from "@/api/common";
import navList from "@/utils/nav.js";
export default {
  data() {
    return {
      navList,
      routePath: "",
      subnavVisibile: false,
      isShowNews:false
    };
  },
  watch: {
    $route(v) {
      this.routePath = v.path;
    },
  },
  mounted() {
    this.routePath = this.$route.path;
    this.getNewsList()
  },
  methods: {
    async getNewsList(){
      let params1 = {
        pageNo:1,
        pageSize:10,
        titleType:6
      }
      let params2 = {
        pageNo:1,
        pageSize:10,
        titleType:7
      }
      try {
        let res1 = await selectReceptionTitle(params1)
        let res2 = await selectReceptionTitle(params2)
        if((res1.code === 0 && res2.code === 0)  && (res1.data.records.length || res2.data.records.length)){
          this.isShowNews = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    gohFn(){
      window.open("https://beian.miit.gov.cn/")
    },
    gohFn2(){
       window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402011394")
    },
    navHandle(item) {
      document.title = item.title;
      this.$router.push({
        path: item.url,
      });
      this.subnavVisibile = false;
    },
  },
};
</script>

<style lang="less" scoped>
.footer-wrap {
  background: #131521;
}
.nav-wrap {
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #474952;
  div {
    flex: 1;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid rgba(255, 255, 255, 0.72);
    font-size: 20px;
    color: #fff;
    &:nth-of-type(2){
      flex: 1.2;
    }
    &:nth-of-type(1){
      flex: 0.8;
    }
    &:last-child {
      border-right: 0;
    }
    img {
      width: 15px;
      margin-left: 7px;
    }
  }
}
.foot-content-wrap {
  height: 310px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px 0 26px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.72);
  line-height: 24px;
  border-bottom: 1px solid #474952;
  .foot-content {
    padding-top: 15px;
    div {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      &:nth-child(3) {
        line-height: 28px;
        width: 460px;
      }
      &:nth-child(4) {
        line-height: 28px;
        width: 460px;
      }
    }
    img {
      width: 20px;
      margin-right: 6px;
    }
  }
  .logo {
    width: 214px;
    img {
      width: 214px;
    }
  }
}
.foot-bottom {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.72);
  text-align: center;
  padding: 5px 0 50px 0;
}
.subnav-dialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.41);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
}
.subnav-wrap {
  position: absolute;
  background: #fff;
  width: 680px;
  border-radius: 8px;
  left: 50%;
  bottom: 140px;
  transform: translate(-50%, 0);
  // max-height: 1158px;
  // overflow-y: auto;
  text-align: center;
  .subnav {
    line-height: 68px;
    color: #4f5978;
    font-size: 24px;
    &:last-child {
      .subnav-item:last-child {
        border-bottom: 0;
      }
    }
    &.active {
      .subnav-title{
      color: #4478e7;

      }
    }
  }
  .subnav-title {
    border-bottom: 1px solid #d5d8e2;
  }
  .subnav-item {
    line-height: 78px;
    color: #11182e;
    font-size: 28px;
    border-bottom: 1px solid #d5d8e2;
    &.active {
      color: #4478e7;
    }
  }
}
.subnav-close {
  width: 680px;
  height: 88px;
  line-height: 88px;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  left: 50%;
  bottom: 46px;
  transform: translate(-50%);
  color: #11182e;
  font-size: 30px;
  text-align: center;
}
.center{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    .div {
      padding: 24px 0;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      font-size: 18px;
      color:rgba(255, 255, 255, 0.72);
      margin: 0 20px;
    }
    img {
      width: 20px;
      margin-right: 6px;
    }
}
</style>
