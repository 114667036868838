<template>
  <div class="home">
    <div class="banner">
      <img src="../assets/img/home_2.png" alt="" />
    </div>
    <div class="ops-wrap">
      <div class="ops-title">
        <img
          class="top-h2 fadeOutUp"
          ref="animate"
          src='../assets/img/BUSINESSFOCUS.png'
          :class="{ fadeInUp: animateData.animate }"
          >
      
      </div>
      <div
        class="top-h3 ops-subTitle fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
        style="transition-delay: 0.5s"
      >
        业务聚焦

      </div>
      <div class="ops-content">
        <p
          style="margin-bottom: 10px;padding:0 10px"
        >
        聚焦数据要素市场，致力于构建标识解析、确权登记、数据交易、资产入表等数据资产全生命周期产品服务体系，以促进企业<span >“增值、增信、增益”</span>为手段，助力企业数据资产价值释放，实现国家战略和企业发展的互利、共赢。公司成立以来，始终以推进数据要素市场化为使命，并立志成为领先的数据资产服务提供商。
        </p>
        <img
          class="top-img1 "
          src='../assets/img/home_1.png' 
          >
      </div>
    </div>
    <div class="service-wrap">
      <div class="ops-title">
        <img
          class="top-h2 fadeOutUp"
          ref="animate4"
          src='../assets/img/BUSINESSFOCUS.png'
          :class="{ fadeInUp: animateData.animate4 }"
          >
      
      </div>
      <div
        class="service-h3 ops-subTitle fadeOutUp"
        ref="animate5"
        :class="{ fadeInUp: animateData.animate5 }"
        style="transition-delay: 0.5s"
      >
        核心产品

      </div>
      <div class="service-block " :class='"service-block"+(index+1)' v-for='(itm,index) in hxdata' :key='itm.title' @click='hxFn(itm)'>
        <div class='hx-h3'>
          <span>{{ itm.title }}</span>
          <img src="../assets/img/arrowhead.png" class="arrowhead">
        </div>
        <img class='hx-img1' :class='"hx-imgs"+(index+1)' :src='itm.img_bj'>
        <img :src="itm.icon" v-if="!itm.list.length" class="icons" :class="'icons' + (index + 1)">
        <div class="subServiceList" v-if="itm.list.length">
         
          <div class="subServiceItem"  v-for="(subService,index) in itm.list" :key="index" :style="`width:${itm.subServiceItemWidth / 32}rem;`">
            <img class="icon" :src="subService.icon" :style="`width:${subService.width / 32}rem;`"/>
            <div class="label">{{ subService.label }}</div>
          </div>
        </div>
      </div>
    
    </div>
    <div class="int-wrap">
      <div
        class="int-title "
       
      >
        <img
          class="top-h2 fadeOutUp"
          ref="animate6"
          src='../assets/img/BUSINESSFOCUS.png'
          :class="{ fadeInUp: animateData.animate6 }"
          >
      </div>
      <div
        class="int-subTitle fadeOutUp"
        ref="animate7"
        :class="{ fadeInUp: animateData.animate7 }"
        style="transition-delay: 0.5s"
      >
        合作伙伴
      </div>
      <div class="int-card1 l-between l-wrap">
        <div class="int-item1" v-for="item in intList" :key="item.id">
            <img :src="item.img" alt="" />
         
        </div>
      </div>
    </div>
    <!-- <div class="partner-wrap">
      <div
        class="partner-title fadeOutUp"
        ref="animate8"
        :class="{ fadeInUp: animateData.animate8 }"
      >
        合作伙伴
      </div>
      <div class="partner-bg">
        <img src="../assets/images/partner.png" alt="" />
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { isElementNotInViewport } from "@/utils/index.js";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      hxdata:[
      {
          img_bj: require("../assets/img/home_3.png"),
          title: "蜂筑工业互联网平台",
          url: "/productN/productFg",
          subServiceItemWidth:200,
          icon:require('../assets/img/H_I_1.png'),
          list:[
            // {label:'',,width:100},
            // {label:'标识管理',icon:require('../assets/img/H_I_2.png'),width:33},
            // {label:'场景应用',icon:require('../assets/img/H_I_3.png'),width:37},
          ]
        },
        {
          img_bj: require("../assets/img/home_36.png"),
          title: "数据资产确权登记平台",
          url: "/productN/dataConfirmationRegistration",
          subServiceItemWidth:200,
          icon:require('../assets/img/home_35.png'),
          list:[
            // {label:'',,width:100},
            // {label:'标识管理',icon:require('../assets/img/H_I_2.png'),width:33},
            // {label:'场景应用',icon:require('../assets/img/H_I_3.png'),width:37},
          ]
        },
        {
          img_bj: require("../assets/img/home_38.png"),
          title: "数据产品可信交易平台",
          url: "/productN/trustedDataTrading",
          subServiceItemWidth:200,
          icon:require('../assets/img/home_37.png'),
          list:[
            // {label:'',,width:100},
            // {label:'标识管理',icon:require('../assets/img/H_I_2.png'),width:33},
            // {label:'场景应用',icon:require('../assets/img/H_I_3.png'),width:37},
          ]
        },
        {
          img_bj: require("../assets/img/home_9.png"),
          title: "TMS自动化测试系统",
          subServiceItemWidth:200,
           url: "/productN/productVibration",
           icon:require('../assets/img/H_I_16.png'),
           list:[
          //  {label:'接口自动化测试',icon:require('../assets/img/H_I_13.png'),width:41},
          //  {label:'界面自动化测试',icon:require('../assets/img/H_I_14.png'),width:33},
          //  {label:'移动端自动化测试',icon:require('../assets/img/H_I_15.png'),width:25},
          ]
        },
        {
          img_bj: require("../assets/img/home_6.png"),
          title: "CRM客户关系管理系统",
          url: "/productN/productRobot",
          subServiceItemWidth:146,
          list:[
            {label:'获客管理',icon:require('../assets/img/H_I_4.png'),width:36},
            {label:'营销管理',icon:require('../assets/img/H_I_5.png'),width:31},
            {label:'交易管理',icon:require('../assets/img/H_I_6.png'),width:29},
            {label:'客服中心',icon:require('../assets/img/H_I_7.png'),width:31},
          ]
        },
        //  {
        //   img_bj: require("../assets/img/home_12.png"),
        //   title: "EMS设备运维管理系统",
        //    url: "/productN/productHSE",
        //    subServiceItemWidth:200,
        //    list:[
        //    {label:'设备管理',icon:require('../assets/img/H_I_8.png'),width:31},
        //    {label:'维修保养',icon:require('../assets/img/H_I_9.png'),width:33},
        //    {label:'点巡检',icon:require('../assets/img/H_I_10.png'),width:31},
        //    {label:'实时监测',icon:require('../assets/img/H_I_11.png'),width:31},
        //    {label:'备品备件',icon:require('../assets/img/H_I_12.png'),width:31},
        //   ]
        // },
        
        
       
      ],
      gridList: [
        {
          img: require("../assets/images/grid_1.png"),
          img_a: require("../assets/images/grid_a_1.png"),
          title: "可视化监拍产品",
        },
        {
          img: require("../assets/images/grid_2.png"),
          img_a: require("../assets/images/grid_a_2.png"),
          title: "工业机器人产品",
        },
        {
          img: require("../assets/images/grid_3.png"),
          img_a: require("../assets/images/grid_a_3.png"),
          title: "AI图像分析产品",
        },
        {
          img: require("../assets/images/grid_7.png"),
          img_a: require("../assets/images/grid_a_7.png"),
          title: "智能安全帽产品",
        },
        {
          img: require("../assets/images/grid_5.png"),
          img_a: require("../assets/images/grid_a_5.png"),
          title: "大数据分析产品",
        },
        {
          img: require("../assets/images/grid_6.png"),
          img_a: require("../assets/images/grid_a_6.png"),
          title: "数字孪生产品",
        },
      ],
      active: 0,
      opsList: [
        {
          icon: require("../assets/images/ops-icon_1.png"),
          img: require("../assets/images/ops-img_1.png"),
          name1: "监拍装置",
          name2: "智能预警",
        },
        {
          icon: require("../assets/images/ops-icon_2.png"),
          img: require("../assets/images/ops-img_2.png"),
          name1: "机器人",
          name2: "智能控制",
        },
        {
          icon: require("../assets/images/ops-icon_3.png"),
          img: require("../assets/images/ops-img_3.png"),
          name1: "无人机",
          name2: "图像识别",
        },
        // {
        //   icon: require("../assets/images/ops-icon_4.png"),
        //   img: require("../assets/images/ops-img_4.png"),
        //   name1: "传感器",
        //   name2: "趋势分析",
        // },
        {
          icon: require("../assets/images/ops-icon_7.png"),
          img: require("../assets/images/ops-img_7.png"),
          name1: "安全帽",
          name2: "安全管控",
        },
        {
          icon: require("../assets/images/ops-icon_5.png"),
          img: require("../assets/images/ops-img_5.png"),
          name1: "边缘设备",
          name2: "云端训练",
        },
        {
          icon: require("../assets/images/ops-icon_6.png"),
          img: require("../assets/images/ops-img_6.png"),
          name1: "3D扫描装置",
          name2: "虚拟仿真",
        },
      ],
      intList: [
        {id:4,img:require("../assets/img/home_18.png")},
        {id:5,img:require("../assets/img/home_19.png")},
        {id:1,img:require("../assets/img/home_15.png")},
        {id:2,img:require("../assets/img/home_16.png")},
        {id:3,img:require("../assets/img/home_17.png")},

          
          {id:6,img:require("../assets/img/home_32.png")},
          {id:6,img:require("../assets/img/home_31.png")},
          {id:14,img:require("../assets/img/home_30.png")},
          {id:6,img:require("../assets/img/home_20.png")},
          {id:8,img:require("../assets/img/home_33.png")},
          {id:7,img:require("../assets/img/home_21.png")},
          {id:14,img:require("../assets/img/home_28.png")},
          // {id:9,img:require("../assets/img/home_23.png")},
          {id:10,img:require("../assets/img/home_24.png")},
          {id:11,img:require("../assets/img/home_25.png")},
          {id:12,img:require("../assets/img/home_26.png")},
          
          // {id:13,img:require("../assets/img/home_27.png")},
          // {id:15,img:require("../assets/img/home_29.png")}
      ],
      animateData: {
        animate1:false,
        animate2:false,
        animate3:false,
        animate4:false,
        animate5:false,
        animate6:false,
        animate7:false,
        animate8:false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    hxFn(item){
       document.title = item.title;
        this.$router.push({
          path: item.url,
        });
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? this.$set(this.animateData, i, true)
          : this.$set(this.animateData, i, false);
      }
    },
  },
};
</script>
<style scoped lang="less">
.banner {
  width: 100%;
  position: relative;
  top: -2px;
  img {
    width: 100%;
    display: block;
  }
}
//aiOps
.ops-wrap {
  padding: 40px 28px 57px;
}
.ops-title {
  color: #11182e;
  font-size: 36px;
  text-align: center;
  line-height: 48px;
 
 
}
.ops-subTitle {
  color: #414B59;
  font-size: 36px;
  text-align: center;
  line-height: 48px;
  margin-top: -30px;
}
.ops-content {
  margin-top: 35px;
  border-radius: 16px;
  padding-top: 15px;
  p{
    font-size: 24px;
    color: #909399;
    line-height: 48px;
    text-align: center;
  }
  span{
    color:#414b59;
    font-size:28px;
  }
  .top-img1{
    width:730px;
    margin-left:-20px;
  }
}
.ops-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 27px 15px;
  border-bottom: 1px dashed #d5d8e2;
  li {
    width: 204px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    background: #f7f8fc;
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 24px 0 21px;
    color: #11182e;

    p {
      height: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
    }
    span {
      font-size: 22px;
      line-height: 28px;
      text-align: center;
    }
    &.active {
      background-color: #24aef2;
      color: #fff;
    }
    &:first-child {
      img {
        width: 66px;
      }
    }
    &:nth-child(2) {
      img {
        width: 58px;
      }
    }
    &:nth-child(3) {
      img {
        width: 58px;
      }
    }
    &:nth-child(4) {
      img {
        width: 63px;
      }
    }
    &:nth-child(5) {
      img {
        width: 62px;
      }
    }
    &:nth-child(6) {
      img {
        width: 56px;
      }
    }
  }
}
.ops-card {
  padding-bottom: 34px;

  .ops-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ops {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 25px 19px;
  }
  .ops-item_name {
    width: 168px;
    height: 51px;
    border-radius: 8px;
    border: 1px solid #e3e5eb;
    color: #11182e;
    font-size: 24px;
    text-align: center;
    line-height: 51px;
  }
  .ops-dotted {
    width: 10px;
    transform: rotate(90deg);
    &:nth-child(4) {
      transform: rotate(-90deg);
    }
  }
  .ops-icon {
    width: 110px;
  }
  .ops-arrow {
    width: 74px;
    img {
      width: 100%;
    }
  }
  .ops-img {
    width: 653px;
    img {
      width: 100%;
      display: block;
    }
  }
}

/* fade-transform */
.fade-transform-enter-active {
  transition: all 0.3s;
}

.fade-transform-enter {
  opacity: 0.2;
  transform: translateX(100%);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

//ai-service
.service-wrap {
  background-size: 100% 100%;
  padding: 0px 28px 1px;
  .service-h3{
      margin-bottom:32px;
  }
  .service-block{
    width:100%;
    position: relative;
    margin-top:18px;
    overflow: hidden;
    .icons{
        // width: 100%;
        height:250px;
        display: block;
        margin:20px auto;

      }
      .icons4{
        margin-top: 30px;
        height: 380px;
      }
    .subServiceList{
      display: flex;
      padding: 0 32px;
      margin:0 -13px;
      margin-top: 20px;
      // justify-content: space-between;
      flex-wrap: wrap;
      
      .subServiceItem{
        width: 200px;
        height: 172px;
        border-radius: 3px;
        background-color:rgba(0, 27, 82, .6);
        position: relative;
        padding-top: 92px;
        margin: 0 9px;
        margin-bottom: 18px;
        .icon{
          position: absolute;
          bottom: 95px;
          left: 50%;
          transform: translateX(-50%);
        }
        .label{
          text-align: center;
          font-size: 24px;
          color: #fff;
        }
      }

    }
    .hx-h3{
        font-size: 26px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 35px;
        padding:0 33px;
        padding-top:32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .arrowhead{
          width: 22px;
          height: 12px;
          transform-origin:50% 50%;
          transform: rotate(-90deg);
        }
    }
    .hx-txt{
        font-size: 22px;
        color: rgba(255, 255, 255, .85);
        line-height: 36px;
         padding:0 33px;
         margin-top:10px;
    }
    .hx-img1{
      position: absolute;
      left:0;
      top:0;
      width:693px;
      z-index:-1;
    }
    .hx-imgs1{
      height:100%;
    }
    .hx-imgs4{
      height:100%;
      width: auto;
    }
    .hx-img4{
      display: block;
       width:654px;
       margin:0 auto;
       margin-top:30px;
       
    }
    .hx-bom{
      position: absolute;
      bottom:32px;
      right:35px;
      span{
        font-size: 20px;
        color: #FFFFFF;
        line-height: 26px;
      }
      .hx-img3{
        margin-left:9px;
        position: relative;
      }
    }
  }
   .service-block1{
        height:365px;
        .hx-bom{
          .hx-img3{
            width:22px;
            height:22px;
            top:4px;
          }
      }
    }
   
    .service-block4{
      height:513px;
      .subServiceList{
        .subServiceItem{
          height: 192px;
          padding: 0 35px;
          padding-top: 92px;
          .icon{
            bottom: 115px;
          }
        }
      }
      
      .hx-img4{
        margin-top:0px;
        
      }
      .hx-bom{
        .hx-img4{
          width:20px;
          height:22px;
          top:1px;
        }
    }
  }
  .service-block5{
        height:305px;
        .hx-img2{
          width:420px;
          
        }
        .hx-bom{
          .hx-img3{
            width:24px;
            height:23px;
          }
      }
    }
  .service-block6{
      height:493px;
      .hx-img2{
        width:420px;
        position: relative;
        top:25px;
        
      }
      .hx-bom{
        .hx-img3{
          width:24px;
          height:21px;
          top:2px;
        }
    }
  }
  

}
.partner-wrap {
  position: relative;
  .partner-title {
    font-size: 36px;
    color: #fff;
    line-height: 47px;
    position: absolute;
    top: 44px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .partner-bg {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
}
 .top-h2{
    width:442px;
    height:41px;
  }
  .int-subTitle {
  color: #414B59;
  font-size: 36px !important;
  text-align: center;
  line-height: 48px;
  margin-top: -26px !important;
}
.int-card1{
  margin-top:10px;
    .int-item1{
      width:230px;
      height:125px;
      img{
        width:100%;
        height:100%;
      }
    }
}
</style>
