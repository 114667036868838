<template>
  <div class="energy-wrap">
    <div class="energy-banner">
      <img src="../../assets/images/product-banner_11.png" alt="" />
    </div>
    <div class="survey-wrap">
      <div class="survey-title">产品概况</div>
      <div class="survey-subTitle">
        智能化水电站检修服务解决方案，主要是针对常规水电站的日常检测、清理、修缮等业务需求而提供的定制化服务方案，该方案不但可基于先进的水下机器人技术，并通过加装定制化的专业设备，来实现坝体状况动态检测、小障碍物清除、坝体附着物清理、样本取样等多种坝体检测和维护工作，还可以通过水下蛙人，实现对大坝较大障碍物清除、水下修补等特殊工作，以此较全面的满足水电站的检修服务工作
      </div>
      <div class="hydropowerStation-wrap">
        <div class="hydropowerStation-nav">
          <span :class="{ active: active }" @click="active = true"
            >水下机器人</span
          >
          <span :class="{ active: !active }" @click="active = false"
            >水下蛙人</span
          >
        </div>
        <div class="hydropowerStation-card" v-if="active">
          <div>
            <img src="../../assets/images/hydropowerStation-img_1.png" alt="" />
          </div>
          <ul>
            <li>
              <img
                src="../../assets/images/hydropowerStation-icon_1.png"
                alt=""
              />
              <span>坝体健康检测</span>
            </li>
            <li>
              <img
                src="../../assets/images/hydropowerStation-icon_2.png"
                alt=""
              />
              <span>小障碍物清除</span>
            </li>
            <li>
              <img
                src="../../assets/images/hydropowerStation-icon_3.png"
                alt=""
              />
              <span>坝体附着物清理</span>
            </li>
            <li>
              <img
                src="../../assets/images/hydropowerStation-icon_4.png"
                alt=""
              />
              <span>样本取样</span>
            </li>
          </ul>
        </div>
        <div class="hydropowerStation-card" v-else>
          <div>
            <img src="../../assets/images/hydropowerStation-img_2.png" alt="" />
          </div>
          <ul>
            <li>
              <img
                src="../../assets/images/hydropowerStation-icon_5.png"
                alt=""
              />
              <span>大障碍物清除</span>
            </li>
            <li>
              <img
                src="../../assets/images/hydropowerStation-icon_6.png"
                alt=""
              />
              <span>水下修补</span>
            </li>
          </ul>
        </div>
      </div>
      <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
      <img class="icon_2" src="../../assets/images/icon_2.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: true,
    };
  },
};
</script>

<style lang='less' scoped>
.hydropowerStation-wrap {
  margin: 32px 28px 60px;
  box-shadow: 0px 0px 9px 6px rgba(23, 31, 48, 0.07);
  border-radius: 16px;
  .hydropowerStation-nav {
    display: flex;
    padding: 38px 0 42px;
    span {
      border-right: 1px solid #979797;
      color: #11182e;
      font-size: 28px;
      flex: 1;
      text-align: center;
      position: relative;
      &:nth-child(2) {
        border-right: 0;
      }
      &.active {
        font-weight: bold;
        &::before {
          content: "";
          position: absolute;
          background: #01a0ef;
          height: 4px;
          width: 62px;
          bottom: -10px;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
  }
  .hydropowerStation-card {
    padding: 0 16px;
    img {
      width: 100%;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 40px 0 10px;
      li {
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;
        justify-content: center;
        margin-bottom: 42px;
      }
      span {
        color: #7e8394;
        font-size: 24px;
        line-height: 32px;
        margin-top: 20px;
      }
      img {
        width: 133px;
      }
    }
  }
}
</style>