<template>
    <div class="product-wrap productWX">
        <div class="top">
               <img class="img1" src="../../assets/img/productWX_1.png">
               <div class="l-start">
                    <div class="top-h1">欢迎使用</div>
                    <div class="top-h2">商拓邦</div>
                    <div class="top-h3">小程序</div>
               </div>
               <div  class="top-p">拓人脉 · 抓商机 · 找融资</div>
               <img  class="img2" src="../../assets/img/productWX_4.png">
              <div class="topblock l-start">
                    <div class="code">
                        <img  class="img10" src="../../assets/img/productWX_10.jpg">
                        <div class="codep">微信扫码<br/>打开小程序</div>
                    </div>
                    <div  class="top-txt">商拓邦是专为创业型企业家设计的轻熟圈信息服务类产品，通过该产品可以直观了解创业家的企业状况和产品方案，帮助创业家精准拓展人脉、智能捕捉商机、快速找到融资。</div>
              </div>
               
              
        </div>
         
        
  
      <div class="productWXimg scene-wrap">
          
          <div class="h3" >
            部分页面展示
          </div>
          <div class="swiper-container scene-swiper">
            <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in yylist" :key="item.title">
                <img :src="item.img" alt="" />
            </div>
            </div>
        </div>
         <!-- <scene-swiper :list="yylist"></scene-swiper> -->
         <img class="icon_1" src="../../assets/images/icon_1.png" alt="" />
  
        
      </div>
      
  
    </div>
  </template>
  
  <script>
  import Swiper from "swiper";
  import "swiper/css/swiper.min.css";
  import sceneSwiper from "@/components/scene-swiper/index.vue";
  import { isElementNotInViewport } from "@/utils/index.js";
  
  export default {
    components: { sceneSwiper },
    data() {
      return {
        animateData: {},
        num:0,
       
        yylist:[
          {img:require("../../assets/img/productWX_5.png"),title:"生产设备维保"},
          {img:require("../../assets/img/productWX_6.png"),title:"工程机械管控"},
          {img:require("../../assets/img/productWX_7.png"),title:"电气设备保障"},
          {img:require("../../assets/img/productWX_8.png"),title:"医疗设备管理"},
          {img:require("../../assets/img/productWX_9.png"),title:"医疗设备管理"},
          
        ]
      };
    },
    methods: {
      imgLoad(){
        this.num++
        if(this.num==4){
          this.swiperInit();
        }
      },
      swiperInit() {
      new Swiper(".swiper-container", {
        slidesPerView: "auto",
        spaceBetween: 10,
      });
    },
      // 滚动事件
      scrollToTop() {
        for (let i in this.$refs) {
          !isElementNotInViewport(this.$refs[i])
            ? this.$set(this.animateData, i, true)
            : this.$set(this.animateData, i, false);
        }
      },
      swiperInit() {
        new Swiper(".swiper-container", {
          slidesPerView: "auto",
          spaceBetween: -10,
          centeredSlides: true,
          loop: true,
          loopAdditionalSlides: 10,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          centeredSlides: true,
          coverflowEffect: {
            rotate: 30,
            stretch: 10,
            depth: 60,
            modifier: 2,
            slideShadows: true,
          },
        });
      },
    },
    mounted() {
      // setTimeout(()=>{
        this.swiperInit();
      // },5000)
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
  };
  </script>
  
  <style lang='less' scoped>
  .productWX{
    background:url('../../assets/img/productWX_2.png') no-repeat center top;
    background-size:100% 1336px;
    .top{
        padding-top:68px;
        position: relative;
        height:1336px;

        .img1{
            width:80px;
            height:80px;
            display: block;
            margin:0 auto;
            margin-bottom:15px;
        }
        .img2{
            width:732px;
            height:875px;
            position: absolute;
            left:0;
            top:140px;
        }
        .top-h1{
            height: 58px;
            font-size: 58px;
            font-family: SourceHanSansCN-Light, SourceHanSansCN;
            font-weight: 300;
            color: #2D2F33;
            line-height: 87px;
            margin-left:166px;
        }
        .top-h2{
            height: 58px;
            font-size: 58px;
            font-family: SourceHanSansCN-Light, SourceHanSansCN;
            font-weight: 900;
            font-weight: 900;
            color: #2D2F33;
            line-height: 87px;
            margin-left:20px;
        }
        .top-h3{
            width:80px;
            height:36px;
            background:url('../../assets/img/productWX_3.png') no-repeat center center;
            background-size:100% 100%;
            position: relative;
            left:6px;
            top:34px;
            font-size: 20px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 34px;
            text-align: center;
        }
        .top-p{
            height: 28px;
            font-size: 28px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #7B8188;
            line-height: 42px;
            text-align: center;
            margin-top:40px;
        }
        .topblock{
            position: absolute;
            bottom:77px;
            left:29px;
            width: 694px;
            height: 223px;
            background: #FFFFFF;
            box-shadow: 0px 0px 12px 8px rgba(37,75,163,0.04);
            border-radius: 22px;
            border: 1px solid #2C4FEA;
            .code{
                padding:23px 28px 0 26px;

                .codep{
                    width: 110px;
                    height: 48px;
                    font-size: 20px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #6A6A6A;
                    line-height: 24px;
                    text-align: center;
                    margin:0 auto;
                    position: relative;
                    top:-6px;
                }
                .img10{
                    width:125px;
                    height:125px;
                }
            }
            .top-txt{
                width: 484px;
                height: 176px;
                font-size: 24px;
                font-family: MicrosoftYaHei;
                color: #8F9299;
                line-height: 44px;
                margin-top:15px;
            }
        }
    }
  }
  .h3{
    height: 48px;
    font-size: 48px;
    font-family: SourceHanSansCN-Light, SourceHanSansCN;
    font-weight: 300;
    color: #2D2F33;
    line-height: 72px;
    text-align: center;
    margin-bottom:30px;
  }
  .td-box{
    padding:0 28px;
    .td-block{
      width:100%;
      height:197px;
      position: relative;
      margin-top:18px;
      .imgbj{
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index:-1;
      }
      .img{
        
      }
      .img1{
        width:90px;
        height:93px;
        margin:57px 40px 0 36px;
      }
      .img2{
        width:86px;
        height:88px;
        margin:58px 41px 0 38px;
      }
      .img3{
        width:85px;
        height:85px;
        margin:55px 39px 0 38px;
      }
      .img4{
        width:88px;
        height:85px;
        margin:55px 39px 0 38px;
      }
    
      .td-rig{
        .td-title{
            height: 15px;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 15px;
            margin:28px 0 9px 0;
        }
        .td-txt{
          color: rgba(255, 255, 255, .9);
          font-size: 10px;
          line-height: 16px;
          padding-right:10px;
        }
      }
    }
  }
  .productWXimg{
    ::v-deep img{
        width:215px !important;
        height:436px !important;
    }
  }
  
  .survey-img2{
    width:620px;
  }
  
  .p-top{
    position: absolute;
    top:79px;
    left:35px;
    h3{
      width: 360px;
      height: 96px;
      font-size: 30px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 48px;
    }
    div{
      width: 342px;
      height: 36px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, .49);
      line-height: 27px;
      margin-top:38px;
    }
  }
  .swiper-container{
      margin-top:0;
  }
  .scene-swiper {
  padding: 42px 20px 64px 20px;

  ::v-deep .swiper-wrapper {
    .swiper-slide {
      width: 250px;
      position: relative;

      img {
        width: 100%;
        display: block;
      }
      
    }
  }
}
  </style>