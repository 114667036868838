<template>
    <div class="product-wrap">
      <div class="product-banner">
        <img src="../../assets/img/dcmm_5.png" alt="" />
        <!-- <div class='p-top'>
            <h3>基于振动监测的轨道交通智能运维产品</h3>
            <div>Rail transit intelligent operation and maintenance products based on Vibration Monitoring
          </div>
        </div> -->
      </div>
      <div class="survey-wrap">
        <img
          class="survey-img fadeOutUp"
          ref="animate0"
          :class="{ fadeInUp: animateData.animate0 }"
          src='../../assets/img/dcmm_3.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
        DCMM的概念
        </div>
        <div class="cp-cont">
          DCMM（DatamanagementCapabilityMaturityModel）是《数据管理能力成熟度评估模型》的简称，是我国首个数据管理领域正式发布的国家标准（GB/T36073-2018），旨在帮助企业利用先进的数据管理理念和方法，建立和评价自身数据管理能力，持续完善数据管理组织、程序和制度，充分发挥数据在促进企业向信息化、数字化、智能化发展方面的价值。
  
        </div>
      </div>
      

      <div class="survey-wrap survey-wrap2">
        <img
          class="survey-img fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
          src='../../assets/img/dcmm_1.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
        DCMM的内容
        </div>
        <div class="cp-cont  " >
          DCMM结合数据生命周期管理各个阶段的特征，按照组织、制度、流程和技术对数据管理能力进行分析、总结，提炼出了数据战略、数据治理、数据架构、数据应用、数据安全、数据质量、数据标准和数据生存周期8个核心能力域，细分为28个过程域和445条能力等级标准。
  
        </div>
        <img class="cp-img1 " src="../../assets/img/dcmm_4.png" alt="" />
      </div>

      
      <div class="survey-wrap survey-wrap3 ">
        <img
          class="survey-img fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
          src='../../assets/img/dcmm_7.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
        DCMM的等级
        </div>
        <div class="cp-cont " >
          DCMM（DatamanagementCapabilityMaturityModel）是《数据管理能力成熟度评估模型》的简称，是我国首个数据管理领域正式发布的国家标准（GB/T36073-2018），旨在帮助企业利用先进的数据管理理念和方法，建立和评价自身数据管理能力，持续完善数据管理组织、程序和制度，充分发挥数据在促进企业向信息化、数字化、智能化发展方面的价值。
  
        </div>
        <img class="cp-img1 "  src="../../assets/img/dcmm_6.png" alt="" />
      </div>


      
      <div class="scene-wrap scene-wrap-ys  survey-wrap4">
          <img
            class="survey-img survey-img1 survey-img2 fadeOutUp"
            ref="animate3"
            :class="{ fadeInUp: animateData.animate3 }"
            src='../../assets/img/dcmm_8.png'
          />
          <div
            class="survey-title fadeOutUp"
            ref="animate3"
            :class="{ fadeInUp: animateData.animate3 }"
          >
          DCMM的价值
          </div>
         
         <!-- <img class="icon_1" src="../../assets/images/icon_1.png" alt="" /> -->
         <div class="box3">
            
            <div class="cpys_wrap">
              <h3 class="h3">企业管理<div class="xian xian2"></div></h3>
                <div class="cpys_card" v-for="(item,index) in yslist" :key="index">
                  <img :src="item.img" alt="">
                  <div class="title">{{ item.title }}</div>
                  <div class="text">{{ item.text }}</div>
                </div>
            </div>
            <div class="bom4">
              <h3 class="h32 h3">行业竞争<div class="xian"></div></h3>
              <p>贯标企业在对外服务、试点项目、数字经济领域等，重要会议、学术交流、承接项目等均可获得更多的机会和优势。</p>
            </div>
         </div>
      </div>
      


      <div class="survey-wrap survey-wrap5 ">
        <div class="bg">
        <img
          class="survey-img fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          src='../../assets/img/dcmm_14.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
        DCMM适用企业
        </div>
        <div class="cp-cont " >
          DCMM是针对企业数据管理和应用能力的评估框架，从标准本身讲，任何企业都可以申请，目前主要适用于两类：
  
        </div>
        <div class="bombox">
          <van-swipe class="my-swipe"  indicator-color="#3E76E0"  :loop="false">
            <van-swipe-item v-for="itm in yyList" :key="itm.title">
                <div class="bomd">
                  <img class="b-icon "  :src='itm.url'/>
                  <img class="b-icon2 "  src='../../assets/img/dcmm_19.png'/>
                  <div class="h4">{{ itm.title }}</div>
                  <div class="p">{{ itm.txt }}</div>
                </div> 
            </van-swipe-item>
            
          </van-swipe>
        </div>
      </div>
    </div>

    <div class="survey-wrap survey-wrap6 ">
        <img
          class="survey-img fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
          src='../../assets/img/dcmm_17.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
        >
        DCMM评估条件
        </div>
        <img class="cp-img1 "  src="../../assets/img/dcmm_18.png" alt="" />
      </div>

      <div class="survey-wrap survey-wrap7 ">
        <img
          class="survey-img fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
          src='../../assets/img/dcmm_22.png'
        />
        <div
          class="survey-title fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
        >
        DCMM政策补贴
        </div>
        <div class="cp-cont " >
          目前共约16个省、32个地市、25个地区出台了DCMM评估奖励补贴政策，相关政策按照首次获得DCMM的认证等级，分别给予20万元-60万元的一次性资金补助（具体以各地方的补贴政策为准）。
  
        </div>
        <img class="cp-img1 "  src="../../assets/img/dcmm_20.png" alt="" />
      </div>
    </div>
  </template>
  
  <script>
  import Swiper from "swiper";
  import "swiper/css/swiper.min.css";
  // import sceneSwiper from "@/components/scene-swiper/index.vue";
  import { isElementNotInViewport } from "@/utils/index.js";
  
  export default {
    components: {  },
    data() {
      return {
        animateData: {},
        num:0,
        hexlist:[
          {url:require("../../assets/img/v_4.png"),title:"接口自动化测试能力",txt:"针对各类接口，通过编写自动化测试脚本，实现接口功能的自动化测试"},
          {url:require("../../assets/img/v_3.png"),title:"界面自动化测试能力",txt:"针对B/S架构系统，通过录制界面组件+测试脚本，实现界面自动化测试"},
          {url:require("../../assets/img/v_5.png"),title:"移动端自动化测试能力",txt:"针对APP、H5类型软件，通过录制组件+测试脚本，实现移动端自动化测试"},
        ],
        tdlist:[
          {url:require("../../assets/img/v_7.png"),bj:require("../../assets/img/v_6.png"),title:"测试工具全集成",txt:"新建线路和既有线路均可快速搭建"},
          {url:require("../../assets/img/v_9.png"),bj:require("../../assets/img/v_8.png"),title:"测试标准规范化",txt:"提供统一的测试过程管理、测试报表、测试日志，建立统一的指标体系"},
          {url:require("../../assets/img/v_11.png"),bj:require("../../assets/img/v_10.png"),title:"一站式测试平台",txt:"用户可以在一个服务上实现各种类型的测试，实现测试用例的统一性"},
        ],
        yslist:[
          {img:require("../../assets/img/dcmm_9.png"),title:"提高数据管理方法",text:'帮助企业科学有效的掌握数据管理方法，发现问题找到差距，给出企业提高数据管理能力的路径。'},
          {img:require("../../assets/img/dcmm_10.png"),title:"提升内部管理",text:'帮助企业提升内部管理，提高数据作为单位核心战略资源的地位'},
          {img:require("../../assets/img/dcmm_11.png"),title:"提高人员技能",text:'帮助企业提高人员技能，推动企业数据管理人才队伍建设'},
          {img:require("../../assets/img/dcmm_12.png"),title:"提高市场竞争门槛",text:'帮助企业提高市场竞争门槛，促进数据要素价值释放'},
        ],
        yyList:[
        {url:require("../../assets/img/dcmm_15.png"),title:"数据拥有方(甲方)",txt:"数据拥有方(甲方)，自身拥有大量数据，需要对自身数据进行管理。如：银行、证券机构、电信运营商、工业企业、政务数据中心等"},
        {url:require("../../assets/img/dcmm_16.png"),title:"信息技术服务方(乙方)",txt:"信息技术服务方(乙方)，自身并不拥有大量数据但对外输出数据管理的能力和相关服务。如：数据开发运营商数据中台技术提供商、数据管理技术解决方案提供商。"},
        ]
      };
    },
    methods: {
      imgLoad(){
        this.num++
        if(this.num==4){
          this.swiperInit();
        }
      },
      // 滚动事件
      scrollToTop() {
        for (let i in this.$refs) {
          !isElementNotInViewport(this.$refs[i])
            ? this.$set(this.animateData, i, true)
            : this.$set(this.animateData, i, false);
        }
      },
      swiperInit() {
        new Swiper(".swiper-container", {
          slidesPerView: "auto",
          spaceBetween: -10,
          centeredSlides: true,
          loop: true,
          loopAdditionalSlides: 10,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          centeredSlides: true,
          coverflowEffect: {
            rotate: 30,
            stretch: 10,
            depth: 60,
            modifier: 2,
            slideShadows: true,
          },
        });
      },
    },
    mounted() {
      // setTimeout(()=>{
      //   this.swiperInit();
      // },5000)
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
  };
  </script>
  
  <style lang='less' scoped>
  .swiper-container {
    margin-top: 45px;
    padding-bottom: 80px;
    margin-bottom: 20px;
  }
  .swiper-wrapper {
    .swiper-slide {
      background-image: url("../../assets/images/rebot-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 425px !important;
      height: 588px;
      transition: 300ms;
      transform: scale(0.8);
      padding-left: 40px;
      padding-top: 45px;
      box-sizing: border-box;
      border-radius: 20px;
      .rebot-title {
        font-size: 28px;
        color: #555;
        position: relative;
        line-height: 38px;
        &::after {
          content: "";
          position: absolute;
          width: 44px;
          height: 8px;
          bottom: -8px;
          left: 0;
          background: #b2b2b2;
        }
      }
      .rebot-name {
        font-size: 22px;
        color: #555;
        line-height: 30px;
        margin-top: 32px;
      }
      .rebot-img {
        position: absolute;
        right: 20px;
        bottom: 20px;
        img {
          width: 200px;
        }
      }
      &.swiper-slide_1 {
        .rebot-img {
          img {
            width: 242px;
          }
        }
      }
      &.swiper-slide_2 {
        .rebot-img {
          img {
            width: 200px;
          }
        }
      }
      &.swiper-slide_3 {
        .rebot-img {
          img {
            width: 230px;
          }
        }
      }
      &.swiper-slide_4 {
        .rebot-img {
          img {
            width: 250px;
          }
        }
      }
      &.swiper-slide-active {
        background-image: url("../../assets/images/rebot-bg-active.png");
        transform: scale(1);
        z-index: 1;
        .rebot-title {
          color: #fff;
          &::after {
            background-color: #01ff66;
          }
        }
        .rebot-name {
          color: #fff;
        }
      }
      &.swiper-slide-prev {
        transform: translate(210px, 0) scale(0.8);
      }
      &.swiper-slide-next {
        transform: translate(-220px, 0) scale(0.8);
      }
    }
  }
  ::v-deep .swiper-pagination {
    .swiper-pagination-bullet {
      background-image: url("../../assets/images/pagination.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: transparent;
      opacity: 1;
      width: 20px;
      height: 20px;
      outline: 0;
    }
    .swiper-pagination-bullet-active {
      background-image: url("../../assets/images/pagination-active.png");
    }
  }
  
  .survey-title{
    margin-top:-20px;
    color: #11182e;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    color: #414B59;
  }
  .survey-title-fff{
    color:#fff;
  }
  .survey-img{
    max-width: 660px;
    height:40px;
    display: block;
    margin: 0 auto;
  }
  .survey-img1{
     width:504px;
    height:40px;
  }
  .cp-cont{
    font-size: 24px;
    color: #909399;
    line-height: 48px;
    padding:23px 28px 0 26px;
    text-align: center;
    span{
       color:#414b59;
      font-size:28px
    }
  }
  .cp-img1{
    width:480px;
    margin:20px auto;
    display: block;
    margin-bottom:50px;
  }
  
  .feature-wrap{
    background: #3E76E0;
  }
  .hx-box{
    padding:0 15px;
    .hx-block{
      height:158px;
      width:100%;
      img{
        margin-top:30px;
        margin-right:30px;
      }
      .img1{
        width:78px;
        height:91px;
      }
      .img2{
        width:90px;
        height:94px;
      }
      .img3{
        width:83px;
        height:86px;
      }
      .img4{
        width:84px;
        height:84px;
      }
      .img5{
        width:82px;
        height:87px;
      }
      .img6{
        width:87px;
        height:73px;
      }
      .hx-block-rig{
        width:291px;
        height:100%;
        border-bottom:1px dashed  rgba(255, 255, 255, .39);
        .hx-block-title{
          font-size: 12px;
          color: #FFFFFF;
          line-height: 15px;
          margin-top:18px;
        }
        .hx-block-txt{
            font-size: 10px;
            color: rgba(255, 255, 255, .66);
            line-height: 15px;
            margin-top:4px;
        }
      }
      .hx-block-rig6{
        border-bottom: none;;
      }
    }
  }
  .td-box{
    padding:0 28px;
    padding-bottom: 75px;
    .td-block{
      width:100%;
      height:197px;
      position: relative;
      margin-top:18px;
      .imgbj{
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index:-1;
      }
      .img{
        
      }
      .img1{
        width:96px;
        height:91px;
        margin:54px 24px 0 37px;
      }
      .img2{
        width:75px;
        height:77px;
        margin:64px 45px 0 37px;
      }
      .img3{
        width:79px;
        height:77px;
        margin:64px 41px 0 37px;
      }
      .img4{
        width:88px;
        height:84px;
        margin:55px 45px 0 30px;
      }
      .img5{
        width:85px;
        height:78px;
        margin:59px 42px 0 36px;
      }
    
      .td-rig{
        .td-title{
            height: 15px;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 15px;
            margin:33px 0 9px 0;
        }
        .td-txt{
          color: rgba(255, 255, 255, .9);
          font-size: 10px;
          line-height: 16px;
          padding-right:10px;
        }
      }
    }
  }
  
  
  .survey-img2{
    width:620px;
  }
  
  .yy-box{
    padding:0 28px;
    .yy-block{
      width: 386px;
      height: 339px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);
      .img{
        width:386px;
        height:249px;
      }
      .yy-title{
        font-size: 24px;
        color: #909399;
        line-height: 31px;
        text-align: center;
        margin-top:28px;
      }
    }
  }
  .p-top{
    position: absolute;
    top:79px;
    left:35px;
    h3{
      width: 360px;
      height: 96px;
      font-size: 30px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 48px;
    }
    div{
      width: 342px;
      height: 36px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, .49);
      line-height: 27px;
      margin-top:38px;
    }
  }
  .swiper-container{
      margin-top:0;
  }
  .scene-wrap-ys{
    background: rgba(246, 248, 253, 1);
  }
  .cpys_wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background:#fff;
    width:693px;
    margin:0 auto;
    .cpys_card{
      width: 331px;
      height: 329px;
      text-align: center;
      padding: 38px 26px;
      background-color: #fff;
      &:first-of-type{
        margin-bottom: 26px;
      }
      img{
        width: 88px;
        height: 103px;
      }
      .title{
        font-size: 26px;
        line-height: 35px;
        height: 35px;
        font-weight: 600;
        color: rgba(65, 75, 89, 1);
      }
      .text{
        width: 279px;
        height: 82px;
        font-size: 22px;
        color: #999999;
        line-height: 34px;
      }
    }
  }
  .survey-wrap2{
    overflow: hidden;
    margin-top:43px;
    background-image: url("../../assets/img/dcmm_2.png");
    background-size: 100% 100%;
    height:1182px;
    .cp-img1{
      width:696px;
      height:696px;
    }
    .cp-cont  {
      color:#5E708E;
    }
  }
  .survey-wrap3{
    .cp-img1{
      width:745px;
      height:513px;
    }
  }

  .survey-wrap4{
    background: #E8EFFB;
    padding-bottom: 80px;
    .box3{
      padding-top:24px;
      width: 693px;
      border-radius: 10px;
      margin:0 auto;
      overflow: hidden;
      height:982px;
    }
    .bom4{
      width: 693px;
      height: 222px;
      background: #3E76E0;
      
      p{
        font-size: 24px;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        line-height: 40px;
        padding:9px 24px;
      }
    }
    .h3{
          font-size: 28px;
          font-family: MicrosoftYaHei;
          color: #414B59;
          line-height: 29px;
          position: relative;
          z-index: 1;
          padding:30px 0 14px 15px;
          width:100%;
          
        .xian{
          width: 110px;
          height: 8px;
          background: rgb(66, 191, 232);
          position: absolute;
          left:18px;
          bottom:17px;
          z-index: -1;
        }
        .xian2{
          background: #428DE8;
        }
      }
      .h32{
        color:#fff;
      }
  }
  .survey-wrap5{
    padding-top:0;
    background: #F6F8FD;
    height:777px;
    .bg{
      background: url("../../assets/img/dcmm_13.png") no-repeat center center;
      background-size: 750px 528px;
      background-position-y:0px;
      padding-top:50px;

    }
    /deep/.van-swipe__indicator{
      width:38px !important;
      height:8px;
      border-radius: 6px;
    }
    .survey-title{
      color:#fff;
    }
    .cp-cont {
      color:#fff;
    }
    .bombox{
      width: 692px;
      height: 370px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.05);
      border-radius: 8px;
      margin:38px auto;

      .bomd{
        height: 450px;
        position: relative;
        padding:0 23px;


      }
      .b-icon{
        width:114px;
        height:135px;
        margin:17px 0 21px 0;
      }
      .b-icon2{
        width:137px;
        height:170px;
        position: absolute;
        top:0;
        right:32px;

      }
      .h4{
        height: 37px;
        font-size: 28px;
        font-family: MicrosoftYaHei;
        color: #414B59;
        line-height: 37px;
      }
      .p{
          height: 114px;
          font-size: 24px;
          font-family: MicrosoftYaHei;
          color: #909399;
          line-height: 38px;
      }
    }
  }

  .survey-wrap6{
    .cp-img1 {
      width:100%;
    }
  }
  .survey-wrap7{
    background: url("../../assets/img/dcmm_21.png") no-repeat center center;
    background-size: 100% 100%;
    background-position-y:0px;
    height:534px;
    width:750px;
    .cp-cont{
      width: 750px;
      font-size: 24px;
      font-family: MicrosoftYaHei;
      color: #909399;
      line-height: 40px;
      padding:0 20px;
      margin:0 auto;
    }
    .cp-img1 {
      width:605px;
      margin-top:74px;
    }
  }
  /deep/.van-swipe__indicator{
    background-color: #999;
  }
  </style>